import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Playerwelcome from "./PlayerWelcome";
// import PlayerStart from '../player/playerstart';
import PlayerStart from "./PlayerStart";
import PlayerEdit from "./PlayerEdit";
import PlayerInvitation from "./PlayerInvitation";
import PlayerQuestion from "./PlayerQuestion";
import Introvideo from "./Introvideo";
import Introtext from "./Introtext";
import PlayerAnswer from "./PlayerAnswer";
import PlayerLeaderboard from "./PlayerLeaderboard";
import PlayerQuestionLeaderBoard from "./PlayerQuestionLeaderboard";
import PlayerCorrectAnswer from "./PlayerCorrectAnswer";
import PlayerPodium from "./PlayerPodium";
import PlayerStartIdle from "./PlayerStartIdle";
import RoundResult from "./RoundResult";
import GameResult from "./GameResult";
import RoundStart from "./RoundStart";

import Loader from "../components/Loader";
import "../assets/astro.scss";

import "../index.css";
import PlayerPaused from "./PlayerPaused";

function Circuit(props) {
  const [userType, setUserType] = useState(1);
  const localStorageGame = JSON.parse(localStorage.getItem("game"));
  const localStage = localStorage.getItem("playerStage");
  const skin = props.game
    ? props.game?.game?.skin?.game_skin_name
    : localStorageGame.skin?.game_skin_name;
  const [isValid, setIsValid] = useState(true);
  const background_v1 =
    "background_v1_" + props.game?.game?.player_color?.replace("#", "");
  const background_v3 =
    "background_v3_" + props.game?.game?.player_color?.replace("#", "");
  let playerPage = "";

  const checkIsLogin = (isLogin) => {
    let { game } = props.game;
    const getGame = game ?? JSON.parse(localStorage.getItem("game"));
    const profile = JSON.parse(localStorage.getItem("profile"));
    // (profile.game_id && profile.game_id !== storedGame?.id)
    if (!profile || (profile.game_id && profile?.game_id != getGame?.id)) {
      localStorage.setItem("playerStage", 2);
      return 2;
    }
    return isLogin;
  };
  // for player view

  switch (parseInt(checkIsLogin(props.playerScreen))) {
    case 1:
      playerPage = <Playerwelcome />;
      break;
    case 2:
      playerPage = <PlayerStart />;
      break;
    case 3:
      playerPage = <Introvideo />;
      break;
    case 4:
      playerPage = <Introtext />;
      break;
    case 5:
      playerPage = <PlayerQuestion />;
      break;
    case 6:
      playerPage = <PlayerAnswer />;
      break;
    case 7:
      playerPage = <PlayerCorrectAnswer />;
      break;
    case 8:
      playerPage = <PlayerQuestionLeaderBoard />;
      break;
    case 9:
      playerPage = <PlayerLeaderboard />;
      break;
    case 10:
      playerPage = <PlayerPodium />;
      break;
    case 11:
      playerPage = <PlayerStartIdle />;
      break;
    case 12:
      playerPage = <PlayerEdit />;
      break;
    case 13:
      playerPage = <PlayerPaused />;
      break;
    case 14:
      playerPage = <RoundResult />;
      break;
    case 15:
      playerPage = <GameResult />;
      break;
    case 16:
      playerPage = <RoundStart />;
      break;
    default:
      playerPage = <PlayerInvitation />;
      break;
  }

  useEffect(() => {
    if (localStage) {
      if (skin) {
        import(`../assets/skin/${skin}/css/carskin.scss`);
        import(`../assets/skin/${skin}/css/carskin-player.scss`);
      }
    }
  }, [skin, localStage]);
  return (
    <>
      {props.isLoader.isLoader && <Loader />}
      {/* skin-car */}
      <div
        className={`main-bg skin-${skin} player-skin ${
          props.control.control == 1 ? `full-screen-game ${background_v1}` : ``
        } ${
          [3, 4].includes(props.count)
            ? `custom-bg-2 ${background_v1}`
            : [5, 6, 7].includes(props.count)
            ? `custom-bg-1 ${background_v1}`
            : userType == 2
            ? `custom-bg-1 ${background_v1}`
            : [8, 9, 10, 12].includes(props.count)
            ? `custom-bg-1 ${background_v3}  `
            : `${background_v1}`
        } `}
      >
        {/* <div className={`main-bg  ${props.control.control == 1 ? `full-screen-game` : ``} ${[3, 4, 11].includes(parseInt(props.count)) ? `custom-bg-2` : [5, 6, 7,9].includes(parseInt(props.count)) ? `custom-bg-2` :[8].includes(parseInt(props.count)) ? `custom-bg-4`:[10].includes(parseInt(props.count)) ? `custom-bg-5`: userType == 2 ? `custom-bg-1` : ``} ` }> */}

        {isValid ? playerPage : <></>}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    count: state.counter.count,
    game: state.game,
    playerScreen: state.player.playerScreen,
    isLoader: state.isLoader,
    question: state.question.question,
    control: state.control,
  };
};

export default connect(mapStateToProps)(Circuit);
