import React, { useEffect, useState, useContext, useRef } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./playerstart.scss";
import { SocketContext } from "../../../../context/socket";
import { performRequest } from "../../../../api/apiHandler";
import Footer from "../components/Footer/index";
import TeamDetails from "../components/TeamDetails";
import { connect, useSelector } from "react-redux";
import { updateTeam } from "../../../../redux/Teams/team.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import Navbar from "../components/Header/index";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { addUser } from "../../../../redux/User/user.actions";
import { addGame } from "../../../../redux/Game/game.actions";

function GameResult(props) {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const { has_team, id, player,is_live,translations } = JSON.parse(localStorage.getItem("game"));

  const [rounds, setRounds] = useState([]);
  const windowHeight = window.innerHeight;
  const questionHeight = document.getElementById("pq-head")?.clientHeight;
  const scrollHeight = windowHeight - (questionHeight ? questionHeight : 0);
  const handleExit = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "game/" + id);
  };

  const updateStage = () => {
    const form = new FormData();
    form.append("game_id", id);
    form.append("stage", 15);
    form.append("user_id", player?.created_by);
    performRequest("post", "api/update-stage", form).then(() => {

    });
  };

  useEffect(() => {
    performRequest("get", "api/get-game-result/" + id + "/" + player?.id).then(
      (res) => {
        if (res?.data?.success) {
          if(!is_live){
          setRounds(res?.data?.data);
          }
          updateStage();
        }
      }
    );
  }, [props]);

  const { color } = JSON.parse(
    localStorage.getItem("profile") ?? '{"color": "transparent"}'
  );

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${r},${g},${b}`;
  };

  const hex2rgbaAlt = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${Math.round(r * 0.72)},${Math.round(g * 0.75)},${Math.round(
      b * 0.85
    )}`;
  };

  return (
    <div className="player-questions">
      <Navbar varient="contentheadershortplayerstart" />

      <div className="main-content">
        <div className="pq-whole-section pb-0 player-result-view">
          <div
            className="pq-head text-center no-progress"
            id="pq-head"
            style={{ "--team-color": `#fffff` }}
          >
            <span>{translations?.you_finish_the_game}</span>
          </div>
          <div
            className="qstn-scroll-sec"
            style={{
              maxHeight: scrollHeight - 120,
              marginTop: questionHeight + 20,
            }}
          >
            {true ? (
              <>
                {has_team ? (
                  <div
                    class="card text-left answer-list--card"
                    style={
                      has_team
                        ? {
                            background: `linear-gradient(225deg, rgba(${hex2rgba(
                              color
                            )},.8) 0%, rgba(${hex2rgba(color)},.4) 100%)`,
                          }
                        : {}
                    }
                  >
                    <div class="card-body">
                      <h3 class="card-title mb-3">{translations?.congrats}</h3>
                      <p class="card-text font-Comfortaa">{translations?.correct_answers}:</p>

                      <ul className="answer-list--items flex-column d-flex flex-wrap ps-0">
                        {rounds.map((e, i) => (
                          <li>
                            {translations?.round} {++i}:
                            <span>
                              {e[0]} of {e[1]}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div class="card text-left answer-list--card">
                    <div class="card-body">
                      <h3 class="card-title mb-3">{translations?.congrats}</h3>
                      <p class="card-text font-Comfortaa">{translations?.correct_answers}:</p>

                      <ul className="answer-list--items flex-column d-flex flex-wrap ps-0">
                        {rounds.map((e, i) => (
                          <li>
                            {translations?.round} {++i}:
                            <span>
                              {e[0]} of {e[1]}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                <div
                  class="alert alert-info my-4 text-dark text-start"
                  role="alert"
                >
                  <i class="fa-solid fa-circle-info me-2"></i>{translations?.check_dashboard_for_final_result}
                </div>
              </>
            ) : (
              <>
                <div class="card text-left answer-list--card">
                  <div class="card-body">
                    <h3 class="card-title mb-3">{translations?.congrats}</h3>
                    <p class="card-text font-Comfortaa">{translations?.correct_answers}:</p>

                    <ul className="answer-list--items flex-column d-flex flex-wrap ps-0">
                      {rounds.map((e, i) => (
                        <li>
                          {translations?.round} {++i}:
                          <span>
                            {e[0]} of {e[1]} {e[2]} pts
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  class="alert alert-info my-4 text-dark text-start"
                  role="alert"
                >
                  <i class="fa-solid fa-circle-info me-2"></i>{translations?.check_dashboard_for_final_result}
                </div>
              </>
            )}
            <div className="btn-group-player d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4 flex-column flex-sm-row">
              <button
                class="btn btn-primary dynamic-btn px-5 d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `linear-gradient(225deg, rgb(${hex2rgba(
                    color
                  )}) 0%, rgb(${hex2rgbaAlt(color)}) 100%)`,
                  "--element-color": hex2rgba(color),
                }}
                onClick={handleExit}
              >
                <i class="fa-solid fa-play me-2"></i>{translations?.continue}
              </button>
              <button
                class="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center text-white"
                onClick={handleExit}
                style={{ borderColor: `rgb(${hex2rgba(color)})` }}
              >
                <i class="fa-solid fa-right-from-bracket me-2"></i>{translations?.exit_game}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    game: state.game.game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    loader: (data) => dispatch(loader(data)),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addUser: (data) => dispatch(addUser(data)),
    addGame: (data) => dispatch(addGame(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameResult);
