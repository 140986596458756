import React from 'react';
import './index.scss';
import Loader from '../../assets/logo.gif';
export default function GameCompleted(props) {
    
    return (
        <>
            <div class="full-section">
                <div>
                    <div className="d-flex align-items-center justify-content-center error-design">
                        <h1>GAME</h1>
                    </div>
                    <p>Completed</p>
                </div>
            </div>
        </>
    )
}


