import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./welcome.scss";
import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import { changeSelection } from '../../../../redux/Selection/selection.actions';

import { socket } from "../../../../context/socket";
import { connect } from "react-redux";

import {
    toggleOption
}
    from "../../../../redux/Option/option.actions"


function PresentationPause(props) {
    const { id ,skin, translations} = JSON.parse(localStorage.getItem('game'));
    const currentSkin = props.game ?  props.game?.game?.skin?.game_skin_name:skin?.game_skin_name;
    const handlePause = () => {
        props.changeSelection(0);
        socket.emit('broadcast_stage', { player: props.memorize.memorize == 1 ? 11 : props.memorize.memorize, presentation: props.memorize.memorize, id: id })
    }

    const handleSkipToNext=()=> {
        // socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id });
        // socket.emit('broadcast_stage', { player: null, presentation: props.memorize.memorize,id:id })
        props.changeSelection(0);
        socket.emit('broadcast_stage', { player: props.memorize.memorize == 1 ? 11 : props.memorize.memorize, presentation: props.memorize.memorize, id: id })

    }


    return (
        <div className="presentation-controller-main position-relative" >
            <div className="navbar-section">
                <Navbar varient="normal" url={props.url} />
            </div>
            {/* <Navbar varient="contentheadersinglemenu" /> */}
            <Navbar varient="contentheadershort" />

            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <img src={`../../assets/skin/${currentSkin}/logo.png`} alt="" />
                <div className="game-paused-text-section mt-4 custom-light-navy-bg">
                    <h2 className="mb-0">{translations?.game_paused}</h2>
                </div>

            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer
                    varient="normal"
                    left={<GameButton varient="fboption" onclick={props.toggleOption} onmouseenter={props.toggleOption} ><Icons varient='option' />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2" varient="sbButton" onclick={handlePause}><Icons varient='startnow' />{translations?.unpause_game}</GameButton>}
                    centerTwo={<GameButton className="me-2" varient="sbButton" onclick={handleSkipToNext} ><Icons varient='skipintro' />{translations?.skip_to_next}</GameButton>} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        memorize: state.memorize,
        game: state.game,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
        changeSelection: (data) => dispatch(changeSelection(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PresentationPause)


