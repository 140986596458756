import React from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `${r},${g},${b}`;
};
function PresentationLeaderboard(props) {
  const { rounds, translations } = JSON.parse(localStorage.getItem("game"));
  const {
    scoreBoard,
    roundScores,
    teamScore,
    varient,
    animate,
    isReset,
    displayDetails,
  } = props;
  let Leaderboard = "";

  switch (varient) {
    case "normal":
      Leaderboard = (
        <div className="leader-board-section position-relative col-lg-7 col-md-9">
          <div className="pb-4">
            <div className="col-12 leader-board-table-header">
              <div className="row m-0">
                <div className="col-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>
                      <span>{translations?.round}</span>{" "}
                      {displayDetails?.round_order_no}
                    </h6>
                    <h6>
                      <span>{translations?.question}</span>{" "}
                      {displayDetails?.question_order_no}
                    </h6>
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex align-items-center player-head-adjust">
                    <div className="mx-2">
                      <p>{translations?.correct_answers}</p>
                    </div>
                    <div className="mx-2">
                      <p>{translations?.fast_correct_answer}</p>
                    </div>
                    <div className="mx-2">
                      <p>{translations?.points}</p>
                    </div>
                    <div className="mx-2">
                      <p>{translations?.round_points}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul className="leaderboard-ul-list p-0 m-0">
              {teamScore
                ? teamScore.map(function (eachTeam, index) {
                    let currentScore =
                      eachTeam.questions != null
                        ? eachTeam.questions.length
                          ? eachTeam.questions[0]
                          : null
                        : null;
                    let someColor = eachTeam.color;
                    let indexPlusOne = index + 1;
                    document.documentElement.style.setProperty(
                      "--question-leaderboard-color-" + indexPlusOne,
                      hex2rgba(someColor)
                    );
                    return (
                      isReset && (
                        <li
                          id={`team` + (index + 1)}
                          key={eachTeam.id}
                          className="team leader-board-table-row blue"
                          style={{ background: `none` }}
                        >
                          <div className="col-5 ps-1 d-flex align-items-center first-list-sec">
                            <span
                              className="list-item item1"
                              style={{
                                animationPlayState: `${
                                  animate ? `running` : `paused`
                                }`,
                                "--element-color": someColor,
                                "--element-color-rgb": hex2rgba(eachTeam.color),
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className="tr-count d-flex justify-content-center align-items-center me-1"
                                  style={{ background: eachTeam.color }}
                                >
                                  {index + 1}
                                </div>
                              </div>
                            </span>
                            <span
                              className="list-item item2 w-100 ps-3"
                              style={{
                                animationPlayState: `${
                                  animate ? `running` : `paused`
                                }`,
                                "--element-color": someColor,
                                "--element-color-rgb": hex2rgba(eachTeam.color),
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <p className="mb-0 player-name-long">
                                  {eachTeam.name}
                                </p>
                              </div>
                            </span>
                          </div>
                          <div className="col-7">
                            <div className="d-flex align-items-center justify-content-between player-point-adjust">
                              <span
                                className="list-item item3"
                                style={{
                                  animationPlayState: `${
                                    animate ? `running` : `paused`
                                  }`,
                                  "--element-color": someColor,
                                  "--element-color-rgb": hex2rgba(
                                    eachTeam.color
                                  ),
                                }}
                              >
                                <div className="correct-answer text-center">
                                  <p className="mx-2 mb-0">
                                    {currentScore?.correct_answers??'/'}
                                  </p>
                                </div>
                              </span>
                              <span
                                className="list-item item4 fast-correct-answer-span"
                                style={{
                                  animationPlayState: `${
                                    animate ? `running` : `paused`
                                  }`,
                                  "--element-color": someColor,
                                  "--element-color-rgb": hex2rgba(
                                    eachTeam.color
                                  ),
                                }}
                              >
                                <div className="fast-correct-answer text-center">
                                  <p className="mx-2 mb-0">
                                    {currentScore
                                      ? currentScore.response_speed
                                        ? currentScore.response_speed + " Sec"
                                        : "/"
                                      : "/"}
                                  </p>
                                </div>
                              </span>
                              <span
                                className="list-item item5"
                                style={{
                                  animationPlayState: `${
                                    animate ? `running` : `paused`
                                  }`,
                                  "--element-color": someColor,
                                  "--element-color-rgb": hex2rgba(
                                    eachTeam.color
                                  ),
                                }}
                              >
                                <div className="points text-center">
                                  <p className="mx-2 mb-0">
                                    {currentScore?.score??'0'}
                                  </p>
                                </div>
                              </span>
                              <span
                                className="list-item item6 pe-4"
                                style={{
                                  animationPlayState: `${
                                    animate ? `running` : `paused`
                                  }`,
                                  "--element-color": someColor,
                                  "--element-color-rgb": hex2rgba(
                                    eachTeam.color
                                  ),
                                }}
                              >
                                <div className="round-points text-center">
                                  <p className="mx-2 mb-0">
                                    {eachTeam?.total_score}
                                  </p>
                                </div>
                              </span>
                            </div>
                          </div>
                        </li>
                      )
                    );
                  })
                : ""}
            </ul>
          </div>
        </div>
      );
      break;
    case "roundlb":
      Leaderboard = (
        <div className="leader-board-section col-lg-6 col-md-8 position-relative custom-padding-for-ltable mb-4">
          <div className="custom-padding-for-ltable">
            <div className="col-12 leader-board-table-header">
              <div className="row m-0">
                <div className="col-4">
                  <div className="d-flex align-items-center">
                    <h6>
                      {translations?.round} {displayDetails?.order_no}{" "}
                      {translations?.of} {rounds.length}
                    </h6>
                  </div>
                </div>
                <div className="col-8 p-0">
                  <div
                    className={`d-flex align-items-center player-head-round round-teb-head ${
                      scoreBoard[0]?.questionScore?.length > 10
                        ? "long-round"
                        : ""
                    }`}
                  >
                    {scoreBoard.length
                      ? scoreBoard[0].questionScore.map((question, index) => {
                          return (
                            <p key={question.id} className="mb-0">
                              {index + 1}
                            </p>
                          );
                        })
                      : ""}
                    <p className="mb-0 last-round">{translations?.round}</p>
                    <p className="mb-0 last-total">{translations?.total}</p>
                  </div>
                </div>
              </div>
            </div>
            <ul className="leaderboard-ul-list p-0 m-0">
              {scoreBoard.map((score, index) => {
                let TotalQuestionScore = 0;
                let roundLength = score.questionScore.length;
                let someColor = score.color;
                let indexPlusOne = index + 1;
                document.documentElement.style.setProperty(
                  "--question-leaderboard-color-" + indexPlusOne,
                  hex2rgba(someColor)
                );
                return (
                  <li
                    id={`team` + (index + 1)}
                    key={`team` + (index + 1)}
                    className="team leader-board-table-row w-100 blue"
                    style={{ background: "none" }}
                  >
                    <div className="col-4 ps-1 d-flex align-items-center first-list-sec">
                      <span
                        className="list-item item1 ps-1"
                        style={{
                          animationPlayState: `${
                            animate ? `running` : `paused`
                          }`,
                          "--element-color": someColor,
                          "--element-color-rgb": hex2rgba(score.color),
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className="tr-count d-flex justify-content-center align-items-center me-2"
                            style={{ background: score.color }}
                          >
                            {index + 1}
                          </div>
                        </div>
                      </span>

                      <span
                        className="list-item item2 ps-3 w-100"
                        style={{
                          animationPlayState: `${
                            animate ? `running` : `paused`
                          }`,
                          "--element-color": someColor,
                          "--element-color-rgb": hex2rgba(score.color),
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <p className="mb-0 player-name pt-0">{score.name}</p>
                        </div>
                      </span>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center justify-content-between player-point-round">
                        {score.questionScore.map((each, key) => {
                          if (each) {
                            TotalQuestionScore += each.score;
                            return (
                              <span
                                key={`round-leader` + each.score}
                                className={`list-item item` + (key + 3)}
                                style={{
                                  "--element-color-rgb": hex2rgba(score.color),
                                  animationPlayState: `${
                                    animate ? `running` : `paused`
                                  }`,
                                  "--element-color": someColor,
                                }}
                              >
                                <p key={each.id} className="mb-0">
                                  {each.score}
                                </p>
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={`round-leader` + each.score}
                                className={`list-item item` + (key + 3)}
                                style={{
                                  animationPlayState: `${
                                    animate ? `running` : `paused`
                                  }`,
                                  "--element-color": someColor,
                                  "--element-color-rgb": hex2rgba(score.color),
                                }}
                              >
                                <p className=" mb-0">0</p>
                              </span>
                            );
                          }
                        })}
                        <span
                          className={`list-item item` + (roundLength + 3)}
                          style={{
                            animationPlayState: `${
                              animate ? `running` : `paused`
                            }`,
                            "--element-color": someColor,
                            "--element-color-rgb": hex2rgba(score.color),
                          }}
                        >
                          <div className="round-table text-center">
                            <p className="mb-0">{TotalQuestionScore}</p>
                          </div>
                        </span>
                        <span
                          className={`list-item item` + (roundLength + 4)}
                          id="endAnimation1"
                          style={{
                            animationPlayState: `${
                              animate ? `running` : `paused`
                            }`,
                            "--element-color": someColor,
                            "--element-color-rgb": hex2rgba(score.color),
                          }}
                        >
                          <div className="total-table text-center">
                            <p className="mb-0 total-text">
                              {TotalQuestionScore +
                                score.previousScore +
                                (score.roundScore
                                  ? score.roundScore.extra_score
                                  : 0)}
                            </p>
                          </div>
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
      break;
    case "podiumlb":
      Leaderboard = (
        <div className="leader-board-section position-relative col-lg-6 col-md-8 custom-padding-for-ltable mb-5">
          {roundScores.map((score, index) => {
            return (
              !index && (
                <div key={score.id} className="leader-board-table-header ps-3">
                  <div className="row m-0">
                    <div className="col-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>{translations?.end_scores}</h6>
                      </div>
                    </div>
                    <div className="col-8 ps-0">
                      <div className="d-flex align-items-center podium-thead">
                        {score.roundScore.length
                          ? score.roundScore.map((e, i) => {
                              return (
                                <p key={`round` + i} className="mb-0">
                                  {i + 1}
                                </p>
                              );
                            })
                          : ""}
                        <p className="mb-0">{translations?.total}</p>
                        {score.most_round_win && (
                          <p className="mb-0">Round Winner</p>
                        )}
                        {score.is_round_correct_answer && (
                          <p className="mb-0">Round Correct Answers</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
          <ul className="leaderboard-ul-list p-0 m-0">
            {roundScores.map((score, index) => {
              let roundTotal = 0;
              let roundLength = score.roundScore.length;
              let indexPlusOne = index + 1;
              document.documentElement.style.setProperty(
                "--podium-leaderboard-color-" + indexPlusOne,
                hex2rgba(score.color)
              );

              return (
                <li
                  id={`team` + (index + 1)}
                  key={score.id}
                  className="team leader-board-table-row w-100 blue"
                  style={{ background: "none" }}
                >
                  <div className="col-4 ps-1 d-flex align-items-center first-list-sec">
                    <span
                      className="list-item item1"
                      style={{
                        "--element-color": score.color,
                        "--element-color-rgb": hex2rgba(score.color),
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="tr-count d-flex justify-content-center align-items-center me-2"
                          style={{ background: score.color }}
                        >
                          {index + 1}
                        </div>
                      </div>
                    </span>

                    <span
                      className="list-item item2 w-100 ps-3"
                      style={{
                        "--element-color-rgb": hex2rgba(score.color),
                        "--element-color": score.color,
                      }}
                    >
                      <div className="d-flex align-items-center text-nowrap">
                        <p className="mb-0 lb-team-name d-inline-block text-truncate long-name-v2">
                          {score.name}
                        </p>
                      </div>
                    </span>
                  </div>
                  <div className="podium-tbody">
                    {score.roundScore
                      ? score.roundScore.map((eachScore, value) => {
                          if (eachScore) {
                            roundTotal += eachScore.score;
                            return (
                              <span
                                key={value}
                                className={
                                  `list-item item` +
                                  (score.roundScore.indexOf(eachScore) + 3)
                                }
                                style={{
                                  "--element-color": score.color,
                                  "--element-color-rgb": hex2rgba(score.color),
                                }}
                              >
                                <p key={eachScore.id} className="mb-0">
                                  {eachScore.score}
                                </p>
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={value}
                                className={`list-item item` + (value + 3)}
                                style={{
                                  "--element-color": score.color,
                                  "--element-color-rgb": hex2rgba(score.color),
                                }}
                              >
                                <p className="mb-0">0</p>
                              </span>
                            );
                          }
                        })
                      : ""}
                    <span
                      className={
                        `list-item pe-4 podium-total item` + (roundLength + 3)
                      }
                      style={
                        score.most_round_win || score.is_round_correct_answer
                          ? { "--element-color": score.color }
                          : {
                              "--element-color-rgb": hex2rgba(score.color),
                              borderTopRightRadius: "30px",
                              borderBottomRightRadius: "30px",
                              "--element-color": score.color,
                            }
                      }
                    >
                      <div className="mx-3 total-table text-center">
                        <p className="mb-0" style={{ lineHeight: "21px" }}>
                          {roundTotal}
                        </p>
                      </div>
                    </span>
                    {score.most_round_win && (
                      <span
                        className={
                          `list-item pe-4 podium-total item` + (roundLength + 2)
                        }
                        style={{
                          "--element-color-rgb": hex2rgba(score.color),
                          borderTopRightRadius: "30px",
                          borderBottomRightRadius: "30px",
                          "--element-color": score.color,
                        }}
                      >
                        <div className="mx-3 total-table text-center">
                          <h6 className="mb-0" style={{ lineHeight: "21px" }}>
                            {score.round_winner_count}
                          </h6>
                        </div>
                      </span>
                    )}
                    {score.is_round_correct_answer && (
                      <span
                        className={
                          `list-item pe-4 podium-total item` + (roundLength + 2)
                        }
                        style={{
                          "--element-color-rgb": hex2rgba(score.color),
                          borderTopRightRadius: "30px",
                          borderBottomRightRadius: "30px",
                          "--element-color": score.color,
                        }}
                      >
                        <div className="mx-3 total-table text-center">
                          <h6 className="mb-0" style={{ lineHeight: "21px" }}>
                            {score.total_round_correct}
                          </h6>
                        </div>
                      </span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
      break;

    default:
      Leaderboard = (
        <div className="leader-board-section position-relative mb-5">
          <div className="col-12">
            <div className="row m-0">
              <div className="col-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Round 1</h5>
                  <h5>Question 6</h5>
                </div>
              </div>
              <div className="col-7">
                <div className="d-flex align-items-center">
                  <p className="mx-2">Correct answers</p>
                  <p className="ms-4 me-2">Fast correct answer</p>
                  <p className="ms-4">Points</p>
                  <p className="ms-3">Round points</p>
                </div>
              </div>
            </div>
          </div>
          <div className="leader-board-table-row w-100 blue mb-2">
            <div className="col-12 xtra-border">
              <div className="row m-0 align-items-center">
                <div className="col-5 ps-1">
                  <div className="d-flex align-items-center">
                    <div className="tr-count d-flex justify-content-center align-items-center me-2">
                      1
                    </div>
                    <p className="mb-0">Team Name A</p>
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex align-items-center">
                    <div className="correct-answer text-end">
                      <p className="mx-2 mb-0">12</p>
                    </div>
                    <div className="fast-correct-answer text-end">
                      <p className="ms-4 me-2 mb-0">7 Sec</p>
                    </div>
                    <div className="points text-center">
                      <p className="ms-4 mb-0">2</p>
                    </div>
                    <div className="round-points text-center">
                      <p className="ms-3 mb-0">14</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leader-board-table-row w-100 green mb-2">
            <div className="col-12 xtra-border">
              <div className="row m-0">
                <div className="col-5 ps-1">
                  <div className="d-flex align-items-center">
                    <div className="tr-count d-flex justify-content-center align-items-center me-2">
                      1
                    </div>
                    <p className="mb-0">Team Name A</p>
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex align-items-center">
                    <div className="correct-answer text-end">
                      <p className="mx-2 mb-0">12</p>
                    </div>
                    <div className="fast-correct-answer text-end">
                      <p className="ms-4 me-2 mb-0">7 Sec</p>
                    </div>
                    <div className="points text-center">
                      <p className="ms-4 mb-0">2</p>
                    </div>
                    <div className="round-points text-center">
                      <p className="ms-3 mb-0">14</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leader-board-table-row w-100 lightblue mb-2">
            <div className="col-12 xtra-border">
              <div className="row m-0">
                <div className="col-5 ps-1">
                  <div className="d-flex align-items-center">
                    <div className="tr-count d-flex justify-content-center align-items-center me-2">
                      1
                    </div>
                    <p className="mb-0">Team Name A</p>
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex align-items-center">
                    <div className="correct-answer text-end">
                      <p className="mx-2 mb-0">12</p>
                    </div>
                    <div className="fast-correct-answer text-end">
                      <p className="ms-4 me-2 mb-0">7 Sec</p>
                    </div>
                    <div className="points text-center">
                      <p className="ms-4 mb-0">2</p>
                    </div>
                    <div className="round-points text-center">
                      <p className="ms-3 mb-0">14</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leader-board-table-row w-100 red mb-2">
            <div className="col-12 xtra-border">
              <div className="row m-0">
                <div className="col-5 ps-1">
                  <div className="d-flex align-items-center">
                    <div className="tr-count d-flex justify-content-center align-items-center me-2">
                      1
                    </div>
                    <p className="mb-0">Team Name A</p>
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex align-items-center">
                    <div className="correct-answer text-end">
                      <p className="mx-2 mb-0">12</p>
                    </div>
                    <div className="fast-correct-answer text-end">
                      <p className="ms-4 me-2 mb-0">7 Sec</p>
                    </div>
                    <div className="points text-center">
                      <p className="ms-4 mb-0">2</p>
                    </div>
                    <div className="round-points text-center">
                      <p className="ms-3 mb-0">14</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="leader-board-table-row w-100 cyan mb-2">
            <div className="col-12 xtra-border">
              <div className="row m-0">
                <div className="col-5 ps-1">
                  <div className="d-flex align-items-center">
                    <div className="tr-count d-flex justify-content-center align-items-center me-2">
                      1
                    </div>
                    <p className="mb-0">Team Name A</p>
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex align-items-center">
                    <div className="correct-answer text-end">
                      <p className="mx-2 mb-0">12</p>
                    </div>
                    <div className="fast-correct-answer text-end">
                      <p className="ms-4 me-2 mb-0">7 Sec</p>
                    </div>
                    <div className="points text-center">
                      <p className="ms-4 mb-0">2</p>
                    </div>
                    <div className="round-points text-center">
                      <p className="ms-3 mb-0">14</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
  }
  return <div>{Leaderboard}</div>;
}
const mapStateToProps = (state) => {
  return {
    question: state.question.question,
  };
};
export default connect(mapStateToProps)(PresentationLeaderboard);
