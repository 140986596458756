import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types'
import axios from 'axios';
import {has} from 'lodash';
import {connect} from "react-redux";
/**Local*/
 const API_URL = process.env.REACT_APP_API_URL;

export const performRequest = (method, url, params, data, login = true) => {
    const body = method === 'get' || method === 'delete' || method === 'put' || method == 'patch' ? 'params' : 'data';
    const config = {
        method,
        url,
        baseURL: API_URL,
        [body]: params || {},
    };
    if (method === 'put' || method == 'patch') {
        config.data = data
    }
    config.headers = {
        "Content-Type": "*",
        "Access-Control-Allow-Headers":"*",
        "Access-Control-Allow-Origin": '*',
        // "Access-Control-Request-Method":"*"

    };
    if (login) {
        config.headers.Authorization = "Bearer " + localStorage.getItem('accessToken')
    }
    return axios.request(config)
    // Add a request interceptor
};

// axios.interceptors.response.use((response) => {
//     return response
// }, function (error) {
//     let token = localStorage.getItem('accessToken');
//     const {config, response: {status}} = error
//     const originalRequest = config;
//     if (status === 403) {
//         performRequest('post', 'api-token-refresh/', {'token': token})
//             .then(response => {
//                 localStorage.setItem('accessToken', response.data.token)
//             })
//             .catch(error => {
//                 localStorage.removeItem('accessToken');
//                 window.location = '/';
//             })
//     }
//     return Promise.reject(error)
// });
// connect(null, addProgress)(performRequest);