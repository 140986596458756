import { ADDTEAM, UPDATETEAM, DELETEPLAYERTEAM } from "./team.types";
const INITIAL_STATE = {
  teams: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADDTEAM:
      return {
        ...state,
        teams: action.data,
      };
    case UPDATETEAM:
      const data = [...state.teams];
      return {
        ...state,
        teams: data
          .map((each) => ({
            ...each,
            players: each.players.filter(
              (player) => player.id !== action.data.id
            ),
          }))
          .map((each) => {
            if (each.id === action.id) {
              each.players.push(action.data);
            }
            return each; 
          }),
      };
    case DELETEPLAYERTEAM:
      const teams = [...state.teams];
      teams.map((each) => {
        // const filteredPeople = people.filter((item) => item.id !== idToRemove);
        if (each.id == action.id) {
          each.players = each.players.filter((item) => item.id !== action.data);
        }
      });
      return {
        ...state,
        teams: teams,
      };
    default:
      return state;
  }
};

export default reducer;
