import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { addQuestion } from "../../../../redux/Questions/question.actions";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { addSelectedAnswer } from "../../../../redux/SelectedAnswers/selectedAnswers.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import { options } from "../../../../constant";
import { performRequest } from "../../../../api/apiHandler";

import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import TeamDetails from "../components/TeamDetails";
import "./playeranswer.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function PlayerAnswer(props) {
  // const question = props.question.question;
  const profile = JSON.parse(localStorage.getItem("profile"));

  const [answers, setAnswers] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [totalQuestion, setTotalQuestion] = useState(1);
  const { id, is_live,translations } = JSON.parse(localStorage.getItem("game"));
  let playerQuestion = JSON.parse(localStorage.getItem("playerQuestion"));
  const questionHeight = document.getElementById("pq-head")?.clientHeight;
  const windowHeight = window.innerHeight;
  const scrollHeight = windowHeight - (questionHeight ? questionHeight : 0);
  const checkQuestionAvailable = () => {
    const playerSelected = JSON.parse(localStorage.getItem("playerSelected"));
    const selectedAnswer = props.selectedAnswer;

    if (question) {
      return false;
    } else if (playerQuestion) {
      // check selected ansewer
      playerQuestion.isPresented = false;
      const updatedQuestion = playerQuestion;
      localStorage.setItem("playerQuestion", JSON.stringify(updatedQuestion));
      if (playerSelected?.length) {
        if (playerSelected[0].question_id == playerQuestion.question.id) {
          props.addSelectedAnswer(playerSelected);
        } else {
          props.addSelectedAnswer([]);
        }
      }
      return false;
    } else {
      return true;
    }
  };

  const handleContinue = () => {
    props.changePlayerStage(7);
  };

  useEffect(() => {
    const getQuestion = props.question?.question
      ? props.question.question
      : playerQuestion?.question;
    setQuestion(getQuestion);
    localStorage.setItem("playerStage", 6);
    if (checkQuestionAvailable()) {
      props.changePlayerStage(11);
    } else {
      props.loader(true);
      const formData = new FormData();
      formData.append("question_id", getQuestion.id);
      formData.append("team_id", profile?.team);
      performRequest('post', 'api/get-team-answers', formData)
          .then(response => {

      props.loader(false);
      const answersByTeam = response.data.data;
   
      const answerList = answersByTeam.map((e) => e.id);
      setAnswerList(answerList);
      setAnswers(answersByTeam);
      let totalQuestions = 0;
      answersByTeam.map((eachAnswer) => {
        totalQuestions += eachAnswer.team_answer.length;
      });
      setTotalQuestion(totalQuestions);
      })

      if (props.selectedAnswer && !props.selectedAnswer.length) {
        const formData = new FormData();
        var isRoundEnd = false;
        formData.append("game_id", id);
        formData.append("question_id", getQuestion.id);
        formData.append("player_id", profile?.id);
        formData.append("answer_ids", JSON.stringify([]));
        formData.append("response_speed", 0);
        formData.append("is_round_end", isRoundEnd);
        performRequest("post", "api/save-question-answer", formData).then(
          (response) => {}
        );
      }
    }
  }, []);

  return (
    <div className="player-questions player-answers">
      <Navbar varient="contentheadershortplayer" />
      <div className="main-content">
        <div className="pq-whole-section pb-md-3 pb-0">
          <div
            className="pq-head text-center"
            id="pq-head"
            style={{ "--team-color": "#" + profile?.color }}
          >
            <h2>
              <span
                dangerouslySetInnerHTML={{
                  __html: question ? question.description : "?",
                }}
              ></span>
            </h2>
          </div>
          {props.selectedAnswer && (
            <div
              className="answer-option-section col-12 qstn-scroll-sec"
              style={{
                marginTop: questionHeight + 30,
                maxHeight: scrollHeight - 130,
              }}
            >
              <div className="col-12 my-2">
                {props.selectedAnswer.length ? (
                  <div className="selected-answer wrong-a">
                    <div className="text-start">
                      <h6 className="ps-3 mb-3">{translations?.your_answer}</h6>
                    </div>
                    {props.selectedAnswer
                      ? props.selectedAnswer.map((answer, index) => {
                          let correctIndex = answerList.indexOf(answer.id);
                          let optionStyle = [
                            "first",
                            "second",
                            "third",
                            "fourth",
                            "first",
                            "second",
                          ];

                          return (
                            <div
                              key={`your-answer-${answer.id}`}
                              className={`${optionStyle[correctIndex]} answer-option mb-4`}
                            >
                              <div className="col-12 ">
                                <div className="row mx-0">
                                  <div className="col-2 p-0">
                                    <div className="option-count d-flex align-items-center justify-content-center w-100 h-100 font-racing">
                                      {
                                        options[
                                          correctIndex != -1 ? correctIndex : 0
                                        ]
                                      }
                                    </div>
                                  </div>
                                  <div className="col-10 p-0">
                                    <div
                                      className="option-detail text-start pa-option-detail correct-ans"
                                      dangerouslySetInnerHTML={{
                                        __html: answer.name,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                ) : (
                  <div className="no-answer-select mb-4 text-start">
                    <h4 className="ps-3">
                      {translations?.no_answer_on_time}
                      
                    </h4>
                  </div>
                )}

                <div className="whole-teams-answer text-start">
                  <h6 className="ps-3">{translations?.teams_answers}</h6>
                  {answers.map((answer) => {
                    let percentage = answer.team_answer.length
                      ? (answer.team_answer.length / totalQuestion) * 100
                      : 0;
                    return (
                      <div
                        key={`team-answer-${answer.id}`}
                        className={
                          answer.is_correct
                            ? "first-answer my-2  right-a"
                            : "first-answer my-2  wrong-a"
                        }
                      >
                        <div
                          className="select-status my-3"
                          style={{
                            width:
                              (answer.team_answer.length / totalQuestion) *
                                100 +
                              "%",
                          }}
                        ></div>
                        <div className="d-flex align-items-center justify-content-between gap-3">
                          <div className="">
                            <div
                              className="answer-options text-start space-wrap"
                              dangerouslySetInnerHTML={{ __html: answer.name }}
                            ></div>
                          </div>
                          <div className="">
                            <div className="answer-status-percentage text-end">
                              {parseFloat(percentage).toFixed(2)}%
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {!is_live ? (
                  <div className="btn-group-player d-flex align-items-center justify-content-between gap-2 flex-wrap mt-4 flex-column flex-sm-row">
                    <button
                      class="btn btn-primary px-5 d-flex justify-content-center align-items-center"
                      onClick={handleContinue}
                    >
                      <i class="fa-solid fa-play me-2"></i>{translations?.continue}
                    </button>
                    <button
                      class="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center text-white"
                      onClick=""
                    >
                      <i class="fa-solid fa-right-from-bracket me-2"></i>{translations?.exit_game}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    question: state.question.question,
    selectedAnswer: state.selectedAnswer.selectedAnswer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addQuestion: (data) => dispatch(addQuestion(data)),
    addSelectedAnswer: (data) => dispatch(addSelectedAnswer(data)),
    loader: (data) => dispatch(loader(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerAnswer);
