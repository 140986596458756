import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";

import Footer from "../components/Footer/index";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import Navbar from "../components/Header/index";

import { addQuestionScore } from '../../../../redux/QuestionScore/questionScore.actions';
import { graph } from '../../../../constant';
import { performRequest } from "../../../../api/apiHandler";
import { socket } from '../../../../context/socket';
import { loader } from "../../../../redux/Loader/loader.actions";
import { toggleOption } from "../../../../redux/Option/option.actions"
import { changePresentationStage } from "../../../../redux/Counter/counter.actions";
import { changeAnimationStatus } from "../../../../redux/Animations/animation.actions"
import { toggleAudio } from "../../../../redux/Audio/audio.actions";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./showplayeranswer.scss";
import { useRef } from "react";

function Showplayeranswer(props) {
    const question = props.question ? props.question.question : JSON.parse(localStorage.getItem('question'));
    let selectedOptions = [];
    let totalPlayers = 0;
    const { rounds, id,translations } = JSON.parse(localStorage.getItem('game'));
    const [animate, setAnimation] = useState(true);
    const questionScore = props.questionScore;
    const [isReplay, setReplay] = useState(true);
    const [animationState, setAnimationState] = useState(true);
    const [anmationEnd, setAnimationEnd] = useState(false);
    const [isReset, setReset] = useState(true);
    const animated = useRef('');
    const { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };
 


    const handleSkipToNextNew = () => {
        if (rounds[round_pos].questions.length > question_pos + 1) {
            socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
            localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos, question_pos: question_pos + 1 }));
        } else {

            if (rounds[round_pos].questions.length == 1) {
                if (rounds.length > round_pos + 1) {
                    localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos + 1, question_pos: 0 }));
                }
                socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })

            } else {
                if (rounds.length > round_pos + 1) {
                    localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos + 1, question_pos: 0 }));
                }
                socket.emit('broadcast_stage', { player: 9, presentation: 9, id: id })
            }
        }
    }

    const handleSkipToNext = () => {
        props.changeAnimationStatus(0);
        socket.emit('broadcast_stage', { player: 7, presentation: 7, id: id })
    }

    const handleAnimation = () => {
        props.changeAnimationStatus(animationState ? 1 : 0);
        setAnimationState(!animationState);
    }
    let timeOut = 0;
    const handleReplay = () => {
        setAnimation(true)

        props.changeAnimationStatus(0);
        setReset(false)
        if (timeOut) {
            clearTimeout(timeOut);
            timeOut = 0;
        }
        timeOut = setTimeout(() => {
            setReset(true)

        }, 500)
    }

    useEffect(() => {
        if (!animate) {
            handleReplay()
        }
    }, [props.animationStatus])

    useEffect(() => {
        const el = document.getElementById('animated');
        if (animated?.current)
            animated.current.addEventListener("animationend", function () {
                setReplay(false);
                props.changeAnimationStatus(2);
                setAnimation(false);
            }, false);
    }, [props.questionScore, isReset])

    useEffect(() => {
        const question_id = question?.id;
        props.loader(true);
        // props.toggleAudio(false);
        props.changeAnimationStatus(0);
        localStorage.removeItem('answered');
        console.log(question,'questions');
        if (question) {
            if (question.is_active)
                handleSkipToNextNew();
            else
                performRequest('get', 'api/teams-score/' + question_id)
                    .then(response => {
                        props.loader(false);
                        const optionsData = response.data.data.options;
                        optionsData.map((opt) => {
                            const data = {
                                'id': opt.id,
                                'option': opt.name,
                                'choosen': opt.choosen,
                                'is_correct': opt.is_correct
                            }
                            totalPlayers += opt.choosen.length;
                            selectedOptions.push(data);
                        })
                        let setQuestionScores = {
                            'options': selectedOptions,
                            'total_players': totalPlayers
                        };
                        // setQuestionScores['options'] = selectedOptions;
                        // setQuestionScores['total_players'] = totalPlayers;
                        localStorage.setItem('questionScores', JSON.stringify(setQuestionScores));
                        props.addQuestionScore(setQuestionScores);
                    })
                    .catch(error => {
                        props.loader(false);
                    })
        } else {
            props.changePresentationStage(5);
        }

        socket.on('get_answer_animation', (arg) => {
            setAnimationState(arg)
        })
        const keyDownHandler = event => {
            if (event.key == 'ArrowRight') {
                handleSkipToNext();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, []);


    return (
        <div className="presentation-question position-relative dh-full">
            <div className="navbar-section">
                <Navbar varient="normal" />
            </div>
            <Navbar varient="contentheadershort" />
            <div className="main-content">
                <div className="pq-whole-section">
                    <div className="pq-head text-center pt-4" dangerouslySetInnerHTML={{ __html: question ? question.description : '' }}>
                    </div>
                    <div className="graph-section">
                        <div className="d-flex justify-content-center align-items-start gap-4">
                            {
                                questionScore && isReset ? questionScore.options.map(function (answer, index) {
                                    
                                    let percentage = answer.choosen.length ? (answer.choosen.length / questionScore.total_players) * 100 : 0;
                                    return <div key={answer.id} className={graph[index]} >
                                        <div className="graph-bar-section d-flex flex-column justify-content-end align-items-center mb-2">
                                            <div className={`ps-trans-background`} >
                                                <GraphProgress index={index} value={parseFloat(percentage).toFixed(2)} delay={index} animationState={animationState} key={index} />
                                            </div>
                                            <div className="graph-bar" id="animated"
                                                ref={animated}
                                                style={animate ? {
                                                    minHeight: '0%',
                                                    maxHeight: answer.choosen.length ? ((answer.choosen.length / questionScore.total_players) * 100) + '%' : '0%',
                                                    height: '0%',
                                                    animation: index ? `graph-animation 7s ease ${(index)}s 1` : `graph-animation 7s ease 1`,
                                                    animationPlayState: props.animationStatus ? 'paused' : 'running',
                                                    animationFillMode: 'forwards',
                                                } : { height: answer.choosen.length ? ((answer.choosen.length / questionScore.total_players) * 100) + '%' : '0%' }}

                                            ></div>
                                        </div>
                                        <div className="answer-section mb-5" dangerouslySetInnerHTML={{ __html: answer.option.length > 25 ? answer.option.substring(0, 25) + "..." : answer.option }}>
                                        </div>
                                    </div>;
                                }) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer
                    varient="normal"
                    left={<GameButton varient="fboption" onmouseenter={props.toggleOption}><Icons varient='option' color="#aa6600" />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2" varient="sbButton" onclick={handleSkipToNext}><Icons varient='star' color="#aa6600" />{translations?.show_correct_answer}</GameButton>}
                    centerTwo={props.animationStatus != 2 ? <GameButton className="me-2" varient="sbButton" onclick={handleAnimation} >{!props.animationStatus ? <><Icons color="#aa6600" varient='pause' />{translations?.pause_animation}</> : <><Icons color="#aa6600" varient='startnow' />{translations?.play_animation}</>}</GameButton> : <GameButton className="me-2" varient="sbButton" onclick={handleReplay} ><><Icons varient='faUndo' color="#aa6600" />{translations?.replay_animation}</></GameButton>} />
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        question: state.question.question,
        questionScore: state.questionScore.questionScore,
        animationStatus: state.animationStatus.animationStatus,
        audioToggle: state.audioToggle.audioToggle,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        addQuestionScore: (data) => dispatch(addQuestionScore(data)),
        changePresentationStage: (data) => dispatch(changePresentationStage(data)),
        toggleOption: () => dispatch(toggleOption()),
        loader: (data) => dispatch(loader(data)),
        changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data)),
        toggleAudio: (data) => dispatch(toggleAudio(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Showplayeranswer);

const GraphProgress = React.memo(({ value, delay, animationState, }) => {
    const refCounter = useRef(null);
    // const refCounterPaused = useRef('');
    // const pause = useRef('');
    const counter = useRef(0);
    const timer = useRef(null);
    const setTimeoutTimer = useRef(null)
    const [isPaused, setisPaused] = useState(false)
    const [data, setData] = useState({
        startAt: 0,
        time: 250
    });

    const progressLogic = (_delay= false) => {
        // let counter = data.startAt;
        // const counterPaused = parseInt(refCounterPaused.current.innerText.replace("%", ""));
        clearInterval(timer.current)
        clearTimeout(setTimeoutTimer.current)
        if(animationState){
            // let counter = value ;
            let intervalTime = Math.floor(data.time / (value - data.startAt));
            let interval = null;
            const counterPaused = parseFloat(refCounter?.current?.innerText.replace("%",'')).toFixed(2);
            if(counterPaused >= value) clearTimeout(setTimeoutTimer.current)
            setTimeoutTimer.current = setTimeout(() => {
                if (counterPaused != value)
                    timer.current = setInterval(intervalHandler, intervalTime)
            }, _delay ? 10 : (!isPaused ? delay * 1000 : delay * 100) );
        }
    }

    function intervalHandler() {
        const counterPaused = parseFloat(refCounter?.current?.innerText.replace("%",'')).toFixed(2);

        // if(counter.current >= value){
        //     console.log('counter =>', counter.current)
        //      clearInterval(timer.current);
        //      return false
        // }
        let _counter = counterPaused >= parseFloat(value) ? parseFloat(value)-0.05 : counterPaused;
        _counter =parseFloat(_counter)+ parseFloat((value - data.startAt) / Math.abs(value - data.startAt) * 0.05);
        if (refCounter.current) refCounter.current.innerText = _counter >= parseFloat(value)? parseFloat(value).toFixed(2)+'%':parseFloat(_counter).toFixed(2) + '%';
        if (_counter >= value ) {
            // if (counter >= value || pause?.current?.innerText == 1 ) {
            clearInterval(timer.current)
                // clearInterval(interval)
        }

        // counter.current = _counter;
    }

    useEffect(() => {
        if (value) progressLogic();
    }, [value]);

    useEffect(() => {
        if (!animationState) {
            clearInterval(timer.current)
            clearTimeout(setTimeoutTimer.current)
            setisPaused(true)
        } else{
            if(animationState && timer.current) progressLogic(true)
            else {
                progressLogic()
            }
            // if (pause)

            //     pause.current.innerText = 0;
            // if (value) progressLogic();
        }

    }, [animationState])

    // return <><span ref={pause} style={{ display: "none" }}>1</span><span style={{ display: `${animationState ? 'none' : ''}` }} ref={refCounterPaused}>0%</span><></><span style={{ display: `${animationState ? '' : 'none'}` }} ref={refCounter}></span></>
    return <span ref={refCounter}>0.00%</span>
})
