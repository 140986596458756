import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import GameButton from "../Button/index";
import Icons from './../Icons/index';
import Shortfooter from '../LayoutComponents/Footer';
import Longfooter from '../LayoutComponents/LongFooter';
import Logogoodcomp from '../assets/images/logo.png';
import { socket } from '../../../../../context/socket';
import { updateMemorize } from '../../../../../redux/Memorize/memorize.actions';
import { changeControl } from "../../../../../redux/Control/control.actions";
import { useDispatch } from 'react-redux';

function Footer(props) {
    const teams = props.teams;
    const game = props.game;
    const [teamMaxSize, setTeamMaxSize] = useState(25);
    const brand_image = game?.brand_image.includes('http') ? game.brand_image : false;
    const localStorageGame = JSON.parse(localStorage.getItem('game'));
    const skin = props.game ? props.game?.skin?.game_skin_name : localStorageGame?.skin?.game_skin_name;

    const rounds_count = game ? game.rounds_count : 1;
    const totalPlayers = teams.reduce(
        (previousValue, currentValue, index) => previousValue + currentValue.players.length, 0);
    let Footer = '';
    let varient = props.varient;

    const dispatch = useDispatch();
    const toggleFullscreen = () => {
        dispatch(changeControl(0));
    }
    const collapseFullscreen = () => {
        const controlTabStatus = localStorage.getItem('controlTabStatus') != undefined ? JSON.parse(localStorage.getItem('controlTabStatus')) : true;
        if (!controlTabStatus) {
            dispatch(changeControl(1))
        }

    };
    const handleTeamPage = () => {
        let { id } = props.game;
        props.updateMemorize(props.count);
        localStorage.setItem('memorize', props.count)
        socket.emit('broadcast_stage', { player: null, presentation: 2, id: id })
    }

    const handleGameStatus = () => {
        let { id } = props.game;
        props.updateMemorize(props.count);
        localStorage.setItem('memorize', props.count)
        socket.emit('broadcast_stage', { player: null, presentation: 13, id: id })
    }

    useEffect(() => {
        setTeamMaxSize(props.game?.team_max_size ? props.game.team_max_size : 25)
    }, [props.game])

    switch (varient) {
        case 'normal':
            Footer = <div>
                {props.control != 1 ? <div className="yakeety-footer px-3 py-2 w-100" style={{ transition: '.3s ease-out all', zIndex: '999' }}>
                    <div className="d-flex justify-content-between align-items-center" onMouseLeave={collapseFullscreen}>
                        <div>
                            {props.left}
                        </div>
                        <div className="d-flex align-items-center">
                            {props.centerOne}
                            {props.centerTwo}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="info-section d-flex align-items-center me-3" onClick={handleTeamPage}>
                                <Icons varient='users' />
                                {totalPlayers}/{teamMaxSize * props.teams.length}


                            </div>
                            <div className="info-section d-flex align-items-center" onClick={handleGameStatus}>
                                <Icons varient='flag' />
                                {props.question ? props.question.round_no : 0}/{rounds_count}
                            </div>
                        </div>
                    </div>
                </div> :

                    <div style={{ height: '55px', position: 'fixed', bottom: '-24px', width: '100vw', left: 0, cursor: 'pointer', transition: '.3s ease-in-out all', zIndex: '999' }}
                        onMouseEnter={toggleFullscreen} />

                }
            </div>
            break;
        case 'footerwithleftbtn':
            Footer = <div className="yakeety-footer p-3 w-100">
                <div className="d-flex justify-content-between align-items-center">
                    {props.children}
                </div>
            </div>
            break;
        case 'footercontent':
            Footer = <div className="footer-bg">
                <div className="footer-overlay-img position-relative">
                    <Longfooter />
                    <div className="ho-logo-section-footer-logo position-absolute">
                        <img src={`../../assets/skin/${skin}/logo.png`} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            break;
        case 'footercontentplayer':
            Footer = <div className="footer-bg">
                <div className="footer-overlay-img-player">
                    {/* <img src={Footerimgplayer} alt="" className="ho-img" /> */}
                    <Shortfooter />
                </div>
            </div>
            break;
        case 'footercontentplayerstart':
            Footer = <div className="footer-bg">
                <div className="footer-overlay-img-player p-start">
                    <Shortfooter />
                </div>
            </div>
            break;
        case 'footercontentpresentation':
            Footer = <div className="footer-bg">
                <div className="footer-overlay-img-player for-presentation">
                    <Shortfooter />
                    <div className="position-absolute company-logo">
                        <img src={brand_image ? brand_image : Logogoodcomp} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            break;
        default:
            Footer = <div className="yakeety-footer px-3 py-2 w-100">
                <div className="d-flex justify-content-between align-items-center">
                    <GameButton varient="fbback" />--
                    <GameButton varient="controlbutton" />

                </div>
            </div>
            break;
    }
    return (
        <div>
            {Footer}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        teams: state.team.teams,
        count: state.counter.count,
        game: state.game.game,
        control: state.control.control,
        question: state.question.question,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateMemorize: (data) => dispatch(updateMemorize(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

