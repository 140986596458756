import React, { useEffect, useState, Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./playerinvitation.scss";
import Icons from '../components/Icons/index';
import Logo from "../components/assets/images/player/logo.svg";
import { performRequest } from '../../../../api/apiHandler';
import { loader } from '../../../../redux/Loader/loader.actions';
import { connect } from "react-redux";
// import { QrReader } from 'react-qr-reader';
import { QrReader } from '@blackbox-vision/react-qr-reader';



function Invitation(props) {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [error, setError] = useState(false);
    const [qr, setQr] = useState(false);
    const [reach, setReach] = useState('');

    useEffect(() => {
        props.loader(false);
    }, [])

    const handleSubmit = (event) => {
        setError('')
        event.preventDefault()
        const url = baseurl + event.target.elements.url.value;
        if (url) {
            const formData = new FormData();
            formData.append('url', url);
            performRequest('post', '/api/check-player-url', formData)
                .then(res => {
                    if (res.data.success) {
                        window.location.assign(res.data.data.game_url);
                    } else {
                        setError('Please enter a valid url')
                    }
                });
        } else {
            setError('Field is required')
        }
    }
    const handleRedirect = (event) => {
        setError('')
        const url = event;
        if (url) {
            const formData = new FormData();
            formData.append('url', url);
            performRequest('post', '/api/check-player-url', formData)
                .then(res => {
                    if (res.data.success) {
                        window.location.assign(res.data.data.game_url);
                    } else {
                        setQr(false);
                        setReach('Invalid QR code')

                    }
                });
        } else {
            setQr(false)
        }
    }
    const previewStyle = {
        height: 240,
        width: 320,
    }

    const handleScan = (result) => {
        const formData = new FormData();
        const current = result
        if (result) {
            setReach(1);
            const url = result.text;
            formData.append('url', url);
            performRequest('post', '/api/check-player-url', formData)
                .then(res => {
                    if (res.data.success) {
                        window.location.assign(res.data.data.game_url);
                    } else {
                        setQr(false);
                        setReach('Invalid QR code')
                    }
                });
            setReach(0);
        }

    }
    const handleQR = () => {
        setReach('')
        setQr(true)
    }

    // const handleUrl = (e) => {
    //     if (e.target.value.includes(basurl)) {
    //         setUrl(e.target.value)
    //     }
    // }

    return (
        <div className="user-registration player-invitation flex-column  d-flex justify-content-center align-items-center">
            {qr ?
                <div class="scaner-box">
                    <QrReader
                        // delay={100}
                        // style={previewStyle}
                        // onScan={handleScan}
                        facingMode="environment"
                        constraints={{
                            facingMode: 'environment'
                        }}
                        onResult={(result, error) => {
                            if (result) {

                                handleRedirect(result?.text);
                            } else {
                                // alert('Invalid QR Code')
                            }
                        }}
                        scanDelay='1000'
                        showViewFinder={true}
                    />
                    <div className="d-flex justify-content-center mt-3">
                        <button className="btn btn-primary px-5 mb-3 d-flex justify-content-center align-items-center" onClick={() => setQr(false)}>
                            Back
                        </button>
                    </div>
                </div> :
                <>
                    <div className="player-inv-head mb-5 text-center d-lg-none d-block">
                        <h5>Welcome to</h5>
                        <div className="logo text-center w-100 mb-2">
                            <img src={Logo} alt="" />
                        </div>
                    </div>

                    <div className="signup col-12 col-sm-8 col-md-12">
                        <div className="row m-0">
                            <div className="signup-form col-lg-6 col-xl-6 col-md-12 d-flex justify-content-center align-items-center flex-column">
                                <div className="player-inv-head mb-5 text-center d-lg-block d-none">
                                    <h5>Welcome to</h5>
                                    <div className="logo text-center w-100 mb-2">
                                        <img src={Logo} alt="" />
                                    </div>
                                </div>
                                <form action="post" onSubmit={handleSubmit} method="get" className="register-form w-100 d-flex justify-content-center align-items-center flex-column">
                                    {/* <div className="text-start w-100">
                                        <label htmlFor="chk" aria-hidden="true" className="mb-2 ps-4">Game code</label>
                                        <input type="text" name="url" placeholder="https//yakeety-games.com/..." className="form-control mb-0 px-4" autoComplete="off" onChange={handleUrl} value={url} />
                                        <small style={{ color: 'red' }}>{error}</small>
                                    </div> */}
                                    <div className="text-start w-100">
                                        <label htmlFor="chk" aria-hidden="true" className="mb-2 ps-4">Game code</label>
                                        <div type="text"  className="form-control mb-0 px-4 d-flex align-items-center" autoComplete="off"  >
                                            <label htmlFor="chk" aria-hidden="true" className="">{baseurl}</label>
                                            <input className="custom-input" name="url" placeholder="enter code"/>
                                        </div>
                                        <small style={{ color: 'red' }}>{error}</small>
                                    </div>
                                    <br />
                                    <small style={{ color: 'red' }}>{reach}</small>

                                    <button className="btn btn-primary px-5 mb-3 d-flex justify-content-center align-items-center" >
                                        <Icons varient='exitgame' />Start
                                    </button>
                                </form>
                                <div className="sign-link text-center">
                                    Create your own game at<br />
                                    <div className="mt-3">
                                        <a href="https://yakeety.com" target="_blank" className="mail-req mt-3 d-flex justify-content-center align-items-center"><Icons varient='magic' className="me-3" />Yakeety.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="signup-image col-lg-6 col-xl-6 col-md-12  d-md-block d-none">
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        loader: (data) => dispatch(loader(data))
    }
}

export default connect(null, mapDispatchToProps)(Invitation)

const QrCodeComp = ({ handleRedirect }) => {

    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    return (
        <div >
            <QrReader

                onResult={(result, error) => {
                    if (result) {
                        setData(result);
                        handleRedirect(result);
                    }

                    if (error) {
                        setError(error.message);
                    }
                }}
            />
            <p>The value is: {JSON.stringify(data, null, 2)}</p>
            <p>The error is: {error}</p>
        </div>
    );





}