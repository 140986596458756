import React, { useEffect, useState } from 'react';
import './index.css'
import { connect } from "react-redux"
import {
    increaseCounter,
    decreaseCounter,
    //   } from "./redux/Counter/counter.actions"
} from "../../../redux/Counter/counter.actions"
import WelcomePage from './views/welcome'
import PresentationStartView from './views/presentationstartview';
import PresentationTeamView from './views/presentationteams'

const margin = { marginLeft: '100px' };

function Circuit(props) {
    const skin = props.skin;
    const [colors, setColors] = useState('')
    const [skins, setSkins] = useState('Default');
    let Screen = '';
    let game = props.game.game;
    const gameUrl = game.custom_game_url;
    useEffect(() => {
        let themeSkin = skins != 'Default' ? `/Skins/${skins}/theme.css` : './index.css';
        const themeStyle = document.createElement("link");
        themeStyle.href = themeSkin;
        themeStyle.rel = "stylesheet";
        themeStyle.async = true;
        document.head.appendChild(themeStyle);

        let themeColor = `/Colors/${colors}/index.css`;
        const colorStyle = document.createElement("link");
        colorStyle.href = themeColor;
        colorStyle.rel = "stylesheet";
        colorStyle.async = true;
        document.head.appendChild(colorStyle);
        return () => {
            document.head.removeChild(colorStyle);
            document.head.removeChild(themeStyle);
        }
    }, [skins, colors]);

    switch (props.count) {
        case 1:
            Screen = <WelcomePage url={gameUrl} />
            break;
        case 2:
            Screen = <PresentationStartView />
            break;
        case 3:
            Screen = <PresentationTeamView />
            break;
        case 4:
            Screen = <div>QR code</div>
            break;
        case 5:
            Screen = <div>Intro video</div>
            break;
        case 6:
            Screen = <div>Intro Text</div>
            break;
        case 7:
            Screen = <div>Intro Question</div>
            break;
        case 8:
            Screen = <div>Player answer</div>
            break;
        case 9:
            Screen = <div>Correct answer</div>
            break;
        case 10:
            Screen = <div>Question leaderboard</div>
            break;
        case 11:
            Screen = <div>Podium</div>
            break;
        default:
            Screen = <div className="background">
                <h1 className='title' />
                <div style={margin}>
                    <div className='element' />
                    <div className='element' />
                    <div className='element' />
                    <div className='element' />
                </div>
                <div>
                    Game Skin:
                    <select onChange={e => setSkins(e.target.value)}>
                        <option value="Default">Default</option>
                        <option value="CarRace">Car skin</option>
                        <option value="MotorCycleRace">MotorCycle skin</option>
                    </select>
                </div>
                <div>
                    Game Color:
                    <select onChange={e => setColors(e.target.value)} value={colors}>
                        <option value="Red">Red</option>
                        <option value="Black">Black</option>
                    </select>
                </div>
            </div>
            break;
    }
    return (
        <>
            {Screen}
        </>
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game
    }
}
const mapDispatchToProps = dispatch => {
    return {
        increaseCounter: () => dispatch(increaseCounter()),
        decreaseCounter: () => dispatch(decreaseCounter()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);
