import { LOADER } from '../../constants/actionTypes';
const INITIAL_STATE = {
  isLoader: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADER:
      return {
        ...state, isLoader: action.data,
      };
    default: return state;
  }
};
export default reducer;