import React, { useEffect, useState } from "react";
import moment from 'moment';
import { socket } from '../../../../context/socket';
import { toggleAudio } from "../../../../redux/Audio/audio.actions";
import { connect } from "react-redux"
import { loader } from "../../../../redux/Loader/loader.actions"
import { performRequest } from "../../../../api/apiHandler";


function Timer(props) {
  const { duration, isTimer, timer, setTimer, question_id, resetAnswerd } = props;
  const { id } = JSON.parse(localStorage.getItem('game'));
  var d = moment.duration(timer, 'seconds')


  useEffect(() => {
    let interval = null;
    if (timer <= 0) {
      const formData = new FormData();
      formData.append("question_id", question_id);
      formData.append("game_id", id);
      props.loader(true);
      localStorage.removeItem('timer');
      localStorage.removeItem('answered');
      resetAnswerd([]);
      props.toggleAudio(false);
      performRequest("post", "/api/end-question", formData)
      return socket.emit('broadcast_stage', { player: 6, presentation: 6, id: id });

    }
    if (isTimer) {

      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
        localStorage.setItem('timer', JSON.stringify({
          question_id: question_id,
          timer: timer - 1
        }));
      }, 1000);

    }
    return () => clearInterval(interval);
  }, [timer, duration, isTimer]);

  const calculatePercentage = (minutes, seconds, duration) => {
    return ((((minutes * 60) + seconds) * 100) / duration);
  }
  return (
    <div className="stop-watch">
      <div className="position-relative">
        <div className="mt-2 progress-circle" data-progress={parseInt(calculatePercentage(d.minutes(), d.seconds(), duration))}>
          <div className="progress-circle__mask progress-circle__mask--full">
            <div className="progress-circle__fill"></div>
          </div>
          <div className="progress-circle__mask">
            <div className="progress-circle__fill"></div>
          </div>
        </div>
        <div className="time-data d-flex flex-column align-items-center justify-content-center">
          <div className="time">{('0' + d.minutes()).slice(-2) + ':' + ('0' + d.seconds()).slice(-2)}</div>
          <div className="time-format">min. sec.</div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    audioToggle: state.audioToggle.audioToggle,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    toggleAudio: (data) => dispatch(toggleAudio(data)),
    loader: (data) => dispatch(loader(data)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer);