import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../components/Footer/index";


export default function index(props) {
    return (
        <div className="presentation-controller-main position-relative" style={{color:'white'}}>
            <div className="navbar-section">
                PLAYERS
            </div>
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <h1>Team name1</h1>
                <button>answer</button>
                <h1>Team name2</h1>
                <button>answer2</button><br/>
                <h1>Team name3</h1>
                <button>answer3</button>                
            </div>
            <div className="footer-section">
                <Footer/>
            </div>
        </div>
    );}


