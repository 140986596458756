import React, { useEffect, useState, Component } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { api } from "./apiResponse";
import { performRequest } from "./api/apiHandler";
import { generateUniqeId, checkUrl } from "./service/service";
import { socket } from "./context/socket";
import { addTeam, updateTeam } from "./redux/Teams/team.actions";
import { changePresentationStage } from "./redux/Counter/counter.actions";
import { updateQuestionStore } from "./redux/QuestionStore/questionStore.actions";

import { changePlayerStage } from "./redux/Player/player.actions";
import { addGame } from "./redux/Game/game.actions";
import { loader } from "./redux/Loader/loader.actions";
import Loader from "./games/GameTypeA/Circuit/components/Loader";
import NotFound from "./games/GameTypeA/Circuit/components/NotFound/404";
import GameCompleted from "./games/GameTypeA/Circuit/components/GameCompleted/index";
import { addUser } from "./redux/User/user.actions";

function Game(props) {
  const { params } = useParams();
  const [theme, setTheme] = useState(false);
  const [skin, setSkins] = useState(false);
  const [type, setType] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isGame, setIsGame] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [isCompletd, setCompletedStatus] = useState(false);

  let frontEndFullUrl = window.location.href;
  const url = window.location.href.split("?")[0];

  useEffect(() => {
    socket.on("connect", () => {
      const game = JSON.parse(localStorage.getItem("game"));
      if (game) {
        socket.emit("join", game.id);
      }
    });
  }, []);

  useEffect(() => {
    const formData1 = new FormData();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("id");
    ////////////////////////
    if(frontEndFullUrl.includes('localhost:')){
    const originalUrl = frontEndFullUrl.split("?")[0];
    const regex = /http:\/\/localhost:3000/;
     frontEndFullUrl = originalUrl.replace(regex, 'https://app.yakeety.com');
    }
    /////////////////////////
    formData1.append(
      "url",
      userId ? frontEndFullUrl.split("?")[0] : frontEndFullUrl
    );
    formData1.append("user_id", userId);
    props.loader(true);
    if (
      checkIsInvitation(
        userId ? frontEndFullUrl.split("?")[0] : frontEndFullUrl
      )
    ) {
      performRequest("post", "api/invitation", formData1).then((response) => {
        if (response.data.success) {
          window.location.href = response.data.data;
        } else {
          props.loader(false);
        }
      });
    } else {
      performRequest("post", "api/game-start", formData1)
        .then((response) => {
          if (!response.data.presenter_id) {
            localStorage.setItem("controlTabStatus", true);
            // if no valid presenter
            const data = response.data;
            const {
              hasFirstTime,
              has_team,
              id,
              is_live,
              rounds,
              rounds_count,
              uid,
            } = data;
            let array_list = new Array(rounds.length).fill("");
            localStorage.setItem("winners", JSON.stringify(array_list));
            const gameLocalstorageId = JSON.parse(localStorage.getItem("game"))?.id
            if(id!==gameLocalstorageId){
                localStorage.setItem("stage", 1);
            }


            const currentData = {
              game_id: id,
              round_no: 1,
              question_no: 1,
              rounds_count: rounds_count,
              questions_count: 1,
            };
            if (checkUrl(url, data) === "player") {
              const isProfileExist = localStorage.getItem("profile")
                ? JSON.parse(localStorage.getItem("profile"))
                : null;
       
              if (parseInt(isProfileExist?.game_id) !== parseInt(data.id)) {
                localStorage.removeItem("profile");
              }

              if (localStorage.getItem("game")) {
                let localStoragePlayer = JSON.parse(
                  localStorage.getItem("game")
                );
                if (
                  localStoragePlayer.id !== data.id ||
                  localStoragePlayer.uid !== uid
                ) {
                  localStorage.removeItem("playerSelected");
                  localStorage.removeItem("playerQuestion");
                }
              }
              localStorage.setItem("game", JSON.stringify(data));
            } else {
              localStorage.setItem("current", JSON.stringify(currentData));
            }
            props.addTeam(data.teams);
            setIsGame(checkUrl(url, data));
            props.addGame(data);
            if (localStorage.getItem("game")) {
              let localStorageGame = JSON.parse(localStorage.getItem("game"));

              if (
                localStorageGame.id != data.id 
                // ||
                // localStorageGame.uid != uid
              ) {
                localStorage.setItem(
                  "rounds",
                  JSON.stringify({ round_pos: 0, question_pos: 0 })
                );
                props.updateQuestionStore({ round_pos: 0, question_pos: 0 });
                localStorage.removeItem("round_list");
                localStorage.removeItem("questionScores");
                localStorage.removeItem("playerSelected");
                localStorage.removeItem("playerQuestion");
                localStorage.removeItem("positions");
                localStorage.removeItem("pauseTimes");

                props.changePresentationStage(1);
                localStorage.setItem("stage", 1);
                localStorage.removeItem("timer");
                localStorage.removeItem("answered");
                localStorage.setItem("game", JSON.stringify(data));
              }
            } else {
              localStorage.setItem("game", JSON.stringify(data));
            }
            if (checkUrl(url, data) == "presentation") {
              const formData = new FormData();
              formData.append("uid", generateUniqeId());
              formData.append("game_id", data.id);
              props.loader(false);
              performRequest("post", "api/presenter-login", formData).then(
                (res) => {
                  if (!res.data) {
                  }
                }
              );
            } else {
              // check the game is selfpaced
              if (is_live) {
                if (localStorage.getItem("profile")) {
                  const { name, team } = JSON.parse(
                    localStorage.getItem("profile")
                  );
                  const api_token = localStorage.getItem("accessToken");
                  const formData = new FormData();

                  if (data?.player) {
                    const profileData = {
                      id: data?.player?.id,
                      name: data?.player?.public_name,
                      game_id: data?.player?.game_id,
                      team: data?.team?.id,
                      color: "#fff",
                    };
                    localStorage.setItem(
                      "profile",
                      JSON.stringify(profileData)
                    );
                    formData.append("name", data?.player?.public_name);
                    formData.append("team", data?.team?.id);
                    formData.append("game", data.id);
                  } else {
                    formData.append("name", name);
                    formData.append("team", team);
                    formData.append("game", data.id);
                    formData.append("api_token", api_token);
                  }

                  performRequest("post", "/api/player-login", formData).then(
                    (res) => {
                      props.loader(false);
                      props.addTeam(data.teams);
                      props.addGame(data);
                      if (res.data.success) {
                        const playerStage = localStorage.getItem("playerStage");
                        // check if playerstage_data exist in local storage
                        if (playerStage)
                          props.changePlayerStage(parseInt(playerStage));
                        else props.changePlayerStage(11);
                      } else {
                        props.changePlayerStage(2);
                      }
                    }
                  );

                  const gameTeams = JSON.parse(
                    localStorage.getItem("gameTeams")
                  );
                  if (gameTeams) {
                    const getTeam = gameTeams.find((e) => e.id == team);
                    if (getTeam) {
                      let localStoragePlayer = JSON.parse(
                        localStorage.getItem("game")
                      );

                      localStoragePlayer.player_color = getTeam.color.replace(
                        "#",
                        ""
                      );
                      localStorage.setItem(
                        "game",
                        JSON.stringify(localStoragePlayer)
                      );
                      data["player_color"] = getTeam.color;
                      props.addGame(data);
                    }
                  }
                } else {
                  props.changePlayerStage(2);
                  props.loader(false);

                  if (userId) {
                    const formData = new FormData();
                    formData.append("user_id", userId);
                    formData.append("id", data.id);
                    performRequest("post", "/api/get-player", formData).then(
                      (res) => {
                        const getData = res?.data?.data;
                        const {
                          id: profileId,
                          public_name: name,
                          game_id: gameId,
                          team_id: team,
                        } = getData;

                        const profileData = {
                          id: profileId,
                          name,
                          game_id: gameId,
                          team,
                          color: "#fff",
                        };
                        props.addUser({
                          id: profileId,
                          name: name,
                          team: team,
                        });
                        
                        localStorage.setItem(
                          "profile",
                          JSON.stringify(profileData)
                        );
                        if (getData?.team_id && getData?.public_name) {
                          props.changePlayerStage(11);
                        }
                        // props.loader(false);
                      }
                    );
                  }
                  // props.loader(false);
                }
              } else {
                if (has_team) {
                  const profileData = {
                    id: data?.player?.id,
                    name: data?.player?.public_name ?? data?.player?.email,
                    game_id: data?.player?.game_id,
                    team: data?.team?.id,
                    color: "#fff",
                  };
                  localStorage.setItem("profile", JSON.stringify(profileData));
                  if (hasFirstTime) {
                    props.changePlayerStage(data?.stage ?? 5);
                  } else {
                    // props.changePlayerStage(11);
                    props.changePlayerStage(data?.stage ?? 11);
                  }
                } else {
                  if (hasFirstTime) {
                    props.changePlayerStage(5);
                  } else {
                    props.changePlayerStage(3);
                  }
                }

                props.loader(false);
              }
              // } else {
              //     props.changePlayerStage(14);
              //     props.loader(false);
              // }

              // })
            }
            socket.on("player_screen", (arg) => {
              props.changePlayerStage(arg);
            });
            socket.on("presenter_screen", function (arg) {
              props.changePresentationStage(arg);
            });
            socket.on("update_team", (arg) => {
              props.updateTeam(arg.team_id, arg.players);
            });
            socket.emit("join", data.id);
          } else {
            props.loader(false);
            setCompletedStatus(true);
          }
        })
        .catch((e) => {
          props.loader(false);
          setNotFound(true);
        });

      // API RESPONSE TO GET THEME_NAME AND SKIN_NAME
      processUrl(params);
      // const apiResponse = processUrl(params)[0];
      const isGame = processUrl(params)[1];
      if (1) {
        setTheme("Circuit");
        setSkins("CarRace");
        setType("GameTypeA");
        // setTheme(apiResponse[0].theme);
        // setSkins(apiResponse[0].skin);
        // setType(apiResponse[0].game_type)
        setLoader(true);
      } else {
        setLoader(false);
      }
    }
  }, []);

  return (
    <div>
      <div>
        {props.isLoader.isLoader && <Loader />}
        {isCompletd ? <GameCompleted /> : ""}
        {!notFound ? (
          loader ? (
            <DynamicContent
              type={type}
              theme={theme}
              skin={skin}
              isGame={isGame}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
                top: "30%",
                margin: "auto",
              }}
            >
              <h1>Invalid URL</h1>
            </div>
          )
        ) : (
          <NotFound />
        )}
        {/* {!props.isLoader.isLoader && <DynamicContent type={type} theme={theme} skin={skin} isGame={isGame} /> } */}
      </div>
    </div>
  );
}
const DynamicContent = ({ type, theme, skin, isGame }) => {
  const Theme = require(`./games/${type}/${theme}`).default;
  return <Theme skin={skin} isGame={isGame} />;
};
function processUrl(params) {
  let urlType = params.split("_");
  let urlTypeParam = urlType[0];
  let token = urlType[1];
  const apiResponse = api.data.filter((e) => e.id == token);
  return [apiResponse, urlTypeParam];
}

const mapStateToProps = (state) => {
  return {
    count: state.counter.count,
    isLoader: state.isLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePresentationStage: (data) => dispatch(changePresentationStage(data)),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addTeam: (data) => dispatch(addTeam(data)),
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    addGame: (data) => dispatch(addGame(data)),
    loader: (data) => dispatch(loader(data)),
    updateQuestionStore: (data) => dispatch(updateQuestionStore(data)),
    addUser: (data) => dispatch(addUser(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Game);

function checkIsInvitation(url) {
  if (url.includes("invite")) return true;
  else return false;
}
