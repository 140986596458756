import { PLAYERSTAGE } from './player.types';
const INITIAL_STATE = {
  playerScreen: 2,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PLAYERSTAGE:
      return {
        ...state, playerScreen: action.data??localStorage.getItem("playerStage")
      };
    default: return state;
  }
};

export default reducer;