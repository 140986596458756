export const ADDQUESTIONSCORE = 'ADDQUESTIONSCORE';

export const ADDSELECTEDANSWER = 'ADDQUESTIONSCORE';

export const AUDIOTOGGLE = 'AUDIOTOGGLE';

export const OPTIONTOGGLE = 'OPTIONTOGGLE';

export const MEMORIZE = 'MEMORIZE';

export const ADDGAME = 'ADDGAME';

export const LOADER = 'LOADER';

export const CHANGECONTROL = 'CHANGECONTROL';

export const SELECTION = 'SELECTION';

export const ADDUSER = 'ADDUSER';

export const UPDATEQUESTIONSTORE = 'UPDATEQUESTIONSTORE';

export const ROUNDANIMATION = 'ROUNDANIMATION';

export const PODIUMANIMATION = 'PODIUMANIMATION';

export const CHANGEANIMATIONSTATUS = 'CHANGEANIMATIONSTATUS';

export const FULLSCREENTOGGLE='FULLSCREENTOGGLE';

export const QRCODETOGGLE='QRCODETOGGLE';

export const GET_USER_DATA_WITH_AUTH_SUCCESS='GET_USER_DATA_WITH_AUTH_SUCCESS';
