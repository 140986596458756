import React, { useContext, useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../components/Footer/index";
import { SocketContext } from '../../../../context/socket';
import { performRequest } from "../../../../api/apiHandler";
import { connect } from "react-redux";
import { updateTeam, addTeam } from "../../../../redux/Teams/team.actions"


function AllComponent(props) {
    const socket = useContext(SocketContext);
    const [name, setName] = useState('');
    const [team, setTeam] = useState('');
    const gameData = JSON.parse(localStorage.getItem('game'));
    const game_teams = gameData.teams;
    const translations= gameData?.translations;

    const handleSubmit = () => {

        if (name) {
            const game_id = gameData.id;
            const formData = new FormData();
            formData.append('name', name);
            formData.append('game_id', game_id);
            formData.append('team_id', team);
            formData.append('created_by', team);

            performRequest('post', 'api/add-player', formData, false)
                .then(response => {
                    const getData = response.data;
                    const team_members = getData.team_members;
                    localStorage.setItem('accessToken', getData.api_token);

                    const data = {
                        id: getData.data.id,
                        name: name,
                        team: team,
                        game_id: getData.data?.game_id,
                        color:getData.data.color
                    }
                    localStorage.setItem("profile", JSON.stringify(data))
                    // socket.emit("message", data);
                    socket.emit("add_team",
                        {
                            team_id: team,
                            game_id: getData.data?.game_id,
                            players: getData.data
                        })

                })
                .catch(error => {
                    alert('failed');
                })


        }
    }
    return (
        <div className="presentation-controller-main position-relative">
            <div className="navbar-section">
                {translations?.players}
            </div>
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <input placeholder="enter player name" value={name} onChange={(e) => setName(e.target.value)} />
                <input placeholder="select game id" value={team} onChange={(e) => setTeam(e.target.value)} />
                {
                    game_teams.map(function (e) {
                        return <p style={{ color: "white" }}>{e.id}<br /></p>;
                    })
                }
                <button onClick={handleSubmit}>{translations?.submit}</button>
            </div>
            <div className="footer-section">
                <Footer />
            </div>
        </div>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        updateTeam: (id, data) => dispatch(updateTeam(id, data)),
        addTeam: (data) => dispatch(addTeam(data)),

    }
}

export default connect(null, mapDispatchToProps)(AllComponent);