import React, { useEffect, useState, Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./playerinvitation.scss";
import Icons from '../components/Icons/index';
import Logo from "../components/assets/images/player/logo.svg";
const translations = JSON.parse(localStorage.getItem("game"))?.translations;


export default function AllComponent(props) {
  return (
    <div className="user-registration player-invitation flex-column  d-flex justify-content-center align-items-center">
        <div className="player-inv-head mb-5 text-center d-lg-none d-block">
            <h5>{translations?.welcome_to}</h5>
            <div className="logo text-center w-100 mb-2">
                <img src={Logo} alt="" />
            </div>
        </div>
        <div className="signup col-12">
            <div className="row m-0">
                <div className="signup-form col-lg-6 col-xl-6 col-md-12 d-flex justify-content-center align-items-center flex-column">
                    <div className="player-inv-head mb-5 text-center d-lg-block d-none">
                        <h5>{translations?.welcome_to}</h5>
                        <div className="logo text-center w-100 mb-2">
                            <img src={Logo} alt=""/>
                        </div>
                    </div>

                    <form action="" method="POST" className="register-form w-100 d-flex justify-content-center align-items-center flex-column">
                        <div className="text-start w-100">
                            <label for="chk" aria-hidden="true" className="mb-2 ps-4">{translations?.game_code}</label>
                            <input type="text" id="emailInput" name="email" placeholder="https//yakeety-games.com/..." className="form-control mb-0 px-4" onkeyup="emailValidation()" autocomplete="off"/>
                        </div>
                        {/* <button className="btn btn-secondary mt-4 px-5 d-flex justify-content-center align-items-center">
                            <Icons varient='qrcode'/>Scan QR code
                        </button> */}
                        <br/>
                        <button className="btn btn-primary px-5 mb-3 d-flex justify-content-center align-items-center">
                            <Icons varient='exitgame'/>Start
                        </button>
                    </form>
                    <div className="sign-link text-center">
                        Create your own game at<br/>
                        <div className="mt-3">
                            <a className="mail-req mt-3 d-flex justify-content-center align-items-center"><Icons varient='magic' className="me-3"/>Yakeety.com</a>
                        </div>
                    </div>
                </div>
                <div className="signup-image col-lg-6 col-xl-6 col-md-12  d-md-block d-none">
                </div>
            </div>
        </div>
    </div>
  );
}