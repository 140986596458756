import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import { addQuestion } from "../../../../redux/Questions/question.actions";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import { options } from "../../../../constant";
import { performRequest } from "../../../../api/apiHandler";
import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import TeamDetails from "../components/TeamDetails";
import "./playeranswer.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function PlayerCorrectAnswer(props) {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const { team, id, is_live, has_team,translations } = JSON.parse(
    localStorage.getItem("game")
  );
  const [answers, setAnswers] = useState([]);
  const [question, setQuestion] = useState(null);
  const [answerList, setAnswerList] = useState([]);
  const playerQuestion = JSON.parse(localStorage.getItem("playerQuestion"));
  const { gameQuestion } = useSelector((state) => state?.question);
  const optionStyle = ["first", "second", "third", "fourth", "first", "second"];
  const [totalQuestion, setTotalQuestion] = useState(1);
  let windowHeight = window.innerHeight;
  let questionHeight = document.getElementById("pq-head")?.clientHeight;
  var scrollHeight = windowHeight - (questionHeight ? questionHeight : 0);
  const handleNext = () => {
    if (gameQuestion?.question?.isRoundEnd) {
      props.changePlayerStage(14);
    } else {
      props.changePlayerStage(5);
    }
  };

  const { color } = JSON.parse(
    localStorage.getItem("profile") ?? '{"color": "transparent"}'
  );

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${r},${g},${b}`;
  };

  const hex2rgbaAlt = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${Math.round(r * 0.72)},${Math.round(g * 0.75)},${Math.round(
      b * 0.85
    )}`;
  };

  const handleExit = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "game/" + id);
  };

  useEffect(() => {
    localStorage.setItem("playerStage", 7);
    const getQuestion = props.question?.question
      ? props.question.question
      : playerQuestion?.question;
    setQuestion(getQuestion);
    if (!question && !playerQuestion) {
      props.changePlayerStage(11);
    } else {
      props.loader(true);
      const formData = new FormData();
      formData.append("question_id", getQuestion.id);
      formData.append("team_id", has_team ? profile?.team : team?.id);
      performRequest("post", "api/get-team-answers", formData).then(
        (response) => {
          props.loader(false);
          const answersByTeam = response.data.data;
          const answerList = answersByTeam.map((e) => e.id);
          setAnswerList(answerList);
          setAnswers(answersByTeam);
          let totalQuestions = 0;
          answersByTeam.map((eachAnswer) => {
            totalQuestions += eachAnswer.team_answer.length;
          });
          setTotalQuestion(totalQuestions);
        }
      );
    }
  }, []);
  return (
    <div className="player-questions player-answers">
      <Navbar varient="contentheadershortplayer" />
      <div className="main-content">
        <div className="pq-whole-section pb-md-3 pb-0">
          <div
            className="pq-head text-center no-progress"
            id="pq-head"
            style={{ "--team-color": "#" + profile?.color }}
          >
            <h2 className="qstn">
              <span
                dangerouslySetInnerHTML={{
                  __html: question ? question.description : "?",
                }}
              ></span>
            </h2>
          </div>
          <div
            className="answer-option-section validate-answer col-12 qstn-scroll-sec"
            style={{
              marginTop: questionHeight + 30,
              maxHeight: scrollHeight - 130,
            }}
          >

            <div className="col-12 my-2">
              {props.selectedAnswer?.length ? (
                <div
                  className={`selected-answer ${
                    props.selectedAnswer.length
                      ? props.selectedAnswer[0].is_correct
                        ? `right-a`
                        : `wrong-a`
                      : ``
                  } `}
                >
                  {is_live ? (
                    ""
                  ) : props?.selectedAnswer[0]?.is_correct ? (
                    <h6 className="ps-3 mb-3">
                      {translations?.congratulations}
                    </h6>
                  ) : (
                    <h6 className="ps-3 mb-3">{translations?.answer_not_correct}</h6>
                  )}
                  <div className="text-start">
                    <h6 className="ps-3 mb-3">{translations?.your_answer}</h6>
                  </div>
                  {props.selectedAnswer
                    ? props.selectedAnswer.map((answer, index) => {
                        let correctIndex = answerList.indexOf(answer.id);

                        return (
                          <div
                            key={`your-answer-${answer.id}`}
                            className={`${optionStyle[correctIndex]} answer-option mb-4`}
                          >
                            <div className="col-12">
                              <div className="row mx-0">
                                <div className="col-2 p-0">
                                  <div className="option-count d-flex align-items-center justify-content-center w-100 h-100 font-racing">
                                    {
                                      options[
                                        correctIndex != -1 ? correctIndex : 0
                                      ]
                                    }
                                  </div>
                                </div>
                                <div className="col-10 p-0">
                                  <div
                                    className="option-detail text-start pa-option-detail correct-ans"
                                    dangerouslySetInnerHTML={{
                                      __html: answer.name,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              ) : (
                // <div className="no-answer-select mb-4 text-start">
                  <h4 className="ps-3">
                    {translations?.no_answer_on_time}
                  </h4>
                // </div>
              )}
              <div className="whole-teams-answer text-start">
                {is_live ? (
                  <h6 className="ps-3">{translations?.teams_answers}</h6>
                ) : has_team ? (
                  <h6 className="ps-3">{translations?.teams_answers}</h6>
                ) : (
                  <h6 className="ps-3">{translations?.your_answers}</h6>
                )}
                {answers.map((answer) => {
                  let percentage = answer.team_answer.length
                    ? (answer.team_answer.length / totalQuestion) * 100
                    : 0;
                  return (
                    <div
                      key={`team-answer-${answer.id}`}
                      className={
                        answer.is_correct
                          ? "first-answer my-2  right-a"
                          : "first-answer my-2  wrong-a"
                      }
                    >
                      <div
                        className="select-status my-3"
                        style={{
                          width:
                            (answer.team_answer.length / totalQuestion) * 100 +
                            "%",
                        }}
                      ></div>
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <div className="">
                          <div
                            className="answer-options text-start space-wrap"
                            dangerouslySetInnerHTML={{ __html: answer.name }}
                          ></div>
                        </div>
                        <div className="">
                          <div className="answer-status-percentage text-end">
                            {parseFloat(percentage).toFixed(2)}%
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!is_live ? (
                <div className="btn-group-player d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4 flex-column flex-sm-row">
                  <button
                    class="btn btn-primary dynamic-btn px-5 d-flex justify-content-center align-items-center"
                    onClick={handleNext}
                    style={{
                      backgroundImage: `linear-gradient(225deg, rgb(${hex2rgba(
                        color
                      )}) 0%, rgb(${hex2rgbaAlt(color)}) 100%)`,
                      "--element-color": hex2rgba(color),
                    }}
                  >
                    <i class="fa-solid fa-play me-2"></i>{translations?.continue}
                  </button>
                  <button
                    class="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center text-white"
                    onClick={handleExit}
                    style={{ borderColor: `rgb(${hex2rgba(color)})` }}
                  >
                    <i class="fa-solid fa-right-from-bracket me-2"></i>{translations?.exit_game}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    question: state.question.question,
    selectedAnswer: state.selectedAnswer.selectedAnswer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addQuestion: (data) => dispatch(addQuestion(data)),
    loader: (data) => dispatch(loader(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerCorrectAnswer);
