import { ADDGAME } from '../../constants/actionTypes';

export const addGame = (data) => {
    return {
        type: ADDGAME,
        data: data
    };
};


