import { PODIUMANIMATION } from '../../constants/actionTypes';

export const podiumAnimationStatus = (data) => {
    return {
        type: PODIUMANIMATION,
        data: data
    };
};


