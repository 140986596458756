import { ROUNDANIMATION } from '../../constants/actionTypes';

export const roundAnumationStatus = (data) => {
    return {
        type: ROUNDANIMATION,
        data: data
    };
};


