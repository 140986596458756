export const api = {  
    data:[
        {
            // "id": '6267bc3e60044-1650965566',//game id 
            "id": '627c12fed745d-1652298494',//game id 
            
            "game_type": "GameTypeA",
            "theme": "Circuit",
            "skin": "CarRace",
        },
        {
            "id": 2,
            "game_type": "GameTypeA",
            "theme": "Circuit",
            "skin": "MotorcycleRace",
        }    
    ]
  };
  