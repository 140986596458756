import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../components/Footer/index";
import { performRequest } from "../../../../api/apiHandler";
import { connect } from "react-redux";
import { changePlayerStage } from '../../../../redux/Player/player.actions';
import { socket } from '../../../../context/socket'
import { addQuestion } from "../../../../redux/Questions/question.actions"


function AllComponent(props) {
    
    const question = props.question.question;
    const game = JSON.parse(localStorage.getItem('game'));
    const translations= game?.translations
    const handleAnswer = (e) => {
        const playerId = JSON.parse(localStorage.getItem('profile')).id;
        
        const gameId = game.id;
        let answer = [];
        answer.push(e);
        const formData = new FormData();
        formData.append('game_id', gameId);
        formData.append('question_id', question.id);
        formData.append('player_id', playerId);
        formData.append('answer_ids', JSON.stringify(answer));
        formData.append('response_speed', 2);

        performRequest('post', 'api/save-question-answer', formData)
            .then(response => {
                // socket.emit('broadcast_stage', { player: 5, presentation: 7 })
            })
            .catch(error => {
            })
    }

    return (
        <>
            {question ?
                <div className="presentation-controller-main position-relative" style={{ color: 'white' }}>
                    <div className="navbar-section">
                        {translations?.players}
                    </div>
                    <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                        <h1>{question.description}?</h1>
                        {question['get_answers_by_question_id'] ? question['get_answers_by_question_id'].map(function (e) {
                            return <button onClick={() => handleAnswer(e.id)}>{e.name}</button>;
                        }) : ''}
                    </div>
                    <div className="footer-section">
                        <Footer />
                    </div>
                </div> : <h1 style={{ color: "white" }}>{translations?.no_questions}</h1>}
        </>
    );
}

const mapStateToProps = state => {
    return {
        question: state.question.question,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changePlayerStage: (data) => dispatch(changePlayerStage(data)),
        addQuestion: (data) => dispatch(addQuestion(data))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllComponent);


