import React, { useEffect, useState, Component } from "react";
import { connect, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";

import Footer from "../components/Footer/index";
import Icons from "../components/Icons/index";
import Navbar from "../components/Header/index";
import { performRequest } from "../../../../api/apiHandler";
import "./playerleaderboard.scss";
import TeamDetails from "../components/TeamDetails";
import { changePlayerStage } from '../../../../redux/Player/player.actions';
import { loader } from "../../../../redux/Loader/loader.actions";
const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `${r},${g},${b}`;
};

function PlayerQuestionLeaderBoard(props) {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const gameData = JSON.parse(localStorage.getItem('game'));
  const [scoreBoard, setScoreBoard] = useState([]);
  const [displayDetails, setDisplayDetails] = useState(null);
  const { gameQuestion } = useSelector((state) => state?.question);
  const translations=gameData?.translations;
  const playerQuestion = JSON.parse(localStorage.getItem('playerQuestion'));
  const question = props.question ? props.question : playerQuestion;

  useEffect(() => {
    localStorage.setItem('playerStage', 8);
    if (question) {
      const formData = new FormData();
      formData.append('round_id', gameQuestion?.question?.round_id);
      formData.append('question_id', gameQuestion?.question?.id);
      formData.append('game_id', gameData.id);
      props.loader(true);
      performRequest('post', 'api/question-score-details', formData)
        .then(response => {
          props.loader(false);
          setScoreBoard(response.data.data);
          setDisplayDetails(response.data.displayDetails);
        })
        .catch(error => {
          alert('something went wrong!');
        })
    } else {
      props.changePlayerStage(11)

    }
  }, []);
  return (
    <div className="presentation-start-view position-relative player-questions only-Lboard">
      <Navbar varient="contentheadershortplayer" />
      <div className="main-content player-top-align pb-0 d-flex justify-content-center align-items-center flex-column leader-board-custom-align">
        <div className="lite-bg astro-lb">
        <div className="col-xl-6 col-lg-9 col-md-10 col-sm-11 col-11 d-flex align-items-center justify-content-between astro-head">
          <h2 className="mb-4" style={{'--team-color': '#'+profile.color}}><span>{translations?.round} {displayDetails?.round_order_no}</span></h2>
          <h2 className="mb-4" style={{'--team-color': '#'+profile.color}}><span>{translations?.question} {displayDetails?.question_order_no}</span></h2>
        </div>
        <div className="leader-board-section col-xl-6 col-lg-9 col-md-10 col-sm-11 col-11 custom-padding-for-ltable mb-5 m-0">
          <div className="col-12 leader-board-table-header">
            <div className="row m-0">
              <div className="col-3 col-sm-4">
                <div className="drush-head d-flex align-items-center justify-content-between w-100">
                  <h2 className="mb-2" style={{'--team-color': '#'+profile.color}}><span>{translations?.round} {displayDetails?.round_order_no}</span></h2>
                  <h2 className="mb-2" style={{'--team-color': '#'+profile.color}}><span>{translations?.question} {displayDetails?.question_order_no}</span></h2>
                </div>
              </div>
              <div className="col-9 col-sm-8 pe-0">
                <div className="d-flex align-items-center h-100 player-table-column tbl-head">

                  <p className="mx-2 mb-0">{translations?.correct_answers}</p>
                  <p className="mx-2 mb-0">{translations?.fast_correct_answer}</p>
                  <p className="mx-2 mb-0">{translations?.points}</p>
                  <p className="mx-2 mb-0">{translations?.round_points}</p>
                </div>
              </div>
            </div>
          </div>
      
          <ul className="leaderboard-ul-list p-0 m-0 qst-rnd-lb">
            {scoreBoard ? scoreBoard.map(function (eachTeam, index) {
              let currentScore = eachTeam.questions != null ? eachTeam.questions.length ? eachTeam.questions[0] : null : null;
              let someColor = eachTeam.color;
              return <li id={`team` + (index + 1)} key={eachTeam.id} className="team leader-board-table-row blue round-qstn" style={{ background: `none` }}>
                  <div className="col-3 col-sm-4 d-flex align-items-center first-list-sec">
                    <span className="list-item item1"
                        style={{ '--element-color-rgb': hex2rgba(eachTeam.color), '--element-color': someColor }}>
                        <div className="d-flex align-items-center">
                            <div className="tr-count d-flex justify-content-center align-items-center me-2"
                                style={{ background: eachTeam.color }}>
                                {index + 1}
                            </div>
                        </div>
                    </span>
                    <span className="list-item item2 w-100 ps-1 ps-sm-3"
                        style={{ '--element-color-rgb': hex2rgba(eachTeam.color), '--element-color': someColor }}>
                        <div className="d-flex align-items-center">
                            <div className="teb-icon"><Icons varient={eachTeam.icon_class}/></div>
                            <p className="mb-0 ms-2 player-name-long d-none d-sm-block long-name-v2">{eachTeam.name}</p>
                        </div>
                    </span>
                  </div>
                  <div className="col-9 col-sm-8">
                      <div className="d-flex align-items-center justify-content-between player-table-column">
                          <span className="list-item item3"
                              style={{ '--element-color-rgb': hex2rgba(eachTeam.color), '--element-color': someColor }}>
                              <p className="mx-2 mb-0">
                                  {currentScore?.correct_answers}
                              </p>
                          </span>
                          <span className="list-item item4"
                              style={{ '--element-color-rgb': hex2rgba(eachTeam.color), '--element-color': someColor }}>
                              <p className="mx-2 mb-0">
                                  {currentScore ? currentScore.response_speed ? currentScore.response_speed + ' Sec' : '/' : ''}
                                  </p>
                          </span>
                          <span className="list-item item5"
                              style={{ '--element-color-rgb': hex2rgba(eachTeam.color), '--element-color': someColor }}>
                              <p className="mx-2 mb-0">
                                  {currentScore?.score}
                                  </p>
                          </span>
                          <span className="list-item item6 pe-3"
                              style={{ '--element-color-rgb': hex2rgba(eachTeam.color), borderTopRightRadius: '30px', borderBottomRightRadius: '30px', '--element-color': someColor }}>
                              <p className="mb-0 total-font">
                                  {eachTeam?.total_score}
                              </p>
                          </span>
                      </div>
                  </div>
              </li>

          }) : ''}
          </ul>


          {scoreBoard.map(function (score, index) {
            let roundTotal = 0;
            
            let currentScore = score.questions != null ? score.questions.length ? score.questions[0] : null : null;
            // return <div key={score.id} className="leader-board-table-row w-100 blue mb-2"
            //   style={{ background: `linear-gradient(90deg, ${score.color + 80} 0.65%, ${score.color} 3.9%, ${score.color + 40} 50.64%)` }}>

            //   <div className="col-12 xtra-border">
            //     <div className="row m-0">
            //       <div className="col-3 ps-1">
            //         <div className="d-flex align-items-center">
            //           <div className="tr-count d-flex justify-content-center align-items-center me-2"
            //             style={{ background: score.color }}
            //           >
            //             {index + 1}
            //           </div>

            //           <Icons varient={score.icon_class} />
            //           <p className="mb-0 ms-2 d-md-block d-none group-name-leader">{score.name}</p>
            //         </div>
            //       </div>
            //       <div className="col-9">
            //         <div className="d-flex align-items-center player-table-column">
            //           <div className="mx-2 round-table text-center">

            //             <p className="mb-0">{currentScore ? currentScore.correct_answers : '-'}</p>
            //           </div>

            //           <div className="mx-2 round-table text-center">

            //             <p className="mb-0">{currentScore ? currentScore.response_speed ? currentScore.response_speed + ' Sec' : '/' : '-'} </p>
            //           </div>

            //           {/* {score.questions.map((question, index) => {
            //             return <p key={question.id} className="mx-4 mb-0">{question.score}</p>;
            //           })} */}
            //           <div className="mx-2 round-table text-center">
            //             <p className="mb-0">{currentScore ? currentScore.score : '-'}</p>


            //           </div>
            //           <div className="mx-2 total-table text-center">
            //             <p className="mb-0">{score.total_score}</p>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
          })}

        </div>
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />
      <div className="footer-section for-player-screen">
        <Footer varient="footercontent" />
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    question: state.question.question,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    loader: (data) => dispatch(loader(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerQuestionLeaderBoard);