import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { graph } from '../../../../constant';
import { socket } from '../../../../context/socket'
import { toggleOption } from "../../../../redux/Option/option.actions"
import { changeAnimationStatus } from "../../../../redux//Animations/animation.actions"

import Footer from "../components/Footer/index";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import Navbar from "../components/Header/index";

import "./showplayeranswer.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

function Showcorrectanswer(props) {
    const { id ,translations} = JSON.parse(localStorage.getItem('game'));
    const [animate, setAnimation] = useState(true);
    const questionScore = props.questionScore ? props.questionScore : JSON.parse(localStorage.getItem('questionScores'));
    const question = props.question ? props.question.question : JSON.parse(localStorage.getItem('question'));
    const [isReplay, setReplay] = useState(true);
    const [animationState, setAnimationState] = useState(true);
    const [isReset, setReset] = useState(true);
    const handleSkipToQuestion = () => socket.emit('broadcast_stage', { player: 8, presentation: 8, id: id });
    let timeOut = 0;
    const handleReplay = () => {
        setAnimation(true)
        props.changeAnimationStatus(0);
        setReplay(true);
        setReset(false)
        if (timeOut) {
            clearTimeout(timeOut);
            timeOut = 0;
        }
        timeOut = setTimeout(() => {
            setReset(true)
        }, 500)
    }

    const handleAnimation = () => {
        props.changeAnimationStatus(animationState ? 1 : 0);
        setAnimationState(!animationState)
    }

    useEffect(() => {
        const el = document.getElementById('animated');
        if (el)
            el.addEventListener("animationend", function () {
                setReplay(false);
                props.changeAnimationStatus(2);
                setAnimation(false);
            }, false);
    }, [props.questionScore, isReset])

    useEffect(() => {
        props.changeAnimationStatus(0);

        socket.on('get_correctanswer_animation', (arg) => {
            // props.changeAnimationStatus(0);
            setAnimationState(arg);
        });
        const keyDownHandler = event => {
            if (event.key == 'ArrowRight') {
                handleSkipToQuestion();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [])

    useEffect(() => {
        if (!animate) {
            handleReplay();
        }
    }, [props.animationStatus])

    return (
        <div className="presentation-question position-relative dh-full">
            <div className="navbar-section">
                <Navbar varient="normal" />
            </div>
            <Navbar varient="contentheadershort" />
            <div className="main-content">
                <div className="pq-whole-section">
                    <div className="pq-head text-center pt-4" dangerouslySetInnerHTML={{ __html: question ? question.description : '' }}>
                    </div>
                    <div className="graph-section">
                        <div className="d-flex justify-content-center align-items-start gap-4">
                            {
                                (questionScore && isReset) && questionScore.options.map(function (answer, index) {
                                    let percentage = answer.choosen.length ? (answer.choosen.length / questionScore.total_players) * 100 : 0;
                                    return <div key={index} className={graph[index]}>
                                        <div className="graph-bar-section d-flex flex-column justify-content-end align-items-center mb-2" >
                                            <div className={answer.is_correct ? 'ps-trans-background answer-correct-percentage' : 'small-perc ps-trans-background'} style={{
                                                animationPlayState: `${props.animationStatus ? 'paused' : 'running'}`,
                                            }}>
                                                {parseFloat(percentage).toFixed(2)}%
                                            </div>
                                            <div className={answer.is_correct ? 'graph-bar correct-ans custom-width-correct-graphbar' : 'graph-bar custom-width-wrong-graphbar'} id="animated"
                                                style={{
                                                    minHeight: '0%',
                                                    maxHeight: answer.choosen.length ? ((answer.choosen.length / questionScore.total_players) * 100) + '%' : '0%',
                                                    height: answer.choosen.length ? ((answer.choosen.length / questionScore.total_players) * 100) + '%' : '0%',
                                                    animation: answer.is_correct ? `graph-animation-2 3s ease 1` : `graph-animation-3 3s ease 1`,
                                                    animationFillMode: 'forwards',
                                                    animationPlayState: `${props.animationStatus ? 'paused' : 'running'}`,
                                                    opacity: !answer.is_correct && '.5',
                                                }}
                                            ></div>
                                        </div>
                                        <div className={answer.is_correct ? 'answer-section answer-section-correct-answer' : 'answer-section'} style={{
                                            animationPlayState: `${props.animationStatus ? 'paused' : 'running'}`,
                                        }}
                                            dangerouslySetInnerHTML={{ __html: answer.option.length > 25 ? answer.option.substring(0, 25) + "..." : answer.option}}>
                                        </div>
                                    </div>;

                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer
                    varient="normal"
                    left={<GameButton varient="fboption" onmouseenter={props.toggleOption} ><Icons varient='option' />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2" varient="sbButton" onclick={handleSkipToQuestion}><Icons varient='chart' />{translations?.show_question_leaderboard}</GameButton>}
                    centerTwo={props.animationStatus == 2 ? <GameButton className="me-2" varient="sbButton" onclick={handleReplay} >
                        <><Icons varient='faUndo' color="#aa6600" />{translations?.replay_animation}</>
                    </GameButton> : <GameButton className="me-2" varient="sbButton" onclick={handleAnimation} >{props.animationStatus ? <><Icons varient='startnow' />{translations?.play_animation}</> : <><Icons varient='pause' />{translations?.pause_animation}</>}</GameButton>} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        question: state.question.question,
        questionScore: state.questionScore.questionScore,
        animationStatus: state.animationStatus.animationStatus
    }
}
const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
        changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Showcorrectanswer)

