import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import TeamDetails from "../components/TeamDetails";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { socket } from "../../../../context/socket";
import { connect } from "react-redux";

import Sidebar from "../components/Sidebar/index";
import "../index.css";

function Circuit(props) {
  const { intro_text, name, id, is_live,translations } = JSON.parse(localStorage.getItem("game"));
  const [animationState, setAnimationState] = useState(true);
  const [isAnimationEnd, setAnimationEnd] = useState(true);
  const [reset, setReset] = useState(true);

  const handleContinue = () => {
    props.changePlayerStage(16);
  };
  const handleExit = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "game/" + id);
  };

  const { color } = JSON.parse(localStorage.getItem("profile") ?? '{"color": "transparent"}');


  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `${r},${g},${b}`;
  };

  const hex2rgbaAlt = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `${Math.round(r * .72)},${Math.round(g * .75)},${Math.round(b * .85)}`;
  };

  useEffect(() => {
    localStorage.setItem("playerStage", 4);

    socket.on("get_intro_text", (arg) => {
      if (arg == 2) {
        setAnimationEnd(true);
        setReset(true);
      } else if (arg == 3) {
        setAnimationEnd(false);
      } else {
        setAnimationState(arg);
      }
    });
    return () => {
      socket.off("get_intro_text");
    };
  }, []);

  useEffect(() => {
    const el = document.getElementById("animated");
    if (el)
      el.addEventListener(
        "animationend",
        function () {
          setReset(false);
        },
        false
      );
  }, [reset]);

  return (
    <div className="player-questions position-relative player-intro-video player-answers pt-sm-0 pt-5">
      {/* <div className="navbar-section">
                <Navbar varient="normal" />
            </div> */}
      <div>{props.optionToggle && <Sidebar varient="normal" />}</div>
      <Navbar varient="contentheadershortplayer" />
      {/* <div className="main-content presentation-qr-code d-flex justify-content-center align-items-center" style={{ animation: `${(intro_text.length / 100) > 100 ? (intro_text.length / 100) : 60}s ease-out 0s 1 animateScroll`, animationPlayState: animationState ? 'running' : 'paused' }}> */}
      <div className="intro-text-area flex-center flex-column gap-3">
        <div className="overflow-hidden">
          {reset && (
          <div
            className="main-content presentation-qr-code d-flex justify-content-center align-items-start"
            id="animated"
            // style={
            //   isAnimationEnd
            //     ? {
            //         animation: `90s ease-out 0s 1 animateScroll`,
            //         animationPlayState: animationState ? "running" : "paused",
            //       }
            //     : {}
            // }
          >
            <div className="intro-text text-center mt-5 col-sm-8 col-10">
              <div className="it-heading mt-5">
                <div
                  className="title-sec"
                  style={{ "--team-color": "#" + color }}
                >
                  <h1>{name}</h1>
                </div>
              </div>
              <div className="it-content text-start">
                <div dangerouslySetInnerHTML={{ __html: intro_text }} />
              </div>
              
            </div>
          </div>
          )}
        </div>

        <div className="row justify-content-center w-100">
          <div className="col-sm-8 col-10">
          
            {!is_live && (
              <div className="btn-group-player d-flex align-items-center justify-content-between gap-3 flex-wrap flex-column flex-sm-row">
                <button
                  className="btn btn-primary dynamic-btn px-5 d-flex justify-content-center align-items-center"
                  onClick={handleContinue} style={{ backgroundImage: `linear-gradient(225deg, rgb(${hex2rgba(color)}) 0%, rgb(${hex2rgbaAlt(color)}) 100%)`,"--element-color": hex2rgba(color)}}>
                  <i className="fa-solid fa-play me-2"></i>{translations?.continue}
                </button>
                <button
                  className="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center text-white"
                  onClick={handleExit} style={{ borderColor: `rgb(${hex2rgba(color)})`}}>
                  <i className="fa-solid fa-right-from-bracket me-2"></i>{translations?.exit_game}
                </button>
              </div>
            )}
              
          </div>
        </div>

      </div>


      <TeamDetails />
      <Footer varient="footercontentplayer" />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
  };
};

export default connect(null, mapDispatchToProps)(Circuit);
