import { PODIUMANIMATION } from '../../constants/actionTypes';
const INITIAL_STATE = {
  podiumAnimationPlay: {
    reply:true,
    reset:true
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PODIUMANIMATION:
      return {
        ...state, podiumAnimationPlay: action.data,
      };
    default: return state;
  }
};
export default reducer;