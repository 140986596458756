import { ADDTEAM, UPDATETEAM, DELETEPLAYERTEAM } from './team.types';
export const addTeam = (data) => {

    return {
        type: ADDTEAM,
        data: data
    };
}
export const updateTeam = (id, data) => {
    return {
        type: UPDATETEAM,
        id: id,
        data: data

    };
}

export const deletePlayerTeam = (id, data) => {
    return {
        type: DELETEPLAYERTEAM,
        id: id,
        data: data

    };
}