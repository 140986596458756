import {
	GET_USER_DATA_WITH_AUTH_SUCCESS
} from '../../constants/actionTypes';

const INITIAL_STATE = {
	isLoading: false,
	user: { ...JSON.parse(localStorage.getItem('userData')) } || { email: '', profileImage: '', username: '', usertype: '' },
	error: '',
	isAuthenticated: (localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn') || '')?.isloggedIn) || false,
	userToken: (localStorage.getItem('loggedIn') && JSON.parse(localStorage.getItem('loggedIn') || '')?.token) || '',
	OTPKey: { key: '', email: '', first_login: '' },
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_USER_DATA_WITH_AUTH_SUCCESS:
			return { ...state, isLoading: false, user: action.data }
		default:
			return state;
	}
};

export default authReducer;