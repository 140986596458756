import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import { Modal, Dropdown, Button } from "react-bootstrap";
import moment from "moment";

import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import Icons from "../components/Icons/index";
import TeamDetails from "../components/TeamDetails";

import { performRequest } from "../../../../api/apiHandler";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import {
  updateTeam,
  deletePlayerTeam,
} from "../../../../redux/Teams/team.actions";
import { socket } from "../../../../context/socket";
import { addUser } from "../../../../redux/User/user.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import { addGame } from "../../../../redux/Game/game.actions";

import Loader from "../components/Loader";

import "bootstrap/dist/css/bootstrap.min.css";
import "./playerstart.scss";

function PlayerStarIdle(props) {
  const { name, id, team } = JSON.parse(localStorage.getItem("profile"));
  const localStorageGame = JSON.parse(localStorage.getItem("game"));
  const [player, setPlayer] = useState("");
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [teamName, setTeamName] = useState("team");
  const [icon, setIcon] = useState("fa-flag");
  const [error, setError] = useState(null);
  const [color, setColor] = useState("");
  const [teamId, setTeamId] = useState(0);
  const [date, setDate] = useState(null);
  const [show, setShow] = useState(false);
  const gameTeams = props.teams;
  const game = props.game.game;
  const api_token = localStorage.getItem("accessToken");
  const [timer, setTimer] = useState(false);
  const [schedule, setScedule] = useState(0);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [initialData, setInitialData] = useState(null);
  const translations = localStorageGame?.translations;
  const team_max_size = props.game.game
    ? props.game.game.team_max_size
      ? props.game.game.team_max_size
      : 25
    : 25;
  const skin = localStorageGame
    ? localStorageGame.skin?.game_skin_name
    : game.skin.game_skin_name;

  useEffect(() => {
    if (gameTeams.length) {
      localStorage.setItem("gameTeams", JSON.stringify(gameTeams));
    }
    const frontEndFullUrl = window.location.href;
    const formData1 = new FormData();
    formData1.append("url", window.location.href.split("?")[0]);
    // socket.on('update_team', (arg) => {
    //     alert(1)
    //     props.updateTeam(arg.team_id, arg.players);
    // });

    performRequest("post", "api/game-start", formData1).then((response) => {
      const { id, is_live } = response.data;
      if (!is_live) {
        setShowProfileEdit(true);
      }
      const localStorageGameTeams = JSON.parse(
        localStorage.getItem("gameTeams")
      );
      const getGameTeams = gameTeams ? gameTeams : localStorageGameTeams;
      const getGame = game ? game : localStorageGame;
      if (getGame.id === id && getGameTeams.length) {
        setPlayer(name);
        setTeamId(team);
        setInitialData({ player: name, team: team });
        let getTeam = getGameTeams.find((e) => e.id === team);
        setIcon(getTeam?.icon);
        setTeamName(getTeam?.name);
        setColor(getTeam?.color);
        
      } else {
        props.changePlayerStage(2);
      }
      
    });
  }, []);

  useEffect(() => {
    if (props.game.game && props.game.game.game_type.accept_start_time) {
      const getDate = props.game.game.start_time;
      if (getDate) {
        const [time, date] = getDate.split("on");
        const timeForMoment = date + " " + time + ":00";
        setScedule(timeForMoment);
        setDate(date);
        setTimer(moment(time, "hh:mm").format("hh:mm"));
      }
    }
  }, [props.game]);

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);
    return () => clearInterval(timer);
  }, [schedule]);

  function calculateTimeLeft() {
    const startDate = moment(schedule, "DD.MM.YYYY");
    if (!moment().diff(startDate, "days")) {
      const startTime = moment();
      const endTime = moment(schedule, "DD/MM/YYYY HH:mm:ss");
      const duration = moment.duration(endTime.diff(startTime));
      if (duration.asSeconds() < 0) {
        setTimer(false);
      }
    }
  }

  const handleSelect = (select) => {
    if (
      select.players.length < team_max_size ||
      select.id == initialData["team"]
    ) {
      setTeamName(select.name);
      setTeamId(select.id);
      setIcon(select.icon);
      setColor(select.color);
      setButtonStatus(initialData["team"] === select.id);
    }
  };

  const handleEdit = () => {
    setShowProfileEdit(true);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("team_id", teamId);
    formData.append("name", player);
    formData.append("id", id);
    formData.append("api_token", api_token);
    setError("");
    if (player) {
      performRequest("post", "api/add-player", formData).then((response) => {
        const getData = response.data;
        if (getData.success) {
          setShowProfileEdit(false);
          const data = {
            id: getData.data.id,
            name: player,
            team: teamId,
            game_id: getData.data?.game_id,
            color: getData.data.color,
          };
          props.addUser({
            id: getData.data.id,
            name: player,
            team: teamId,
          });
          props.deletePlayerTeam(initialData["team"], getData.data.id);
          setInitialData({ player: player, team: teamId });
          setButtonStatus(true);
          props.updateTeam(getData.data.team_id, getData.data);
          props.teams.map((team) =>
            socket.emit("add_team", {
              team_id: getData.data?.team_id,
              game_id: getData.data?.game_id,
              players: getData.data,
            })
          );
          props.loader(false);
          const propGame = props.game.game;
          propGame["player_color"] = props.teams.find((e) => e.id == teamId)
            ? props.teams.find((e) => e.id == teamId).color.replace("#", "")
            : "";
          props.addGame(propGame);
          localStorage.setItem("profile", JSON.stringify(data));
          
          if(!localStorageGame?.is_live)
          props.changePlayerStage(3);

        } else {
          setError(getData.message);
        }
      });
    } else {
      setError("Name field is required.");
    }
  };

  const handleModal = () => {
    setShow(!show);
  };

  const handlePlayerName = (e) => {
    setPlayer(e.target.value);
    setButtonStatus(initialData["player"] === e.target.value);
  };

  return (
    <div className="user-registration presentation-player-start player-invitation d-flex flex-column justify-content-md-center align-items-center">
      {!player && <Loader />}
      <Navbar varient="contentheadershortplayer" />
      <div className="col-lg-6 col-xl-6 d-lg-none d-block mb-4">
        <div className="presentation-content d-flex flex-column justify-content-center align-items-center position-relative h-100">
          <h4>{translations?.welcome_to}</h4>
          <img src={`../../assets/skin/${skin}/logo.png`} />
        </div>
      </div>
      <div className="signup col-12 col-sm-8 col-md-12">
        <div className="row m-0">
          <div className="signup-form col-lg-6 col-xl-6 col-md-12 d-flex justify-content-center align-items-center flex-column p-4">
            <div className="player-inv-head position-relative top-0 text-center">
              {!showProfileEdit ? (
                <h5>
                  {!timer ? translations?.waiting_for_the_game_to_start : translations?.game_start_at}
                </h5>
              ) : (
                ""
              )}
              <h4>{timer !== false ? date : ""}</h4>
              {localStorageGame?.is_live && (
                <h2 className="mb-3">{timer || ""}</h2>
              )}
            </div>
            {
              showProfileEdit ? (
                <div className="">
                  <div className="row d-flex">
                    <div className="col-12">
                      <div className="custom-form-field-with-validation position-relative text-start ps-3">
                        {localStorageGame?.is_live ? (
                          <>
                            <label className="ps-4 mb-2 w-100 text-start">
                              {translations?.enter_your_name}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={player}
                              placeholder="Your name"
                              onChange={handlePlayerName}
                            />
                          </>
                        ) : (
                          <h3 className="text-start green-border d-inline-block">{player}</h3>
                        )}

                        {error && (
                          <>
                            <div
                              className={`validation-line  position-absolute ${
                                error ? `error` : `success`
                              }`}
                            ></div>
                            <div className="validation-icon error position-absolute">
                              <Icons varient="warning" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {error && (
                      <div className="col-12">
                        <div
                          className="alert alert-warning mt-2 mb-0 text-start"
                          role="alert"
                        >
                          {error}
                        </div>
                      </div>
                    )}
                    <label className="ps-4 mb-2 w-100 text-start">
                    {translations?.select_your_team}
                    </label>
                    <div className="mob-drop d-block d-sm-none mb-3">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="btn btn-secondary mb-3 text-start py-0 d-flex align-items-center w-100 mt-0"
                        >
                          {/* <div className="player-start team-icon-sec me-2" */}
                          <div
                            className={`${
                              teamId ? "team-icon-sec " : " "
                            }player-start me-2`}
                            style={
                              teamId
                                ? {
                                    backgroundColor: `${
                                      props.teams.find((e) => e.id == teamId)
                                        ? props.teams.find(
                                            (e) => e.id == teamId
                                          ).color
                                        : ""
                                    }`,
                                  }
                                : { height: "34px", color: "#91877E" }
                            }
                          >
                            {teamId ? (
                              <Icons
                                varient={
                                  props.teams.find((e) => e.id == teamId)
                                    ? props.teams.find((e) => e.id == teamId)
                                        .icon
                                    : ""
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="custom-dropdown-text-content"
                            style={
                              teamId
                                ? { backgroundColor: "" }
                                : { color: "#91877E" }
                            }
                          >
                            {teamId
                              ? props.teams.find((e) => e.id == teamId)
                                ? props.teams.find((e) => e.id == teamId).name
                                : ""
                              : "Select team"}
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {props.teams.map((team) => {
                            let maximum = team_max_size ? team_max_size : 25;
                            let countPlayer = team.players.length;
                            return (
                              <Dropdown.Item
                                key={team.id}
                                disabled={!(countPlayer < maximum)}
                              >
                                {/* <div className="d-flex align-items-center" onClick={() => handleSelect(team)}> */}
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => handleSelect(team)}
                                >
                                  <div
                                    className="player-start team-icon-sec me-2 "
                                    style={{ backgroundColor: `${team.color}` }}
                                  >
                                    <Icons varient={team.icon} />
                                  </div>
                                  <p className="mb-0">{team.name}</p>
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="row d-sm-flex d-none">
                      {props.teams.map((eachTeam) => {
                        let maximum = team_max_size ? team_max_size : 25;
                        let countPlayer = eachTeam.players.length;
                        return (
                          <div
                            key={eachTeam.id}
                            className="col-lg-6 col-md-12 col-sm-12 col-12"
                          >
                            <button
                              onClick={() => handleSelect(eachTeam)}
                              className={
                                countPlayer < maximum
                                  ? `btn w-100 btn-secondary mb-3 text-start py-0 d-flex align-items-center ${
                                      teamId == eachTeam.id && "team-sel-active"
                                    }`
                                  : "btn btn-secondary mb-3 text-start py-0 d-flex align-items-center disabled"
                              }
                            >
                              <div
                                className="team-icon-sec me-2"
                                style={{
                                  backgroundColor: `${
                                    countPlayer < maximum
                                      ? eachTeam.color
                                      : "white"
                                  }`,
                                }}
                              >
                                <Icons
                                  varient={eachTeam.icon}
                                  color={
                                    countPlayer < maximum
                                      ? null
                                      : eachTeam.color
                                  }
                                />
                              </div>
                              <div className="custom-dropdown-text-content">
                                {eachTeam.name}
                              </div>
                            </button>
                          </div>
                        );
                      })}
                      <small style={{ color: "red" }}></small>
                    </div>

                    <div className="col-12 d-md-flex justify-content-between align-items-center">
                      <button
                        className={`btn btn-primary d-flex justify-content-center align-items-center `}
                        onClick={handleSubmit}
                      >
                        <Icons varient="check" />
                        {translations?.continue}
                      </button>
                      {localStorageGame?.is_live &&
                      <button
                        className="btn btn-secondary d-flex align-items-center start-btn mt-3 mt-md-0 ms-0 ms-md-3"
                        onClick={() => setShowProfileEdit(false)}
                        style={{
                          borderColor: `${
                            gameTeams.find((e) => e.id == teamId)?.color
                          }`,
                          color: `${
                            gameTeams.find((e) => e.id == teamId)?.color
                          }`,
                        }}
                      >
                        <Icons
                          varient="faClose"
                          color={`${
                            gameTeams.find((e) => e.id == teamId)?.color
                          }`}
                        />
                        {translations?.cancel}
                      </button>}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row d-flex">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-start">
                    <label htmlFor="" className="ps-4 mb-2">
                      {translations?.your_name}
                    </label>
                    <div className="custom-form-field-with-validation position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your name"
                        value={player}
                        disabled
                      />
                      {error && (
                        <>
                          <div
                            className={`validation-line  position-absolute ${
                              error ? `error` : `success`
                            }`}
                          ></div>
                          <div className="validation-icon error position-absolute">
                            <Icons varient="warning" />
                          </div>
                        </>
                      )}
                    </div>
                    <small style={{ color: "red" }}>{error}</small>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-start">
                    <label htmlFor="" className="ps-4 mb-2">
                      {translations?.your_team}
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        disabled
                        className="btn btn-secondary mb-3 text-start py-0 d-flex align-items-center w-100 mt-0"
                      >
                        <div
                          className="player-start team-icon-sec me-2"
                          style={{
                            backgroundColor: `${
                              gameTeams.find((e) => e.id == teamId)
                                ? gameTeams.find((e) => e.id == teamId).color
                                : ""
                            }`,
                          }}
                        >
                          <Icons
                            varient={
                              gameTeams.find((e) => e.id == teamId)
                                ? gameTeams.find((e) => e.id == teamId).icon
                                : ""
                            }
                          />
                        </div>
                        <div className="custom-dropdown-text-content">
                          {gameTeams.find((e) => e.id == teamId)
                            ? gameTeams.find((e) => e.id == teamId).name
                            : ""}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {gameTeams.map((team) => {
                          let maximum = team_max_size ? team_max_size : 25;
                          let countPlayer = team.players.length;
                          return (
                            <Dropdown.Item
                              key={team.id}
                              disabled={!(countPlayer < maximum)}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className="player-start team-icon-sec me-2 "
                                  style={{ backgroundColor: `${team.color}` }}
                                >
                                  <Icons varient={team.icon} />
                                </div>
                                <p className="mb-0">{team.name}</p>
                              </div>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-md-block d-none">
                    <button
                      className="btn btn-secondary d-flex align-items-center start-btn"
                      onClick={handleEdit}
                      style={{
                        borderColor: `${
                          gameTeams.find((e) => e.id === teamId)?.color
                        }`,
                        color: `${
                          gameTeams.find((e) => e.id === teamId)?.color
                        }`,
                      }}
                    >
                      <Icons
                        varient="faPencil"
                        color={`${
                          gameTeams.find((e) => e.id === teamId)?.color
                        }`}
                      />
                      {translations?.edit}
                    </button>
                  </div>
                  {gameTeams.length && (
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="team-player-count py-2">
                        <div
                          className="info-section d-flex align-items-center justify-content-md-end justify-content-center me-4"
                          onClick={handleModal}
                          style={{
                            color: `${
                              gameTeams.find((e) => e.id == teamId)?.color
                            }`,
                          }}
                        >
                          <Icons varient="users" />
                          {gameTeams.find((e) => e.id == teamId)
                            ? gameTeams.find((e) => e.id == teamId).players
                                .length
                            : "0"}
                          /
                          {game
                            ? game.team_max_size
                              ? game.team_max_size
                              : 25
                            : 25}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-md-none d-block">
                    <button
                      className="btn btn-secondary d-flex align-items-center start-btn"
                      onClick={handleEdit}
                      style={{
                        borderColor: `${
                          gameTeams.find((e) => e.id == teamId)?.color
                        }`,
                        color: `${
                          gameTeams.find((e) => e.id == teamId)?.color
                        }`,
                      }}
                    >
                      <Icons
                        varient="faPencil"
                        color={`${
                          gameTeams.find((e) => e.id == teamId)?.color
                        }`}
                      />
                      {translations?.edit}
                    </button>
                  </div>
                </div>
              )
            }
          </div>

          <div
            className={`signup-image presentation-start-game-img col-lg-6 col-xl-6 d-lg-block d-none skin-${skin} background_v3_${props.game?.game?.player_color}`}
          >
            <div className="presentation-content d-flex flex-column justify-content-center align-items-center position-relative h-100">
              <h4>Welcome to</h4>
              <img src={`../../assets/skin/${skin}/logo.png`} />
            </div>
          </div>
          {/* modal */}
          <Modal
            className={`view-team-members-modal ${skin}`}
            show={show}
            onHide={() => setShow(!show)}
            centered
          >
            <Modal.Header closeButton>
              <div className="d-flex align-items-center justify-content-between w-100">
                <h5 className="mb-0">{translations?.team_members} </h5>
                <button
                  type="button"
                  className="btn-close-custom p-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      style={{ fill: `${color}` }}
                      d="M15.17 11L21.4244 4.74562C22.1919 3.97812 22.1919 2.73375 21.4244 1.96562L20.0344 0.575625C19.2669 -0.191875 18.0225 -0.191875 17.2544 0.575625L11 6.83L4.74563 0.575625C3.97813 -0.191875 2.73375 -0.191875 1.96563 0.575625L0.575625 1.96562C-0.191875 2.73312 -0.191875 3.9775 0.575625 4.74562L6.83 11L0.575625 17.2544C-0.191875 18.0219 -0.191875 19.2662 0.575625 20.0344L1.96563 21.4244C2.73313 22.1919 3.97813 22.1919 4.74563 21.4244L11 15.17L17.2544 21.4244C18.0219 22.1919 19.2669 22.1919 20.0344 21.4244L21.4244 20.0344C22.1919 19.2669 22.1919 18.0225 21.4244 17.2544L15.17 11Z"
                      fill="#AA6600"
                    />
                  </svg>
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="team-info-footer custom-for-modal-head d-flex align-items-center mb-3">
                  <div
                    className="player-start team-icon-sec ms-2"
                    style={{ backgroundColor: `${color}` }}
                  >
                    <Icons varient={icon} />
                  </div>
                  <h5 className="ms-2 mb-0">{teamName}</h5>
                </div>
                {props.teams
                  ? props.teams.map(function (team) {
                      if (team.id == teamId) {
                        return (
                          <div key={team.id} className="col-6">
                            <ol>
                              {team.players.map(function (player) {
                                return <li key={player.id}> {player.name} </li>;
                              })}
                            </ol>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>
            </Modal.Body>
          </Modal>
          {/* end modal */}
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    game: state.game,
    user: state.user.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    deletePlayerTeam: (id, data) => dispatch(deletePlayerTeam(id, data)),
    addUser: (data) => dispatch(addUser(data)),
    loader: (data) => dispatch(loader(data)),
    addGame: (data) => dispatch(addGame(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStarIdle);
