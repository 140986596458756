import {
  ADDQUESTION,
  GETQUESTION,
  GET_QUESTION_BY_SUCCESS,
} from "./question.types";
const INITIAL_STATE = {
  question: 0,
  gameQuestion: null,
};

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case ADDQUESTION:
      return {
        ...state,
        question: action.data,
      };

    case GET_QUESTION_BY_SUCCESS:

      return { ...state, gameQuestion: action?.data };

    case GETQUESTION:
      return {
        ...state,
        question: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
