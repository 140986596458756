import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import Game from "./Game";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { connect } from "react-redux"

import AllComponent from "./views/AllComponent";
import Welcome from "./games/GameTypeA/Circuit/views/welcome";
import Presentationteams from "./games/GameTypeA/Circuit/views/presentationteams";
import Presentationstartview from "./games/GameTypeA/Circuit/views/presentationstartview";
import Presentationquestion from "./games/GameTypeA/Circuit/views/presentationquestion";
import Presentationround from "./games/GameTypeA/Circuit/views/presentationround";
import Round from "./games/GameTypeA/Circuit/views/preview/round";

import Showplayeranswer from "./games/GameTypeA/Circuit/views/showplayeranswer";
import Presentationpodium from "./games/GameTypeA/Circuit/views/presentationpodium";
import Playerinvitation from "./games/GameTypeA/Circuit/views/playerinvitation";
import Introvideo from "./games/GameTypeA/Circuit/views/introvideo";
import Qrcode from "./games/GameTypeA/Circuit/views/qrcode";
import Introtext from "./games/GameTypeA/Circuit/views/introtext";
import Home from "../src/games/GameTypeA/Circuit/views/playerinvitation"

import { increaseCounter } from "./redux/Counter/counter.actions"
import { changePlayerStage } from "./redux/Player/player.actions"
import { addTeam, updateTeam } from "./redux/Teams/team.actions"
import { SocketContext, socket } from './context/socket';
import { loader } from "./redux/Loader/loader.actions"
import UrlExpire from './games/GameTypeA/Circuit/components/url_expire';
import Podium from './games/GameTypeA/Circuit/views/preview/podium';

function App(props) {
  const [userType, setUserType] = useState(1);
  const url = window.location.href;
  useEffect(() => {
    if (url.includes("game")) {
      setUserType(2)
    }
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      <div className={`App`}>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/:params' element={<Game />} />
            <Route path='/components' element={<AllComponent />} />
            <Route path='/welcome' element={<Welcome />} />
            <Route path='/presentationteam' element={<Presentationteams />} />
            <Route path='/presentationview' element={<Presentationstartview />} />
            <Route path='/presentationquestion' element={<Presentationquestion />} />
            <Route path='/presentationround' element={<Presentationround />} />
            <Route path='/showplayeranswer' element={<Showplayeranswer />} />
            <Route path='/presentationpodium' element={<Presentationpodium />} />
            <Route path='/playerinvitation' element={<Playerinvitation />} />
            <Route path='/introvideo' element={<Introvideo />} />
            <Route path='/qrcode' element={<Qrcode />} />
            <Route path='/introtext' element={<Introtext />} />
            <Route path='/urlexpire' element={<UrlExpire />} />
            <Route path="/preview-round" element={<Round/>} />
            <Route path="/preview-podium" element={<Podium/>} />
          </Routes>
        </Router>
      </div>
    </SocketContext.Provider>
  );
}

const mapStateToProps = state => {
  return {
    count: state.counter.count,
    audioToggle: state.audioToggle.audioToggle,
    isLoader: state.isLoader,
    control: state.control
  }
}

const mapDispatchToProps = dispatch => {
  return {
    increaseCounter: () => dispatch(increaseCounter()),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addTeam: (data) => dispatch(addTeam(data)),
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    loader: (data) => dispatch(loader(data)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);