import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import GameButton from "../components/Button/index";
// import "./presentationstartview.scss";
import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import logo from "../components/assets/images/team_logo.svg";


export default function AllComponent(props) {
    return (
        <div className="presentation-controller-main position-relative">
            <div className="navbar-section">
                PLAYERS
            </div>
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <input placeholder="add game url"/>
            </div>
            <div className="footer-section">
                <Footer/>
            </div>
        </div>
    );}


