import React, { useEffect, useState } from 'react';
import { connect } from "react-redux"
import { changePresentationStage } from "../../../../redux/Counter/counter.actions";
import { toggleFullScreen } from "../../../../redux/Fullscreen/fullscreen.actions";

import WelcomePage from '../views/welcome'
import PresentationStartView from '../views/presentationstartview';
import PresentationTeamView from '../views/presentationteams';
import Presentationquestion from "../views/presentationquestion";
import Showplayeranswer from "../views/showplayeranswer";
import Showcorrectanswer from "../views/showcorrectanswer";
import Presentationpodium from "../views/presentationpodium";
import Presentationround from "../views/presentationround"
import GamePause from "../views/gamepause"
import Sidebar from "../components/Sidebar/index"
import Qrcode from './qrcode';
import Introvideo from './introvideo';
import Introtext from './introtext';
import PresentationPause from './PresentationPause';


const margin = { marginLeft: '100px' };

function Circuit(props) {
    const [userType, setUserType] = useState(1);
    let game = props.game;
    const [skin, setSkin] = useState('');
    const getGame = JSON.parse(localStorage.getItem('game'));
    const getSkin = getGame ? getGame?.skin?.game_skin_name : '';
    const propSkin = props.game?.game?.skin?.game_skin_name ? props.game?.game?.skin.game_skin_name : getSkin;
    useEffect(() => {
        localStorage.setItem('questions', JSON.stringify([]));
        const isRoundExist = localStorage.getItem('rounds');
        const currentStage = localStorage.getItem('stage');
        if (currentStage) {
            props.changePresentationStage(currentStage);
        }
        if (isRoundExist) {
        }
    }, [props.count])

    useEffect(() => {
        setSkin(propSkin);
        import(`../assets/skin/${propSkin}/css/carskin.scss`);
    }, [props.game])


    useEffect(() => {
        if (document.addEventListener) {
            document.addEventListener('webkitfullscreenchange', exitHandler, false);
            document.addEventListener('mozfullscreenchange', exitHandler, false);
            document.addEventListener('fullscreenchange', exitHandler, false);
            document.addEventListener('MSFullscreenChange', exitHandler, false);
        }

    }, []);

    function exitHandler() {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            props.toggleFullScreen(false);
        }
    }

    let Screen = '';
    const gameUrl = game.custom_game_url;

    switch (parseInt(props.count)) {
        case 1:
            Screen = <WelcomePage url={gameUrl} />
            break;
        case 2:
            Screen = <PresentationTeamView />
            break;
        case 3:
            Screen = <Introvideo />
            break;
        case 4:
            Screen = <Introtext />
            
            break;
        case 5:
            Screen = <Presentationquestion />
            break;
        case 6:
            Screen = <Showplayeranswer />
            break;
        case 7:
            Screen = <Showcorrectanswer />
            break;
        case 8:
            Screen = <PresentationStartView />
            break;
        case 9:
            Screen = <Presentationround />
            break;
        case 10:
            Screen = <Presentationpodium />
            break;
        case 11:
            Screen = <Qrcode />
            break;
        case 12:
            Screen = <PresentationPause />
            break;
        case 13:
            Screen = <GamePause />
            break;
        default:
            Screen = <div className="background">
                <h1 className='title' />
                <div style={margin}>
                </div>
                <div>
                    Game Skin:
                </div>
                <div>
                    Game Color:
                </div>
            </div>
            break;
    }
    return (
        <>
            {window.innerWidth < 768 ? <Sidebar varient="normal" /> :
                // <div className={['astro', 'car'].includes(skin) ? skin : ''}>
                // skin-car
                <div className={`main-bg skin-${propSkin} ${props.control.control == 1 ? `full-screen-game` : ``} ${[4, 11].includes(parseInt(props.count)) ? `custom-bg-2` : [5, 6, 7, 9].includes(parseInt(props.count)) ? `custom-bg-2` : [8].includes(parseInt(props.count)) ? `custom-bg-4` : [10].includes(parseInt(props.count)) ? `custom-bg-5` : userType == 2 ? `custom-bg-1` : ``} `}>
                    <div >
                        {props.optionToggle && <Sidebar varient="normal" />}
                    </div>
                    {Screen}
                </div>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game,
        playerScreen: state.player.playerScreen,
        audioToggle: state.audioToggle.audioToggle,
        optionToggle: state.optionToggle.optionToggle,
        control: state.control,
        fullscreenToggle: state.toggleFullScreen.fullscreenToggle

    }
}
const mapDispatchToProps = dispatch => {
    return {
        changePresentationStage: (data) => dispatch(changePresentationStage(data)),
        toggleFullScreen: (data) => dispatch(toggleFullScreen(data))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);



