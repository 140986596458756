import { MEMORIZE } from '../../constants/actionTypes';

export const updateMemorize = (data) => {
    return {
        type: MEMORIZE,
        data: data
    };
};


