import { CHANGEANIMATIONSTATUS } from '../../constants/actionTypes';
const INITIAL_STATE = {
  animationStatus: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGEANIMATIONSTATUS:
      return {
        ...state, animationStatus: action.data,
      };
    default: return state;
  }
};

export default reducer;