import React, { useEffect, useState, useRef } from "react";
import "../index.css";
import { connect } from "react-redux";
import {
  increaseCounter,
  decreaseCounter,
} from "../../../../redux/Counter/counter.actions";
import { socket } from "../../../../context/socket";
import { toggleOption } from "../../../../redux/Option/option.actions";

import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import { changeAnimationStatus } from "../../../../redux/Animations/animation.actions";

function Circuit(props) {
  const game = JSON.parse(localStorage.getItem("game"));
  const translations = game?.translations;
  const intro_video = game?.skin?.intro_video ?? game?.theme?.intro_video;
  const [isPlay, setIsPlay] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [show, setShow] = useState(true);
  const video = useRef();
  const currentSkin = props.game
    ? props.game?.game?.skin?.game_skin_name
    : game.skin?.game_skin_name;

  function handleSkipToText() {
    socket.emit("broadcast_stage", { player: 4, presentation: 4, id: game.id });
  }

  function handleFreezeIntro() {
    setIsPlay(!isPlay);
    props.changeAnimationStatus(isPlay ? 0 : 1);
    const game = JSON.parse(localStorage.getItem("game"));
    if (isPlay) {
      socket.emit("pause", { isPause: true, id: game.id });
      video.current.pause();
    } else {
      socket.emit("pause", { isPause: false, id: game.id });
      video.current.play();
    }
  }

  const handleReplay = () => {
    setShow(true);
    setIsEnd(false);
    socket.emit("pause", { isPause: false, id: game.id });
    props.changeAnimationStatus(1);
    video.current.play();
  };

  useEffect(() => {
    props.changeAnimationStatus(1);
    const el = document.getElementById("video");
    if (el)
      el.addEventListener(
        "ended",
        function () {
          props.changeAnimationStatus(2);
          setIsEnd(true);
          setShow(false);
          /////////////////////
        },
        false
      );
    socket.on("get_video", (arg) => {
      if (arg) video.current.pause();
      else video.current.play();
    });

    const keyDownHandler = (event) => {
      if (event.key == "ArrowRight") {
        handleSkipToText();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    // if (props.animationStatus == 1) {
    //     video.current.play();
    //     socket.emit('pause', { isPause: props.animationStatus, id: game.id })
    // }
    // else if (props.animationStatus == 0) {
    //     socket.emit('pause', { isPause: props.animationStatus, id: game.id })
    //     video.current.pause();
    // }
  }, [props.animationStatus]);

  return (
    <div className="presentation-question position-relative">
      <div className="navbar-section">
        <Navbar varient="normal" />
      </div>
      <Navbar varient="contentheadershort" />
      <div className="main-content presentation-qr-code d-flex justify-content-center align-items-center">
        <div className="video-container col-12 yakeety-intro-video">
          <video
            ref={video}
            autoPlay
            id="video"
            style={show ? { display: "" } : { display: "none" }}
          >
            <source src={intro_video} type="video/mp4" />
          </video>
          <img
            src={`../../assets/skin/${currentSkin}/logo.png`}
            alt=""
            style={show ? { display: "none" } : { display: "" }}
          />
        </div>
      </div>
      <Footer varient="footercontentpresentation" />
      <div className="footer-section">
        <Footer
          varient="normal"
          left={
            <GameButton
              varient="fboption"
              onclick={props.toggleOption}
              onmouseenter={props.toggleOption}
            >
              <Icons varient="option" />
              {translations?.options}
            </GameButton>
          }
          centerOne={
            <GameButton
              className="me-2"
              varient="sbButton"
              onclick={handleSkipToText}
            >
              <Icons varient="skipintro" />
              {isEnd
                ? translations?.show_presentation_text
                : translations?.skip_presentation_text}{" "}
            </GameButton>
          }
          centerTwo={
            props.animationStatus == 2 ? (
              <GameButton
                className="me-2"
                varient="sbButton"
                onclick={handleReplay}
              >
                <Icons varient="faUndo" color="#aa6600" />
                {translations?.replay_video}
              </GameButton>
            ) : (
              <GameButton
                className="me-2"
                varient="sbButton"
                onclick={handleFreezeIntro}
              >
                {props.animationStatus ? (
                  <>
                    <Icons varient="pause" />
                    {translations?.freeze_video}
                  </>
                ) : (
                  <>
                    <Icons varient="startnow" />
                    {translations?.play_video}
                  </>
                )}
              </GameButton>
            )
          }
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    count: state.counter.count,
    game: state.game,
    playerScreen: state.player.playerScreen,
    animationStatus: state.animationStatus.animationStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    increaseCounter: () => dispatch(increaseCounter()),
    decreaseCounter: () => dispatch(decreaseCounter()),
    toggleOption: () => dispatch(toggleOption()),
    changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);
