import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import GameButton from "../components/Button/index";
import Icons from '../components/Icons/index';
import "./presentationteams.scss";
import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import { socket } from "../../../../context/socket";
import { connect } from "react-redux";
import { loader } from "../../../../redux/Loader/loader.actions"


function Presentationteams(props) {
    const teams = props.teams;
    const { team_max_size, id,translations } = JSON.parse(localStorage.getItem('game'));
    const handleBack = () => {
        const memorize = props.memorize.memorize ? props.memorize.memorize : localStorage.getItem('memorize');
        if(memorize==5){
            props.loader(true);
        }
        socket.emit('broadcast_stage', { player: null, presentation: memorize, id: id });
        localStorage.removeItem('memorize');
    }
    useEffect(()=>{
        localStorage.setItem('pauseTimes',props.animationStatus)

    },[])
    return (
        <div className="presentation-team-main position-relative">
            <div className="navbar-section">
                <Navbar varient="normal" />
            </div>
            <div className="main-content position-absolute align-items-start team-list-view">
                <div className="team-tables mt-3 w-100 ps-5">
                    <div className="col-12 p-0">
                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <div className="row">
                                    {teams ? teams.map(function (team) {
                                        return <div className="col-3" key={team.id}>
                                            <div className="team-list-head">
                                                <div className="d-flex align-items-center">
                                                    <div className="tl-head-logo d-flex align-items-center justify-content-center green me-3"
                                                        style={{ backgroundColor: `${team.color}` }}
                                                    >
                                                        <Icons varient={team.icon} />
                                                    </div>
                                                    <h4 className="mb-0">{team.name}</h4>
                                                </div>
                                                <p className="ps-2 text-start">{team.players.length}/{team_max_size ? team_max_size : 25}</p>
                                            </div>
                                            <ol>
                                                {team.players.map(function (player) {
                                                    return <li>{player.name}</li>;
                                                })}

                                            </ol>
                                        </div>;
                                    }) : ''}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer varient="footerwithleftbtn">
                    <GameButton varient="sbButton" onclick={handleBack} ><Icons varient='back' />{translations?.back}</GameButton>
                </Footer>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        teams: state.team.teams,
        game: state.game,
        memorize: state.memorize,
        animationStatus: state.animationStatus.animationStatus

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loader: (data) => dispatch(loader(data)),
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Presentationteams);
