import { CHANGEANIMATIONSTATUS } from '../../constants/actionTypes';

export const changeAnimationStatus = (data) => {
    return {
        type: CHANGEANIMATIONSTATUS,
        data
    };
};


