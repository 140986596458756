import React, { useEffect, useState, Component } from "react";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBars, faCheck, faCoffee, faDragon, faReply, faStar, faExpandAlt, faHourglassHalf, faEye, faFastForward, faFlag, faForward, faMagic, faMagicWandSparkles, faPause, faPlay, faQrcode, faRocket, faSave, faSignOutAlt, faStop, faTrophy, faUserAstronaut, faUserFriends, faUserNinja, faVolumeMute, faHome } from '@fortawesome/free-solid-svg-icons'
import * as iconCosnt from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css'; //import in react app


export default function Icons(props) {
    let Icons = '';
    let varient = props.varient;
    let color = props.color;
    // const renderIcons=()=>{
    switch (varient) {
        case 'startnow':
            Icons = <FontAwesomeIcon icon={faPlay} className="me-2" />
            break;
        case 'skipintro':
            Icons = <FontAwesomeIcon icon={faFastForward} className="me-2" />
            break;
        case 'pause':
            Icons = <FontAwesomeIcon icon={faPause} className="me-2" />
            break;
        case 'pencil':
            Icons = <FontAwesomeIcon icon={iconCosnt.faMedal}  className="me-2"/>
            break;
        case 'medal':
            Icons = <FontAwesomeIcon icon={iconCosnt.faPencil}  className="me-2"/>
            break;
        case 'forward':
            Icons = <FontAwesomeIcon icon={faForward} className="me-2" />
            break;
        case 'finish':
            Icons = <FontAwesomeIcon icon={faTrophy} className="me-2" />
            break;
        case 'stop':
            Icons = <FontAwesomeIcon icon={faStop} className="me-2" />
            break;
        case 'fullscreen':
            Icons = <FontAwesomeIcon icon={faExpandAlt} className="me-2" />
            break;
        case 'qrcode':
            Icons = <FontAwesomeIcon icon={faQrcode} className="me-2" />
            break;
        case 'visible':
            Icons = <FontAwesomeIcon icon={faEye} className="me-2" />
            break;
        case 'mute':
            Icons = <FontAwesomeIcon icon={faVolumeMute} className="me-2" />
            break;
        case 'option':
            Icons = <FontAwesomeIcon icon={faBars} className="me-2" />
            break;
        case 'users':
            Icons = <FontAwesomeIcon icon={faUserFriends} className="me-2" />
            break;
        case 'flag':
            Icons = <FontAwesomeIcon icon={faFlag} className="me-2" />
            break;
        case 'back':
            Icons = <FontAwesomeIcon icon={faAngleLeft} className="me-2" />
            break;
        case 'exitgame':
            Icons = <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
            break;
        case 'tlrocket':
            Icons = <FontAwesomeIcon icon={faRocket} />
            break;
        case 'tlcoffee':
            Icons = <FontAwesomeIcon icon={faCoffee} />
            break;
        case 'tlninja':
            Icons = <FontAwesomeIcon icon={faUserNinja} />
            break;
        case 'tldragon':
            Icons = <FontAwesomeIcon icon={faDragon} />
            break;
        case 'tlastro':
            Icons = <FontAwesomeIcon icon={faUserAstronaut} />
            break;
        case 'magic':
            Icons = <FontAwesomeIcon icon={faMagicWandSparkles} className="me-2" />
            break;
        case 'check':
            Icons = <FontAwesomeIcon icon={faCheck} className="me-2" />
            break;
        case 'checkIcon':
            Icons = <svg className="me-2" width="33" height="20" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3686 23.4664L0.96861 13.0664C0.343797 12.4416 0.343797 11.4285 0.96861 10.8036L3.2313 8.54088C3.85611 7.91601 4.86924 7.91601 5.49405 8.54088L12.5 15.5468L27.5059 0.540875C28.1308 -0.0839376 29.1439 -0.0839376 29.7687 0.540875L32.0314 2.80363C32.6562 3.42844 32.6562 4.4415 32.0314 5.06638L13.6314 23.4665C13.0065 24.0913 11.9934 24.0913 11.3686 23.4664Z" fill="white"/></svg>
            break;
        case 'save':
            Icons = <FontAwesomeIcon icon={faSave} className="me-2" />
            break;
        case 'timer':
            Icons = <FontAwesomeIcon icon={faHourglassHalf} className="me-2" />
            break;
        case 'chart':
            Icons = <FontAwesomeIcon icon={iconCosnt.faChartSimple} className="me-2" />
            break;
        case 'star':
            Icons = <FontAwesomeIcon icon={faStar} className="me-2" />
            break;
        case 'reply':
            Icons = <FontAwesomeIcon icon={faReply} className="me-2" />
            break;
        case 'warning':
            Icons = <FontAwesomeIcon icon={iconCosnt.faCircleExclamation} className="me-2" />
            break;
        case 'qrhide':
            Icons = <FontAwesomeIcon icon={iconCosnt.faQrcode} className="me-2" />
            break;
        default:
            const icon = varient ? varient.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) : 'faFlag';
            Icons = <FontAwesomeIcon icon={iconCosnt[icon]} color={color ? color : 'white'} className="me-2" />
            break;
    }

    // }
    return (
        <div>
            {/* <FontAwesomeIcon icon={iconCosnt[varient]} /> */}
            {Icons}
        </div>
    )
}


