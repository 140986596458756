import React, { useEffect, useState, useRef } from "react";
import "../index.css";
import { connect } from "react-redux";
import {
  decreaseCounter,
  increaseCounter,
} from "../../../../redux/Counter/counter.actions";
import { changePlayerStage } from "../../../../redux/Player/player.actions";

import { socket } from "../../../../context/socket";
import { toggleOption } from "../../../../redux/Option/option.actions";
import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import TeamDetails from "../components/TeamDetails";
import Sidebar from "../components/Sidebar/index";

function Circuit(props) {
  const game = JSON.parse(localStorage.getItem("game"));
  const intro_video = game?.skin?.intro_video ?? game?.theme?.intro_video;
  const video1 = useRef();
  const [show, setShow] = useState(true);
  const currentSkin = props.game
    ? props.game?.game?.skin?.game_skin_name
    : game.skin?.game_skin_name;

  const handleContinue = () => {
    props.changePlayerStage(4);
  };
  const { color } = JSON.parse(
    localStorage.getItem("profile") ?? '{"color": "#fff"}'
  );
  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${r},${g},${b}`;
  };

  const hex2rgbaAlt = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    // const colorAdjust = [r = (r - 20), g = (g - 20), b = (b - 20)];
    return `${Math.round(r * 0.72)},${Math.round(g * 0.75)},${Math.round(
      b * 0.85
    )}`;
  };

  // useEffect(() => {
  //   localStorage.setItem("playerStage", 3);
  //   video1.current.click();
  //   const el = document.getElementById("video");
  //   if (el)
  //     el.addEventListener(
  //       "ended",
  //       function () {
  //         setShow(false);
  //       },
  //       false
  //     );
  // }, []);

  // useEffect(() => {
  //   socket.on("is_pause", (arg) => {
  //     if (arg == false) {
  //       video1.current?.pause();
  //     } else {
  //       setShow(true);
  //       video1.current.play();
  //     }
  //   });
  //   return () => {
  //     socket.off("is_pause");
  //   };
  // }, []);

  useEffect(() => {
    localStorage.setItem("playerStage", 3);
    video1.current.click();
    const el = document.getElementById("video");
    if (el) {
      el.addEventListener("ended", () => setShow(false), false);
    }

    socket.on("is_pause", (arg) => {
      if (arg === false) {
        video1.current?.pause();
      } else {
        setShow(true);
        video1.current.play();
      }
    });

    return () => {
      if (el) {
        el.removeEventListener("ended", () => setShow(false), false);
      }
      socket.off("is_pause");
    };
  }, []);

  return (
    <div className="player-questions position-relative player-intro-video player-answers">
      <div>{props.optionToggle && <Sidebar varient="normal" />}</div>
      <Navbar varient="contentheadershortplayer" />
      <div className="main-content presentation-qr-code d-flex justify-content-center align-items-center">
        <div className="video-container col-12 yakeety-intro-video flex-column">
          <video
            id="video"
            style={show ? { display: "" } : { display: "none" }}
            ref={video1}
            playsInline
            autoPlay
            muted
          >
            <source src={intro_video} type="video/mp4" />
          </video>
          <img
            style={show ? { display: "none" } : { display: "" }}
            src={`../../assets/skin/${currentSkin}/logo.png`}
            alt=""
          />
          {!game?.is_live && (
            <button
              class="btn btn-primary dynamic-btn px-5 mb-3 mt-2 d-flex justify-content-center align-items-center"
              onClick={handleContinue}
              style={{
                backgroundImage: `linear-gradient(225deg, rgb(${hex2rgba(
                  color
                )}) 0%, rgb(${hex2rgbaAlt(color)}) 100%)`,
                "--element-color": hex2rgba(color),
              }}
            >
              <i class="fa-solid fa-play me-2"></i>{game?.translations?.continue}
            </button>
          )}
        </div>
      </div>

      <TeamDetails />
      <Footer varient="footercontentplayer" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    count: state.counter.count,
    game: state.game,
    playerScreen: state.player.playerScreen,
    optionToggle: state.optionToggle.optionToggle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    increaseCounter: () => dispatch(increaseCounter()),
    decreaseCounter: () => dispatch(decreaseCounter()),
    toggleOption: () => dispatch(toggleOption()),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);
