import React from "react";
import { connect } from "react-redux";

import { socket } from '../../../../../context/socket'
import {
    toggleOption
}
    from "../../../../../redux/Option/option.actions"

import Footer from "../../components/Footer/index";
import GameButton from "../../components/Button/index";
import Icons from "../../components/Icons/index";


function Showcorrectanswer(props) {
    const { id } = JSON.parse(localStorage.getItem('game'));

    function handleSkipToNext() {
        socket.emit('broadcast_stage', { player: 8, presentation: 8 ,id:id})
    }

    function handleSkipIntro() {
        socket.emit('broadcast_stage', { player: 5, presentation: 5 ,id:id})
    }
    return (
        <Footer
            varient="normal"
            left={<GameButton varient="fboption" onclick={props.toggleOption} ><Icons varient='option' />Options</GameButton>}
            centerOne={<GameButton className="me-2" varient="sbButton" onclick={handleSkipToNext}><Icons varient='chart' />Show question leaderboard</GameButton>}
            centerTwo={<GameButton className="me-2" varient="sbButton" onclick={handleSkipIntro} ><Icons varient='skipintro' />Freeze results</GameButton>} />
    );
}

const mapStateToProps = state => {
    return {
        question: state.question.question,
        questionScore: state.questionScore.questionScore
    }
}
const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),

    }
}

export default connect(mapStateToProps)(Showcorrectanswer)

