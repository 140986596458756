import { ADDQUESTIONSCORE } from './questionScore.types';
const INITIAL_STATE = {
  questionScore: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case ADDQUESTIONSCORE:
      return {
        ...state, questionScore: action.data,
      };
    default: return state;
  }
};

export default reducer;