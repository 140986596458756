import React from 'react';
import socketio, { io } from "socket.io-client";
import { SOCKET_URL } from "../config";

// export const socket = socketio.connect(SOCKET_URL, { transports: ['websocket'] });
export const socket = io(SOCKET_URL,
    {
    transports: ['websocket'],
    reconnection: true,             // whether to reconnect automatically
    reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
    reconnectionDelay: 1000,        // how long to initially wait before attempting a new reconnection
    reconnectionDelayMax: 5000,     // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
    randomizationFactor: 0.5
});
export const SocketContext = React.createContext();


// import React from 'react';
// import socketio, { io } from "socket.io-client";
// import { SOCKET_URL } from "../config";

// // // export const socket = socketio.connect(SOCKET_URL, { transports: ['websocket'] });
// // export const socket = io(SOCKET_URL, { transports: ['websocket'] });
// // export const SocketContext = React.createContext();

// const Socket = (children) => {

//     const socket = io(SOCKET_URL, { transports: ['websocket'] });

//     return (<>
//         {children}
//     </>
//     )
// }

// export default Socket;

