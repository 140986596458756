import { FULLSCREENTOGGLE } from '../../constants/actionTypes';
const INITIAL_STATE = {
  fullscreenToggle: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FULLSCREENTOGGLE:
      return {
        ...state, fullscreenToggle: action.data,
      };
    default: return state;
  }
};

export default reducer;