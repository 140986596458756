import { ADDUSER } from '../../constants/actionTypes';

export const addUser = (data) => {
    return {
        type: ADDUSER,
        data: data
    };
};


