import { INCREMENT, DECREMENT, PRESENTATIONSTAGE } from './counter.types';
export const increaseCounter = () => {
    return {
        type: INCREMENT,
    };
};
export const decreaseCounter = (data) => {
    return {
        type: DECREMENT,
        data: data
    };
};

export const changePresentationStage = (data) => {
    return {
        type: PRESENTATIONSTAGE,
        data: data
    };
}
