import { ADDQUESTION,GET_QUESTION_BY_SUCCESS } from './question.types';
import { performRequest } from '../../api/apiHandler';
// import { loader } from "./redux/Loader/loader.actions"
import { loader } from "../Loader/loader.actions"


const getQuestionBySucess = (data) => ({ type: GET_QUESTION_BY_SUCCESS, data })


export const addQuestion = (data) => {
    return {
        type: ADDQUESTION,
        data: data
    };
}


export const getQuestion = (data) => async (dispatch) => {
    dispatch(loader(true))
    try {
        const res = await performRequest('get','api/users/'+data.id+'/question/'+data.user_id)
        if (res.status === 200) {
            dispatch(getQuestionBySucess(res?.data))
            
            dispatch(loader(false))
            return { success: true, message: 'success',data: res.data}

        }

    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        return { success: false, message: message }
    }
}

export const updateQuestion = (data) => {
    // getQuestionBySucess(data)
    return {
        type: GET_QUESTION_BY_SUCCESS,
        data: data
    };

}


// export const actionGetRequestOrderDetailsById = (id) => async (dispatch) => {
//     dispatch(getRequestOrderDetailsByIdBegins())
//     try {
//         const res = await api.get(`/admin/orders/${id}/request-order-details`)
//         if (res.status === 200) {
//             const { data } = res?.data
//             dispatch(getRequestOrderDetailsByIdSuccess(data))
//             return { success: true, message: 'success' }
//         }
//     }
//     catch (error) {
//         const { message } = error?.response?.data || { message: 'Something went wrong' }
//         return { success: false, message: message }
//     }
// }