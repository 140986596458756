export const generateUniqeId = () => {
  let navigator_info = window.navigator;
  let screen_info = window.screen;
  let uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  return uid;
};


export const checkUrl = (url, data) => {
  if(url.includes('localhost:')){
    const originalUrl = url.split("?")[0];
    const regex = /http:\/\/localhost:3000/;
    url = originalUrl.replace(regex, 'https://app.yakeety.com');
    }
    if (!data.is_live || data.game_url === url || data.custom_game_url === url) {
      return "player";
    } else if (data.presentation_url === url || data.custom_presentation_url === url) {
      return "presentation";
    }
  };
  
