import React from 'react'
import './layout.scss'
import { connect } from "react-redux"


function SingleLongHeader(props) {
    const gameData = props.game;
    const game = JSON.parse(localStorage.getItem('game'));
    const last = game?.color_combination?.color_class;
    const first = game?.color_combination?.file_path;
    const topLeftClass = props?.game?.player_color ? 'player_top_left_' + props?.game?.player_color.replace('#', '') : 'player_top_left';
    const topRightClass = props?.game?.player_color ? 'player_top_right_' + props?.game?.player_color.replace('#', '') : 'player_top_right';

    const skin = gameData ? gameData?.skin?.game_skin_name : game?.skin.game_skin_name;

    return (
        <div className='normal-header long-header position-relative'>
                        {skin!='astro'?
            <div className="defualt-left-cloud">
                <img className='img-fluid' src={`../../assets/skin/${skin}/cloud/player/${topLeftClass}.png`} />
            </div>:''}
            {1?
            <div className="defualt-right-cloud">
                <img className='img-fluid' src={`../../assets/skin/${skin}/cloud/player/${topRightClass}.png`} />
            </div>:''}
            <svg viewBox="0 0 1452 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0V160.44C0 160.44 47.24 179.53 91.84 176.88C143.93 174.23 134.73 147.84 295.33 84.27C296.24 83.91 298.48 82.89 304.93 82.44C311.92 82.47 329.34 82.38 329.34 82.38L622.86 82.56C622.86 82.56 637.24 82.49 642.35 81.46C647.46 80.43 683.63 64.78 683.63 64.78C693.84 59.93 696.4 61.12 706.1 60.95C733.93 61.04 1161.87 61.86 1161.87 61.86C1161.87 61.86 1171.06 60.16 1185.7 68.33C1200.34 76.5 1237.79 94.54 1294.98 89.44C1351.49 83.99 1384.87 63.29 1451.77 66.99V0H0Z" fill="url(#paint0_linear_104_22)" />
                <defs>
                    <linearGradient id="paint0_linear_104_22" x1="2376.5" y1="-343.499" x2="-1019.5" y2="716.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor={first} />
                        <stop offset="1" stopColor={last} />
                    </linearGradient>
                </defs>
            </svg>
            {1?

            <svg viewBox="0 0 1452 148" fill="none" xmlns="http://www.w3.org/2000/svg" className='second-svg position-absolute'>
                <path d="M0 0V147.67H209.37C209.37 147.67 221.71 148.35 231.33 140.95C240.95 133.55 300.52 87.25 300.52 87.25C307.24 82.4 314.22 82.74 318.39 82.57C351.33 82.48 644.16 82.31 644.16 82.31C644.16 82.31 660.16 83.84 665.1 69.71C670.04 55.58 681.27 55.75 684.33 55.07C726.71 54.9 1174.27 56.07 1174.27 56.07C1174.27 56.07 1229.42 51.81 1292.91 89.43C1349.76 119.39 1372.61 121.37 1451.77 112.3V0H0Z" fill="url(#paint0_linear_103_20)" />
                <defs>
                    <linearGradient id="paint0_linear_103_20" x1="2110.5" y1="-571.5" x2="-617.5" y2="703.5" gradientUnits="userSpaceOnUse">
                        <stop stopColor={first} />
                        <stop offset="0.987559" stopColor={last} />
                    </linearGradient>
                </defs>
            </svg>:''}


            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1451.76 177.13">
                <path fill={game?.color_combination?.color_class} d="M0 0v160.44s47.24 19.09 91.84 16.44c52.09-2.65 42.89-29.04 203.49-92.61.91-.36 3.15-1.38 9.6-1.83 6.99.03 24.41-.06 24.41-.06l163.52.18s14.38-.07 19.49-1.1 41.28-16.68 41.28-16.68c10.21-4.85 12.77-3.66 22.47-3.83 27.83.09 620.77-.09 620.77-.09s9.19-1.7 23.83 6.47 52.09 26.21 109.28 21.11c56.51-5.45 82.57-20.43 121.79-21.45V0H0Z" />
            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1451.76 147.69"
                className='second-svg position-absolute'
            >
                <path fill={game?.color_combination?.color_class} d="M0 0v147.67h209.37s12.34.68 21.96-6.72 69.19-53.7 69.19-53.7c6.72-4.85 13.7-4.51 17.87-4.68 32.94-.09 228.77-.26 228.77-.26s16 1.53 20.94-12.6 16.17-13.96 19.23-14.64c42.38-.17 612.94 0 612.94 0s55.15-4.26 118.64 33.36c56.85 29.96 83.25 31.66 132.86 25.87V0H0Z" />
            </svg> */}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        game: state.game.game,
    }
}

export default connect(mapStateToProps, null)(SingleLongHeader)