import React, { useEffect, useState } from "react";
import { connect } from "react-redux"
import moment from 'moment';

import GameButton from "../Button/index";
import Icons from './../Icons/index';
import logo from './../assets/images/logo_img.svg';
import teamlogo from "./../assets/images/team_logo.svg";

import { changeControl } from "../../../../../redux/Control/control.actions";
import { changeSelection } from '../../../../../redux/Selection/selection.actions';
import { gameStatus, displayText } from "../../../../../constants/constant"
import { socket } from '../../../../../context/socket';
import { toggleAudio } from "../../../../../redux/Audio/audio.actions";
import { toggleOption } from "../../../../../redux/Option/option.actions"
import { updateMemorize } from '../../../../../redux/Memorize/memorize.actions';
import { roundAnumationStatus } from '../../../../../redux/RoundAnimation/roundAnimation.actions';
import { podiumAnimationStatus } from '../../../../../redux/PodiumAnimation/podiumAnimation.actions';
import { toggleFullScreen } from '../../../../../redux/Fullscreen/fullscreen.actions';
import { toggleQrCode } from '../../../../../redux/QrCode/qrcode.actions';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function Sidebar(props) {
    const [conrtroller, setController] = useState({ 'title': 0, 'mainbutton': 'ctrlstartnow', 'displayText': 0 });
    const [timer, setTimer] = useState(false);
    const [date, setDate] = useState(null);
    const [schedule, setScedule] = useState(0);
    const [heading, setHeading] = useState(0);
    const [subHeading, setSubHeading] = useState(0);
    const [toggleTheme, setToggleTheme] = useState(true);
    const game = JSON.parse(localStorage.getItem('game'));
    const translations=game?.translations; 
    const { rounds_count } = JSON.parse(localStorage.getItem('current'));
    const team_max_size = game ? game.team_max_size : 25;
    const skin = props.game ? props.game?.skin?.game_skin_name : game?.skin?.game_skin_name;
    const teams = props.teams;
    const totalPlayers = teams.reduce((previousValue, currentValue, index) => previousValue + currentValue.players.length, 0);
    let Sidebar = '';
    let varient = props.varient;

    useEffect(() => {
        const control = conrtroller;
        if (props.game?.game_type?.accept_start_time) {
            const getDate = props.game.game.start_time;
            if (getDate) {
                const [time, date] = getDate.split('on');
                const timeForMoment = date + ' ' + time + ':00';
                setScedule(timeForMoment);
                setDate(date);
                setTimer(moment(time, "hh:mm").format("hh:mm"));
            }
        }
        switch (parseInt(props.count)) {
            case 2:
                control.title = 3
                setController(control);
                break;
            case 3:
                setHeading(2)
                setSubHeading(5)
                break;
            case 4:
                setHeading(2)
                setSubHeading(6)
                break;
            case 5:
                setHeading(3)
                setSubHeading(7)
                break;
            case 6:
                setHeading(3)
                setSubHeading(8)
                break;
            case 7:
                setHeading(3)
                setSubHeading(8)
                break;
            case 8:
                setHeading(3)
                setSubHeading(9)
                break;
            case 9:
                setHeading(4)
                setSubHeading(10)
                break;
            case 10:
                setHeading(5)
                setSubHeading(11)
                break;
            default:
                setController(control);
                break;
        }

        if (props.count == 3) {
            // control.title = 2;
            // control.displayText = 5;
            // setController(control);
        }

    }, [props.count])


    const handleStart = () => {
        props.changeSelection(1);
        socket.emit('broadcast_stage', { player: 3, presentation: 3, id: game.id });
        const control = conrtroller;
    }

    const handleSkipIntro = () => {
        props.changeSelection(2);
        props.updateMemorize(props.count);
        socket.emit('broadcast_stage', { player: 5, presentation: 5, id: game.id });
    }

    const handlePause = () => {
   
        if (props.selection == 3) {
            setHeading(1)
            props.changeSelection(0);
            socket.emit('broadcast_stage', { player: [1, 12].includes(parseInt(props.memorize.memorize)) ? 11 : props.memorize.memorize, presentation: props.memorize.memorize, id: game.id })
        } else {
            setHeading(0)

            props.changeSelection(3);
            props.updateMemorize(props.count);
            socket.emit('broadcast_stage', { player: 13, presentation: 12, id: game.id })
        }

    }

    const handleShowQr = () => {
        // props.changeSelection(8);
        props.toggleQrCode(!props.qrcodeToggle);
        if (props.count != 11) {
            localStorage.setItem('qr_memorized', props.count)
            socket.emit('broadcast_stage', { player: null, presentation: 11, id: game.id });

        } else {
            socket.emit('broadcast_stage', { player: 5, presentation: localStorage.getItem('qr_memorized'), id: game.id });
        }
    }


    let timeOut = 0;
    const handleFinishRound = () => {
        props.roundAnumationStatus({
            reply: false,
            reset: false
        });
        if (timeOut) {
            clearTimeout(timeOut);
            timeOut = 0;
        }
        timeOut = setTimeout(() => {
            props.roundAnumationStatus({
                reply: false,
                reset: true
            });
        }, 500)
    }



    const handleFinishGame = () => {
        socket.emit('broadcast_stage', { player: 10, presentation: 10, id: game?.id })
    }

    const handleUnpause = () => {
        props.changeSelection(11);
        socket.emit('broadcast_stage', { player: 12, presentation: props.memorize.memorize, id: game.id })
    }


    function handleNextQuestion() {
        let { rounds, id } = JSON.parse(localStorage.getItem('game'));
        const lastRound = rounds.length - 1;
        const lasrQuestion = (rounds[rounds.length - 1].questions).length - 1;
        // const { round_pos, question_pos } = JSON.parse(localStorage.getItem('rounds'));
        const { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };

        if (!(round_pos == lastRound && question_pos == lasrQuestion)) {
            if (rounds[round_pos].questions.length > question_pos + 1) {
                socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
                localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos, question_pos: question_pos + 1 }));
            } else {
                if (rounds.length > round_pos + 1) {

                    localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos + 1, question_pos: 0 }));
                } else {
                }
                socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id });
            }
        }
    }

    const handleExtra = () => {
        props.changeSelection(9);
        props.changeControl(props.control ? 0 : 1);
        localStorage.setItem('controlTabStatus', !JSON.parse(localStorage.getItem('controlTabStatus')))
    }

    const handleFulscreen = () => {

        props.changeSelection(7);
        if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {  // current working methods
            props.toggleFullScreen(true);
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            props.toggleFullScreen(false);
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    const handleAudio = () => {
        props.changeSelection(10);
        props.toggleAudio(!props.audioToggle);
    }

    const showButton = (button) => {
        
        switch (button) {
            case 'fbback':
                return <GameButton varient="fbbutton" ><Icons varient='back' />{translations?.back}</GameButton>;
                break;
            case 'mbskiptopresentation':
                return <GameButton varient="mbbuttons" ><Icons varient='skipintro' onClick={handleSkipIntro} />{translations?.skip_to_presentation}</GameButton>;
                break;
            case 'showcorrectanswer':
                return <GameButton varient="mbbuttons" ><Icons varient='skipintro' />{translations?.show_correct_answer}</GameButton>;
                break;
            case 'showleaderboard':
                return <GameButton varient="mbbuttons" ><Icons varient='skipintro' />{translations?.show_leaderboard}</GameButton>;
                break;
            case 'shownextquestion':
                return <GameButton varient="mbbuttons" ><Icons varient='skipintro' />{translations?.next_question}</GameButton>;
                break;
            case 'showpoduim':
                return <GameButton varient="mbbuttons" ><Icons varient='skipintro' />{translations?.podium}</GameButton>
                break;
            case 'shownroundextquestion':
                return <GameButton varient="mbbuttons" ><Icons varient='pencil' />{translations?.show_round_leaderboard}</GameButton>
                break;
            case 'ctrlskipintro':
                return <GameButton varient="ctrlbutton" ><Icons varient='skipintro' />{translations?.skip_intro}</GameButton>
                break;
            case 'ctrlstartnow':
                return <GameButton varient="controlbutton" ><Icons varient='skipintro' />{translations?.start_now}</GameButton>
                break;
            case 'ctrlaction':
                return <GameButton varient="ctrlaction" ><Icons varient='skipintro' />{translations?.freeze_video}</GameButton>
                break;
            case 'ctrlfreezetimer':
                return <GameButton varient="ctrlbutton" ><Icons varient='skipintro' />{translations?.freeze_timer}</GameButton>
                break;
            case 'ctrlfreezeslide':
                return <GameButton varient="ctrlbutton" ><Icons varient='skipintro' />{translations?.freeze_slide}</GameButton>
                break;
            case 'ctrlfreezeresult':
                return <GameButton varient="ctrlbutton" ><Icons varient='skipintro' />{translations?.freeze_result}</GameButton>
                break;
            case 'ctrlpauseanimation':
                return <GameButton varient="ctrlbutton" ><Icons varient='skipintro' />{translations?.pause_animation}</GameButton>
                break;
            case 'ctrlreplayanimation':
                return <GameButton varient="ctrlbutton" ><Icons varient='skipintro' />{translations?.replay_animation}</GameButton>
                break;
            //end for controller
            default:
                return <GameButton varient="mbstartnow" />;
                break;
        }
    }

    switch (varient) {
        case 'normal':
            Sidebar = <div className={`yakeety-sidebar ${toggleTheme && 'light-mode-active'} d-flex flex-column justify-content-between align-items-center py-4`} onMouseLeave={props.toggleOption}>
                <div className="logo-section mb-5 d-md-block d-none">
                    <img src={logo} alt="" />
                </div>
                <div className="d-block d-md-none mob-sidebar w-100 px-4 mb-4">
                    <div className="mob-sidebar-head d-flex justify-content-between align-items-center">
                        <div className="msh-team-logo">
                            <img src={`../../assets/skin/${skin}/logo.png`} alt="" className="img-fluid" />
                        </div>
                        <div className="msh-darkmode-button d-flex align-items-center">
                            <div className="toggle me-1">
                                <input type="checkbox" className="toggle-input" onClick={() => setToggleTheme(!toggleTheme)}></input>
                            </div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.85059 16C11.3181 16 13.5718 14.8773 15.0649 13.0378C15.2857 12.7656 15.0449 12.3681 14.7036 12.4331C10.8222 13.1722 7.25787 10.1963 7.25787 6.27825C7.25787 4.02131 8.46605 1.94591 10.4297 0.828438C10.7324 0.656188 10.6562 0.197281 10.3123 0.13375C9.83014 0.0448416 9.34088 7.30105e-05 8.85059 0C4.43468 0 0.850586 3.57847 0.850586 8C0.850586 12.4159 4.42905 16 8.85059 16Z" />
                            </svg>
                        </div>
                    </div>
                    <div className="controller-view mt-4">
                        <div className="game-stats-section mb-3">
                            <div className="game-start-section p-3">
                                <h4 className="mb-0">{gameStatus[heading]}</h4>
                            </div>
                            <div className="text-center game-status p-3">
                                <h4>{displayText[timer ? 1 : subHeading]}</h4>
                                <h4>{timer}</h4>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div className="info-section d-flex align-items-center me-3">
                                        <Icons varient='users' />
                                        {totalPlayers}/{(team_max_size ?? 25) * teams.length}
                                    </div>
                                    <div className="info-section d-flex align-items-center">
                                        <Icons varient='flag' />
                                        {props.question ? props.question.round_no : 0}/{rounds_count}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="controller-actions-section px-4">
                            <div className="mb-2 action-first">
                                {showButton(conrtroller.mainbutton)}
                            </div>
                            <div className="mb-3">
                                {showButton("ctrlaction")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-section">
                    {window.innerWidth > 768 &&
                        <div className="mb-3 bs-divider pb-3">
                            {showButton(conrtroller.mainbutton)}
                            {showButton("ctrlaction")}
                        </div>
                    }
                    <div className="my-3 bs-divider pb-3">
                        <GameButton varient="sbButton" onclick={handlePause} >{props.selection == 3 ? <><Icons varient='pause' />{translations?.unpause_game}</> : <><Icons varient='pause' />{translations?.pause_game}</>}</GameButton>
                    </div>
                    <div className="my-3 bs-divider pb-3">

                        <GameButton varient="sbButton" onclick={handleFulscreen} className={props.selection == 7 ? props.control == 1 ? `active` : `` : ``}>{props.fullscreenToggle ? <><Icons color="#aa6600" varient='faDownLeftAndUpRightToCenter' />{translations?.full_screen}</> : <><Icons varient='fullscreen' />{translations?.full_screen}</>}</GameButton>
                        <GameButton varient="sbButton" onclick={handleShowQr} className={props.selection == 8 && `active`}>{props.qrcodeToggle ? <><Icons color="#aa6600" varient='faThLarge' />{translations?.hide_qr}</> : <><Icons varient='qrcode' />{translations?.show_qr}</>}</GameButton>
                        <GameButton varient="sbButton" onclick={handleExtra} className={`${props.selection == 9 && 'active'} text-wrap`}>
                            {(localStorage.getItem('controlTabStatus') != undefined ? JSON.parse(localStorage.getItem('controlTabStatus')) : true) ? <><Icons varient='faEyeSlash' color="#aa6600" />{translations?.hide_info_and_control_bar}</> : <><Icons varient='visible' />{translations?.show_info_and_control_bar}</>}
                        </GameButton>
                        <GameButton varient="sbButton" onclick={handleAudio} className={`${props.selection == 10 && ' active '} ${[0, 1, 3, 4].includes(parseInt(props.count)) && ' disabled'}`}>{props.audioToggle ? <><Icons varient='mute' />{translations?.mute_sound}</> : <><Icons varient='faVolumeHigh' color="#aa6600" /> <span>{[0, 1, 3, 4].includes(parseInt(props.count)) ? translations?.mute_sound : translations?.unmute_sound}</span></>}</GameButton>
                    </div>
                    <div className="d-none d-md-block mt-2">
                        <GameButton varient="sbButton" className="text-wrap disabled"><Icons varient='exitgame' />{translations?.dashboard} ({translations?.exit_game})</GameButton>
                    </div>

                    <div className="d-block d-md-none mt-2">
                        <GameButton varient="sbButton" ><Icons varient='exitgame' />{translations?.dashboard} ({translations?.exit_game})</GameButton>
                        <div className="d-flex justify-content-end w-100 mt-2">
                            <img src={logo} alt="" className="sb-mb-footer-logo" />
                        </div>
                    </div>
                </div>
            </div>
            break;
        default:
            Sidebar = <div className="yakeety-header">
                <div className="navbar p-4">
                    <div className="text-section d-flex align-items-top"><p className="mb-0"><span>{translations?.join_now}:</span> https//app.yakeety.com/</p><h5 className="mb-0">astroquiz</h5></div>
                    <div className="logo-img-section"><img src={logo} alt="" /></div>
                </div>
                <div className="color-scheme-section d-flex align-items-center w-100">
                    <div className="cs-color green"></div>
                    <div className="cs-color red"></div>
                    <div className="cs-color violet"></div>
                    <div className="cs-color cyan"></div>
                    <div className="cs-color blue"></div>
                </div>
            </div>
            break;
    }
    return (
        <div>
            {Sidebar}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        control: state.control.control,
        audioToggle: state.audioToggle.audioToggle,
        teams: state.team.teams,
        question: state.question.question,
        memorize: state.memorize,
        qrcodeToggle: state.toggleQrCode.qrcodeToggle,
        selection: state.selection.selection,
        fullscreenToggle: state.toggleFullScreen.fullscreenToggle
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeControl: (data) => dispatch(changeControl(data)),
        changeSelection: (data) => dispatch(changeSelection(data)),
        podiumAnimationStatus: (data) => dispatch(podiumAnimationStatus(data)),
        roundAnumationStatus: (data) => dispatch(roundAnumationStatus(data)),
        toggleAudio: (data) => dispatch(toggleAudio(data)),
        toggleFullScreen: (data) => dispatch(toggleFullScreen(data)),
        toggleOption: () => dispatch(toggleOption()),
        toggleQrCode: (data) => dispatch(toggleQrCode(data)),
        updateMemorize: (data) => dispatch(updateMemorize(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);