import React from "react";
import { connect } from "react-redux"
import Icons from './../Icons/index';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from "react";


function TeamDetails(props) {
    const getTeam = props.teams;
    const teamFromLS = JSON.parse(localStorage.getItem('profile'));
    const teamName = getTeam.find(e => e.id == (props?.user ? props?.user?.team : teamFromLS?.team));
    return (
        teamName?
        <div className="team-info-footer d-flex align-items-center" >
            <h6 className="mb-0" style={{ color: 'white' }}>{teamName.name}</h6>
            <div className="player-start team-icon-sec ms-2"
                style={{ backgroundColor: `${teamName.color}` }}
            >
                <Icons varient={teamName.icon} />
            </div>
        </div>:''
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game,
        teams: state.team.teams,
        user: state.user.user,

    }
}
export default connect(mapStateToProps)(TeamDetails);


