import React from 'react'
import { connect } from "react-redux"
import './layout.scss'


function Footer(props) {
  let gameData = props.game;
  const game = JSON.parse(localStorage.getItem('game'));
  const topLeftClass = props?.game?.player_color ? 'player_bottom_right_' + props?.game?.player_color.replace('#','') : 'player_bottom_right';
  const skin = gameData ? gameData?.skin?.game_skin_name : game?.skin.game_skin_name;

  return (
    <div className='normal-footer position-relative'>
        <img src={`../../assets/skin/${skin}/cloud/player/${topLeftClass}.png`} />
    </div>
  )
}
const mapStateToProps = state => {
  return {
    game: state.game.game,
    teams: state.team.teams,
  }
}
export default connect(mapStateToProps, null)(Footer)