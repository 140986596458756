import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { connect } from "react-redux";

import "./welcome.scss";
import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import { socket } from "../../../../context/socket";

import { toggleOption } from "../../../../redux/Option/option.actions"


function WelcomePage(props) {
    const [timer, setTimer] = useState(false);
    const [schedule, setScedule] = useState(0);
    const [date, setDate] = useState(null);
    let { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };
    const localStorageGame = JSON.parse(localStorage.getItem('game'));
    const translations=localStorageGame?.translations;
    const skin = props.game ? props.game?.game?.skin?.game_skin_name : localStorageGame.skin?.game_skin_name;
    function handleStart() {
        const { id } = JSON.parse(localStorage.getItem('game'));
        const teams = props.teams;
        if (teams.length) {
            socket.emit('broadcast_stage', { player: 3, presentation: 3, id: id })
        }
        else {
            alert('no team found')
        }
    }

    useEffect(() => {
        if (props.game.game && props.game.game.game_type.accept_start_time) {
            const getDate = props.game.game.start_time;
            if (getDate) {
                const [date,time ] = getDate.split(' ');
                const timeForMoment = date + ' ' + time + ':00';
                setScedule(timeForMoment);
                setDate(date);
                setTimer(moment(time, "hh:mm").format("hh:mm"));
            }
        }
    }, [props.game])

    function calculateTimeLeft() {
        // check dates are equal
        const startDate = moment(schedule, "DD.MM.YYYY")
        if (!moment().diff(startDate, 'days')) {
            const startTime = moment();
            const endTime = moment(schedule, 'DD/MM/YYYY HH:mm:ss');
            const duration = moment.duration(endTime.diff(startTime));
            if (duration.asSeconds() == 0) {
                handleSkipIntro();
            }
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            calculateTimeLeft();
        }, 1000);
        return () => clearInterval(timer);
    }, [schedule]);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key == 'ArrowRight') {
                handleStart();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [])

    function handleSkipIntro() {
        const { id } = JSON.parse(localStorage.getItem('game'));
        if ((round_pos || question_pos)) {
            socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
        } else {
            socket.emit('broadcast_stage', { presentation: 5, id: id })
        }

    }

    return (
        <div className="presentation-controller-main position-relative" >
            <div className="navbar-section">
                <Navbar varient="normal" url={props.url} />
            </div>
            <Navbar varient="contentheadershort" />
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <h2>{translations?.welcome_to}</h2>
                <img src={`../../assets/skin/${skin}/logo.png`} />
                {timer != false ? 
                
                    <div className="game-paused-text-section mt-4 custom-light-navy-bg">
                        <h5 className="custom-font-color-white">{timer != false ? translations?.game_start_at : ''}</h5>
                        <h3 className="custom-font-color-white">{timer != false ? date : ''}</h3>
                        <h1 className="custom-font-color-white">{timer != false ? timer : ''}</h1>
                    </div>
                
                :''}


            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer
                    varient="normal"
                    left={<GameButton varient="fboption" onclick={props.toggleOption} onmouseenter={props.toggleOption} ><Icons varient='option' />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2" varient="sbButton" onclick={handleStart}><Icons varient='startnow' />{translations?.start_now}</GameButton>}
                    centerTwo={<GameButton className="me-2" varient="sbButton" onclick={handleSkipIntro} ><Icons varient='skipintro' />{translations?.skip_intro}</GameButton>} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        teams: state.team.teams,
        game: state.game,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage)


