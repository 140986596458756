import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Icons from "../components/Icons/index";
import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import Star from "../components/assets/images/star.svg";
import pos1 from "../components/assets/images/first.png";
import pos2 from "../components/assets/images/second.png";
import pos3 from "../components/assets/images/third.png";
import { performRequest } from "../../../../api/apiHandler";
import TeamDetails from "../components/TeamDetails";
import { loader } from "../../../../redux/Loader/loader.actions";
import { socket } from "../../../../context/socket";

import trophyPng from "../components/assets/images/trophy.png"

import "./playerpodium.scss";

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `${r},${g},${b}`;
};


function PlayerPodium(props) {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const [rounds, setRounds] = useState([]);
  const { id,translations } = JSON.parse(localStorage.getItem('game'));
  const [isWinner, setIsWinner] = useState(false);
  const { team } = JSON.parse(localStorage.getItem('profile'));
  useEffect(() => {
    localStorage.setItem('playerStage', 10);
    const formData = new FormData()
    formData.append('id', id)

    socket.on('reset_game', (arg) => {
      localStorage.clear();
      window.location.reload();

    });

    // props.loader(true);
    performRequest('post', 'api/get-podium-score', formData)
      .then(response => {
        props.loader(false);
        const roundScores = response.data.questions;
        setRounds(roundScores);
        if (team) {
          if (roundScores[0].id == team) {
            setIsWinner(true)
          }
          roundScores.map((value, index) => {
            if (value.id == team) {
              setIsWinner(index)
            }
          })
        }
      })
  }, []);

  return (
    <div className="presentation-start-view position-relative player-questions">
      <Navbar varient="contentheadershortplayer" />
      <div className="main-content player-top-align pb-0 d-flex flex-column align-items-center dusk-skin">
      <div className="lite-bg flex-column astro-player">
        <div className="col-lg-7 col-12">
          <div className="podium-result w-100">
            <h2 className="mb-4 position-relative" style={{'--team-color': '#'+profile.color}}><span>End scores</span></h2>

            <div className="pb-5 pt-3 dusk-space-0">

              {isWinner == 0 && rounds.length>1 &&
                <div className="podium-rating-section text-center position-relative"
                  style={{ background: `linear-gradient(180deg, rgba(0,0,0,0) 0%, ${rounds[0].color} 100%` }}
                >
                  <div className="won-text">
                    <h3>{translations?.congrats}</h3>
                    <h5>{translations?.team_won_game}</h5>
                  </div>
                  <div className="star-rating d-flex gap-1 position-absolute">
                    <div className="small-star">
                      <img src={Star} alt="" className="img-fluid" />
                    </div>
                    <div className="big-star">
                      <img src={Star} alt="" className="img-fluid" />
                    </div>
                    <div className="small-star">
                      <img src={Star} alt="" className="img-fluid" />
                    </div>
                    <div className="team-pos">
                      <img src={pos1} alt="" />
                    </div>
                  </div>
                </div>
              }
              {isWinner == 1 && rounds.length > 1 &&
                <div className="podium-rating-section text-center position-relative"
                  style={{ background: `linear-gradient(180deg, rgba(0,0,0,0) 0%, ${rounds[1].color} 100%` }}
                >
                  <div className="won-text">
                    <h3>{translations?.congrats}</h3>
                    <h5>{translations?.team_won_second_game}</h5>
                  </div>
                  <div className="star-rating d-flex gap-1 position-absolute">
                    <div className="small-star">
                      <img src={Star} alt="" className="img-fluid" />
                    </div>
                    <div className="big-star">
                      <div style={{ width: "92px" }}></div>
                    </div>
                    <div className="small-star">
                      <img src={Star} alt="" className="img-fluid" />
                    </div>
                    <div className="team-pos">
                      <img src={pos2} alt="" />
                    </div>
                  </div>
                </div>
              }
              {isWinner == 2 && rounds.length > 2 &&
                <div className="podium-rating-section text-center position-relative"
                  style={{ background: `linear-gradient(180deg, rgba(0,0,0,0) 0%, ${rounds[2].color} 100%` }}
                >
                  <div className="won-text">
                  <h3>{translations?.congrats}</h3>
                  <h5>{translations?.team_won_third_game}</h5>
                  </div>
                  <div className="star-rating d-flex gap-1 position-absolute">
                    <div className="small-star">
                      <img src={Star} alt="" className="img-fluid" />
                    </div>
                    <div className="big-star">
                    </div>
                    <div className="small-star">
                    </div>
                    <div className="team-pos">
                      <img src={pos3} alt="" />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="custom-podium-table custom-bg-forplayer-table col-lg-7 col-md-10 col-12 pb-5">
          <div className="leader-board-section col-12 custom-padding-for-ltable mb-2 mt-4 p-0 m-0 pb-2 d-md-block d-none">
            <div className="col-12 leader-board-table-header">
              <div className="row m-0">
                <div className="col-3">
                </div>
                <div className="col-9 ps-1">
                  <div className="d-flex align-items-center justify-content-around podium-table-head">
                    {
                      rounds.length ? rounds[0].roundScore.map((score, index) => {
                        return <p className="mx-1 mb-0">{index + 1}</p>;
                      }) : ''
                    }
                    <p className="mx-1 mb-0">Total</p>
                    {rounds.length ? rounds[0]?.most_round_win && <p className="mx-1 mb-0"> {translations?.round_winner} </p> : ''}
                    {rounds[0]?.is_round_correct_answer && <p className="mx-1 mb-0">{translations?.round_correct_answer}</p>}
                  </div>
                </div>
              </div>
            </div>
            {


              <ul className="leaderboard-ul-list p-0 m-0 player-leaderboard">
                {rounds
                  .map((score, index) => {
                    let roundTotal = 0;
                    let roundLength = score.roundScore.length;
                    return <li id={`team` + (index + 1)} key={score.id} className="team leader-board-table-row w-100 blue"
                      style={{ background: 'none' }}>
                        <div className="col-3 d-flex align-items-center first-list-sec">
                          <span className="list-item item1"
                            style={{'--element-color-rgb': hex2rgba(score.color), '--element-color': score.color }}>
                            <div className="d-flex align-items-center text-nowrap name-sec">
                              <div className="tr-count d-flex justify-content-center align-items-center me-2"
                                style={{ background: score.color }}>
                                {index + 1}
                              </div>
                            </div>
                          </span>

                          <span className="list-item item2 ps-3 w-100"
                            style={{ '--element-color-rgb': hex2rgba(score.color), '--element-color': score.color }}>
                            <div className="d-flex align-items-center text-nowrap name-sec">
                              <p className="mb-0 lb-team-name d-inline-block text-truncate long-name-v2">{score.name}</p>
                            </div>
                          </span>

                        </div>
                      <div className="col-9 ps-0">
                        <div className="d-flex align-items-center justify-content-between player-point-round">
                          {
                            score.roundScore ? score.roundScore.map((eachScore, value) => {
                              if (eachScore) {
                                roundTotal += eachScore.score;
                                return <span className={`list-item item` + (score.roundScore.indexOf(eachScore) + 3)} style={{ '--element-color-rgb': hex2rgba(score.color), '--element-color': score.color }}><p key={eachScore.id} className="mx-1 mb-0">{eachScore.score}</p></span>;
                              } else {
                                return <span className={`list-item item` + (value + 3)} style={{ '--element-color-rgb': hex2rgba(score.color), '--element-color': score.color }}><p className="mx-1 mb-0">0</p></span>;
                              }
                            }) : ''
                          }
                          <span className={`list-item pe-2 item` + (roundLength + 3)}
                            style={score.most_round_win || score.is_round_correct_answer ? { '--element-color-rgb': hex2rgba(score.color), '--element-color': score.color } : { '--element-color-rgb': hex2rgba(score.color), borderTopRightRadius: '30px', borderBottomRightRadius: '30px', '--element-color': score.color }}>
                            <div className="mx-1 total-table text-center">
                              <p className="mb-0 total-font" style={{ lineHeight: '21px' }}>{roundTotal}</p>
                            </div>
                          </span>
                          {
                            score.most_round_win &&
                            <span className={`list-item item` + (roundLength + 3)}
                              style={{ '--element-color-rgb': hex2rgba(score.color), borderTopRightRadius: '30px', borderBottomRightRadius: '30px', '--element-color': score.color }}>
                              <div className="mx-1 total-table text-center">
                                <p className="mb-0 total-font" style={{ lineHeight: '21px' }}>{score.round_winner_count}</p>
                              </div>
                            </span>
                          }
                          {
                            score.is_round_correct_answer &&
                            <span className={`list-item pe-4 item` + (roundLength + 3)}
                              style={{ '--element-color-rgb': hex2rgba(score.color), borderTopRightRadius: '30px', borderBottomRightRadius: '30px', '--element-color': score.color }}>
                              <div className="mx-1 total-table text-center">
                                <p className="mb-0 total-font" style={{ lineHeight: '21px' }}>{score.total_round_correct}</p>
                              </div>
                            </span>
                          }

                        </div>
                      </div>

                    </li>;
                  })}
              </ul>


              // rounds.map((round, index) => {
              //   let total = 0;
              //   return <div className="leader-board-table-row w-100 blue mb-2"
              //     style={{ background: `linear-gradient(90deg, ${round.color + 80} 0.65%, ${round.color} 3.9%, ${round.color + 40} 50.64%)` }}>
              //     <div className="col-12 xtra-border">
              //       <div className="row m-0">
              //         <div className="col-3 ps-1">
              //           <div className="d-flex align-items-center">
              //             <div className="tr-count d-flex justify-content-center align-items-center me-2"
              //               style={{ background: round.color }}>
              //               {index + 1}
              //             </div>
              //             <Icons varient={round.icon_class} />
              //             <p className="mb-0 ms-2 group-name-leader">{round.name}</p>
              //           </div>
              //         </div>
              //         <div className="col-9">
              //           <div className="d-flex align-items-center">
              //             {round.roundScore.map((score) => {
              //               if (score) {
              //                 total += score.score;
              //                 return <p key={score.id} className="mx-4 mb-0">{score.score}</p>
              //               } else {
              //                 return <p className="mx-4 mb-0">0</p>;
              //               }

              //             })}
              //             <div className="mx-3 total-table text-center">
              //               <h5 className="mb-0"><b>{total}</b></h5>
              //             </div>
              //             {round.most_round_win &&
              //               <div className="mx-3 total-table text-center">
              //                 <h5 className="mb-0"><b>{round.round_winner_count} </b></h5>
              //               </div>
              //             }
              //             {
              //               round.is_round_correct_answer &&
              //               <div className="mx-3 total-table text-center">
              //                 <h5 className="mb-0"><b>{round.total_round_correct} </b></h5>
              //               </div>
              //             }

              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // })
            }
          </div>
          <div className="leader-board-section verticle-table col-12 for-mob-screen custom-padding-for-ltable mb-5 mt-sm-4 d-md-none d-block mt-3 dusk-player-lb">
            <div className="d-flex m-0 align-items-end justify-content-center">
              <div className="leader-board-table-header me-2">
                <div>
                  <div className="d-flex flex-column justify-content-end align-items-lg-center align-items-end">
                    {
                      rounds.length ? rounds[0].roundScore.map((score, index) => {
                        return <p className="mt-2 mb-0">{index + 1}</p>;
                      }) : ''
                    }
                    <p className="my-2">Total</p>
                  </div>
                </div>
              </div>


              {
                rounds.map((round, index) => {
                  let total = 0;
                  return <div className="leader-board-table-row me-2"
                    style={{ '--element-color-rgb': hex2rgba(round.color) }}>
                    <div className="">
                      <div className="col-12 small-space">
                        <div className="d-flex flex-column justify-content-center align-items-center mob-right-align">
                          <div className="tr-count d-flex justify-content-center align-items-center mb-3 mt-1"
                            style={{ background: round.color }}>
                            {index + 1}
                          </div>
                          <div className="team-icon">
                            <Icons varient={round.icon_class} />
                          </div>
                          {round.roundScore.map((score) => {
                            if (score) {
                              total += score.score;
                              return <p key={score.id} className="mt-2 mb-0">{score.score}</p>
                            } else {
                              return <p className="mt-2 mb-0">0</p>;
                            }

                          })}
                          {/* {round.roundScore.map((score) => {
                            total += score.score;
                            return <p key={score.id} className="mt-2 mb-0">{score.score}</p>
                          })} */}
                          <div className="my-2 mob-full-width">
                            <p className="mb-0"><strong>{total}</strong></p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
        </div>
        
        {/* <div className="podium-msg">
          <div className="msg-box">
              <h2>Custom title set in Podium screen</h2>
              <p>Custom text that is set in Game creation | Edit Round.  If no text is set then this popover isn’t shown. The cup shows only when there’s enough space.</p>
              <img src={trophyPng} alt="trophy" />
          </div> 
        </div> */}

      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />
      <div className="footer-section for-player-footer footer-plb-2">
        <Footer varient="footercontent" />
      </div>
    </div>

  );
}
const mapStateToProps = state => {
  return {
    question: state.question.question,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loader: (data) => dispatch(loader(data))
  }
}
export default connect(null, mapDispatchToProps)(PlayerPodium);
