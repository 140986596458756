import React from 'react'
import { connect } from "react-redux"
import './layout.scss'

function LongFooter(props) {
  let gameData = props.game.game;
  const game = JSON.parse(localStorage.getItem('game'));
  const last = gameData?.player_color ? gameData?.player_color : game?.color_combination?.color_class;
  const first = gameData?.player_color ? gameData?.player_color : game?.color_combination?.file_path;
  const skin = game?.skin?.game_skin_name;
  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <div className='long-footer position-relative'>
        <div className="defualt-right-cloud">
          <img className='img-fluid' src={`../../../../../assets/skin/${skin}/cloud/presenter/cloud_bottom_center.png`} alt="" />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    game: state.game
  }
}

export default connect(mapStateToProps, null)(LongFooter)
// export default LongFooter