import { ADDGAME } from '../../constants/actionTypes';
const INITIAL_STATE = {
  game: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADDGAME:
      return {
        ...state, game: action.data,
      };
    default: return state;
  }
};

export default reducer;