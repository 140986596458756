import React, { useEffect, useState, Component } from "react";
import Button from 'react-bootstrap/Button';


export default function index(props) {
    let GameButton = '';
    let varient = props.varient;
    switch (varient) {
        case 'normal':
            GameButton = <Button>on click</Button>
            break;
        case 'background':
            GameButton = <Button>Save</Button>
            break;
        default:
            GameButton = <Button>default</Button>
            break;
    }
    return (
        <div>
            {GameButton}
        </div>
    )
}


