import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../components/Footer/index";


export default function AllComponent(props) {
    return (
        <div className="presentation-controller-main position-relative">
            <div className="navbar-section">
                PLAYERS
            </div>
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <input placeholder="Set time"/>
            </div>
            <div className="footer-section">
                <Footer/>
            </div>
        </div>
    );}


