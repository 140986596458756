import { UPDATEQUESTIONSTORE } from '../../constants/actionTypes';
const INITIAL_STATE = {
  currentQuestion: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case UPDATEQUESTIONSTORE:
      return {
        ...state, currentQuestion: action.data,
      };
    default: return state;
  }
};

export default reducer;