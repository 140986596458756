import { SELECTION } from '../../constants/actionTypes';

const INITIAL_STATE = {
  selection: 0
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECTION:
      return {
        ...state, selection: action.data,
      };
    default: return state;
  }
};

export default reducer;