import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { connect } from "react-redux"

import Icons from './../Icons/index';
import { socket } from '../../../../../context/socket';
import { changeSelection } from '../../../../../redux/Selection/selection.actions';
import { updateMemorize } from '../../../../../redux/Memorize/memorize.actions';
import { changeAnimationStatus } from '../../../../../redux/Animations/animation.actions'
import { podiumAnimationStatus } from "../../../../../redux/PodiumAnimation/podiumAnimation.actions";
import { performRequest } from "../../../../../api/apiHandler";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

function Buttons(props) {
    let ActionButton = '';
    let varient = props.varient;
    const [video, setVideo] = useState(false);
    const [text, setText] = useState(false);
    const [answerAnimation, setAnswerAnimation] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [questionAnimation, setQuestionAnimation] = useState(false);

    const [roundAnimation, setRoundAnimation] = useState(false);
    const [podiumAnimation, setPodiumAnimation] = useState(false);
    // handleRoundAnimation
    const [timer, setTimer] = useState(false);
    const { id,translations } = localStorage.getItem('game') ? JSON.parse(localStorage.getItem('game')) : '';

    const handleFreezeVideo = (e) => {
        if (e == 1) {
            props.changeAnimationStatus(e);
        } else {
            props.changeAnimationStatus(video ? 1 : 0);
            setVideo(!video);
        }
    }
    let timeOut = 0;
    const handleReplayAnimation = () => {
        props.changeAnimationStatus(1);
        props.podiumAnimationStatus({
            reply: false,
            reset: false
        });
        if (timeOut) {
            clearTimeout(timeOut);
            timeOut = 0;
        }
        timeOut = setTimeout(() => {
            props.podiumAnimationStatus({
                reply: true,
                reset: true
            });
        }, 500)
    }

    const handleSkipToText = () => {
        socket.emit('broadcast_stage', { player: 4, presentation: 4, id: id })
    }
    const handleText = () => {
        socket.emit('set_text', { action: text, id: id });
        socket.emit('intro_text', { status: text, id: id });
        props.changeAnimationStatus(!text);
        setText(!text);
    }
    const handleTimer = () => {
        socket.emit('set_timer', { action: timer, id: id });
        setTimer(!timer)
        props.changeAnimationStatus(timer ? 1 : 0);
    }

    const handleAnswerAnimation = () => {
        socket.emit('set_correctanswer_animation', { action: answerAnimation, id: id });
        setAnswerAnimation(!answerAnimation)
        props.changeAnimationStatus(!answerAnimation);
    }

    const handleShowAnswer = () => {
        //working on
        socket.emit('set_answer_animation', { action: showAnswer, id: id });
        setShowAnswer(!showAnswer);
        props.changeAnimationStatus(!showAnswer ? 1 : 0);
    }

    const handleRoundAnimation = () => {
        socket.emit('set_round_animation', { action: roundAnimation, id: id });
        setRoundAnimation(!roundAnimation);
        props.changeAnimationStatus(!roundAnimation);
    }

    const handleQuestionAnimation = () => {
        setQuestionAnimation(!questionAnimation)
        props.changeAnimationStatus(!questionAnimation);
        socket.emit('set_question_animation', { action: questionAnimation, id: id });
    }

    const handlePodiumAnimation = () => {
        setPodiumAnimation(!podiumAnimation)
        socket.emit('set_podium_animation', { action: podiumAnimation, id: id });
    }
    switch (varient) {
        case 'normal':
            ActionButton = <Button className="bg-color d-flex justify-content-center align-items-center"><Icons varient='normal' />{translations?.on_click}</Button>
            break;
        case 'sbButton':
            ActionButton = <Button className={`sidebar-btn d-flex justify-content-start align-items-center ${props.className}`} onClick={props.onclick}>{props.children}</Button>
            break;
        case 'fboption':
            ActionButton = <Button className="footer-btn d-flex justify-content-start align-items-center" onClick={props.onclick} onMouseEnter={props.onmouseenter} >{props.children}</Button>
            break;
        case 'mbbuttons':
            ActionButton = <Button className="btn-secondary-custom for-mob-sidebar w-100 d-flex justify-content-start align-items-center" onClick={props.onclick}>{props.children}</Button>
            break;
        case 'controlbutton':
            ActionButton = <ControlButton game={props.game} count={props.count}
                varient={props.varient} video={video} text={text} timer={timer} showAnswer={showAnswer}
                questionAnimation={questionAnimation}
                animationStatus={props.animationStatus}
                actions={{ handleFreezeVideo, handleSkipToText, handleText, handleTimer, handleShowAnswer, handleQuestionAnimation, handlePodiumAnimation, handleReplayAnimation }}
                memorize={props.memorize}
                updateMemorize={props.updateMemorize}
                changeSelection={props.changeSelection}
            />;

            break;
        case 'ctrlaction':
            ActionButton = <ControlButton game={props.game}
                count={props.count} varient={props.varient} video={video} text={text}
                animationStatus={props.animationStatus}
                timer={timer}
                answerAnimation={answerAnimation}
                showAnswer={showAnswer}
                questionAnimation={questionAnimation}
                roundAnimation={roundAnimation}
                memorize={props.memorize}
                actions={{ handleFreezeVideo, handleSkipToText, handleText, handleTimer, handleAnswerAnimation, handleShowAnswer, handleRoundAnimation, handleQuestionAnimation, handlePodiumAnimation, handleReplayAnimation }} />;
            break;

        default:
            ActionButton = <Button className="footer-btn d-flex justify-content-start align-items-center">default</Button>
            break;
    }

    return (
        <div>
            {ActionButton}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game,
        memorize: state.memorize,
        animationStatus: state.animationStatus.animationStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateMemorize: (data) => dispatch(updateMemorize(data)),
        changeSelection: (data) => dispatch(changeSelection(data)),
        changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data)),
        podiumAnimationStatus: (data) => dispatch(podiumAnimationStatus(data))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);


const ControlButton = (props) => {
    let presenterStage = props.count;
    let varient = props.varient;
    const video = props.video;
    let footerButton = '';
    let screenAction = '';
    const animationStatus = props.animationStatus;
    const game = props.game.game ? props.game.game : JSON.parse(localStorage.getItem('game'));
    const translations = game?.translations;
    const id = game ? game.id : 0;
    const getCurrentQuestion = localStorage.getItem('question') ? JSON.parse(localStorage.getItem('question')) : null;
    const { handleFreezeVideo, handleSkipToText, handleText, handleTimer, handleAnswerAnimation, handleShowAnswer, handleQuestionAnimation, handleRoundAnimation, handlePodiumAnimation, handleReplayAnimation } = props.actions;

    function handleStart() {
        socket.emit('broadcast_stage', { player: 3, presentation: 3, id: id })
    }

    function endTimer() {
        handleReplayAnimation()
        socket.emit('broadcast_stage', { player: 6, presentation: 6, id: id })
    }
    function handleShowCorrectAnswer() {
        socket.emit('broadcast_stage', { player: 7, presentation: 7, id: id });
    }

    function handleShowingQuestionLeaderBoard() {
        let { rounds, id } = JSON.parse(localStorage.getItem('game'));
        const { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };
        if (rounds[round_pos].questions.length > question_pos + 1) {
            localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos, question_pos: question_pos + 1 }));
        } else {
            if (rounds.length > round_pos + 1) {
                localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos + 1, question_pos: 0 }));
            } else {
                const data = JSON.parse(localStorage.getItem('winners'));
                let array_list = new Array(data.length).fill('-');
                localStorage.setItem('winners', JSON.stringify(array_list));
                localStorage.setItem('rounds', JSON.stringify({ round_pos: 0, question_pos: 0 }));
            }
        }
        socket.emit('broadcast_stage', { player: 8, presentation: 8, id: id })
    }
    function handleShowingLeaderBoard() {
        socket.emit('broadcast_stage', { player: 9, presentation: 9, id: id })
    }
    function handleSkipToQuestion() {
        socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
    }

    function handleRemoteSkipToQuestion() {
        let { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };
        const formData = new FormData();
        formData.append('game_id', id);
        formData.append('round_no', round_pos + 1);
        formData.append('question_no', question_pos + 1);
        formData.append('rounds_count', game.rounds.length);
        formData.append('questions_count', game.rounds[round_pos].questions.length);
        formData.append('question_id', game.rounds[round_pos].questions[question_pos]);
        formData.append('round_id', game.rounds[round_pos].round);
        performRequest('post', 'api/get-question', formData)
            .then(response => {
                const getTime = response.data.question.time ? response.data.question.time : 20;
                const getTimer = localStorage.getItem('timer') ? JSON.parse(localStorage.getItem('timer')) : { timer: getTime };
                // setDuration(getTime);
                // setEnableButton(false);
                // setIsQuestion(!response.data.question.is_question)
                // localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos, question_pos: question_pos }));
                // props.updateQuestionStore({ round_pos: round_pos, question_pos: question_pos });
                // setQuestionStatus(response.data.nextQuestionStatus)
                socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
                socket.emit('get_question', response.data.question);
                // if (getTimer?.timer == getTime) {
                //     setTimer(getTime);
                //     setFirstQuestion(true);
                // } else {
                //     setFirstQuestion(false);
                //     setTimer(parseInt(getTimer?.timer));
                // }
                // props.addQuestion(response.data.question);
                localStorage.setItem('question', JSON.stringify(response.data.question.question));
                // setQuestion(response.data.question);
                // props.loader(false);
            })
    }
    function handleShowPodium() {
        socket.emit('broadcast_stage', { player: 10, presentation: 10, id: id })
    }
    const handlePause = () => {
        props.changeSelection(11);
    }

    const handleNextQuestion = () => {
        handleReplayAnimation()
        const current = JSON.parse(localStorage.getItem('current'));
        if (current.round_no <= current.rounds_count) {
            if (current.question_no <= current.questions_count) {
                let data = {
                    game_id: current.game_id,
                    round_no: current.round_no,
                    question_no: current.question_no,
                    rounds_count: current.rounds_count,
                    questions_count: current.questions_count
                }
                localStorage.setItem('current', JSON.stringify(data));
            } else {
                let data = {
                    game_id: current.game_id,
                    round_no: current.round_no + 1,
                    question_no: 1,
                    rounds_count: current.rounds_count,
                    questions_count: current.questions_count
                }
                localStorage.setItem('current', JSON.stringify(data));
            }
            socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
        } else {
            let data = {
                game_id: current.game_id,
                round_no: 1,
                question_no: 1,
                rounds_count: current.rounds_count,
                questions_count: current.questions_count
            }
            localStorage.setItem('current', JSON.stringify(data));
        }
    }

    const buttonStyle = "sidebar-btn d-flex justify-content-start align-items-center ";
    const footerButtonClass = window.innerWidth > 768 ? buttonStyle + ' active' : "bg-color d-flex justify-content-center align-items-center w-100 ";
    const isRemote = window.innerWidth > 768;
    const screenActionClass = window.innerWidth > 768 ? buttonStyle : "bg-color d-flex justify-content-center align-items-center btn-secondary-custom w-100";
    switch (parseInt(presenterStage)) {
        case 1:
            footerButton = <Button className={footerButtonClass} onClick={handleStart}><Icons varient='startnow' />{translations?.start_now}</Button>
            screenAction = <Button className={screenActionClass} onClick={isRemote ? handleSkipToQuestion : handleRemoteSkipToQuestion}><Icons varient='skipintro' />{translations?.skip_intro}</Button>
            break;
        case 2:
            footerButton = <Button className={footerButtonClass} onClick={endTimer}><Icons varient='skipintro' />{translations?.skip_to_presentation}</Button>
            screenAction = <Button className={screenActionClass} onClick={endTimer}><Icons varient='pause' />{translations?.freeze_video}</Button>
            break;
        case 3:
            footerButton = <Button className={footerButtonClass} onClick={handleSkipToText}><Icons varient='skipintro' />{translations?.skip_to_presentation}</Button>
            screenAction = animationStatus == 2 ? <Button className={screenActionClass} onClick={() => handleFreezeVideo(1)}><Icons varient='faUndo' color="#aa6600" />{translations?.replay_video} </Button> : <Button className={screenActionClass} onClick={handleFreezeVideo}>{animationStatus ? <><Icons varient='pause' />{translations?.freeze_video}</> : <><Icons varient='startnow' />{translations?.play_video}</>}</Button>
            break;
        case 4:
            footerButton = <Button className={footerButtonClass} onClick={isRemote ? handleSkipToQuestion : handleRemoteSkipToQuestion}><Icons varient='skipintro' />{translations?.skip_to_question}</Button>
            screenAction = <Button className={screenActionClass} onClick={handleText}>{!animationStatus ? <><Icons varient='pause' />{translations?.freeze_text_scroll}</> : <><Icons varient='startnow' />{translations?.unfreeze_text_scroll}</>}</Button>
            break;
        case 5:
            footerButton = !getCurrentQuestion?.is_active ? <Button className={footerButtonClass} onClick={endTimer}><Icons varient='faHourglassHalf' color="#aa6600" /> {translations?.end_timer}</Button> : ''
            screenAction = !getCurrentQuestion?.is_active ? <Button className={screenActionClass} onClick={handleTimer} >{animationStatus ? <><Icons varient='pause' /> {translations?.freeze_timer}</> : <><Icons varient='startnow' /> {translations?.unfreeze_timer}</>}</Button> : ''
            break;
        case 6:
            footerButton = <Button className={footerButtonClass} onClick={handleShowCorrectAnswer}><Icons varient='star' />{translations?.show_correct_answer} </Button>
            screenAction = animationStatus == 2 ? <Button className={screenActionClass} onClick={handleReplayAnimation}><Icons varient='faUndo' color="#aa6600" />{translations?.replay_animation}</Button> : <Button className={screenActionClass} onClick={handleShowAnswer}>{animationStatus ? <><Icons varient='startnow' />{translations?.play_animation}</> : <><Icons varient='pause' />{translations?.pause_animation}</>}</Button>
            break;
        case 7:
            footerButton = <Button className={footerButtonClass} onClick={handleShowingQuestionLeaderBoard}><Icons varient='chart' />{translations?.show_question_leaderboard}</Button>
            screenAction = animationStatus == 2 ? <Button className={screenActionClass} onClick={handleReplayAnimation}><Icons varient='faUndo' color="#aa6600" />{translations?.replay_animation}</Button> : <Button className={screenActionClass} onClick={handleAnswerAnimation}>{animationStatus ? <><Icons varient='startnow' />{translations?.play_animation}</> : <><Icons varient='pause' />{translations?.pause_animation}</>}</Button>
            break;
        case 8:
            footerButton = checkIfRoundEnd() ? <Button className={footerButtonClass} onClick={isRemote ? handleNextQuestion : handleRemoteSkipToQuestion}><Icons varient='startnow' />{translations?.next_question}</Button> : <Button className={footerButtonClass} onClick={handleShowingLeaderBoard}><Icons varient='startnow' />{translations?.show_round_leaderboard}</Button>
            screenAction = animationStatus == 2 ? <Button className={screenActionClass} onClick={handleReplayAnimation}> <><Icons varient='faUndo' color="#aa6600" />{translations?.replay_animation}</></Button> : <Button className={screenActionClass} onClick={handleQuestionAnimation}>{!animationStatus ? <><Icons varient='pause' />{translations?.pause_animation}</> : <><Icons varient='startnow' />{translations?.start_animation}</>}</Button>
            break;
        //vvv
        case 9:
            footerButton = checkIfAllRoundsEnd() ? <Button className={`${footerButtonClass} podium-button` } onClick={isRemote ? handleNextQuestion : handleRemoteSkipToQuestion}><Icons varient='startnow' />{translations?.next_round}</Button> : <Button className={`${footerButtonClass} podium-button`} onClick={handleShowPodium}><Icons varient='faTrophy' color="#aa6600" />{translations?.show_podium}</Button>
            screenAction = animationStatus == 2 ? <Button className={screenActionClass} onClick={handleReplayAnimation}> <><Icons varient='faUndo' color="#aa6600" /> {translations?.replay_animation}</></Button> : <Button className={screenActionClass} onClick={handleRoundAnimation}>{animationStatus ? <><Icons varient='startnow' /> {translations?.play_animation}</> : <><Icons varient='pause' /> {translations?.pause_animation}</>}</Button>
            break;
        case 10:
            footerButton = <Button className={footerButtonClass} onClick={handleReplayAnimation} ><Icons varient='faUndo' color="#aa6600" />{translations?.replay_animation}</Button>
            // screenAction = <Button className={footerButtonClass} onClick={handlePodiumAnimation}><Icons varient='pause' />Pause animation</Button>
            screenAction = ''
            break;
        case 12:
            // footerButton = <Button className={footerButtonClass} onClick={handlePause} ><Icons varient='normal' />Pause</Button>
            // screenAction = <Button className={screenActionClass} ><Icons varient='normal' />Skip info</Button>
            footerButton = ''
            screenAction = ''
            break;
        default:
            footerButton = ''
            screenAction = ''
            break;
    }

    return varient == 'controlbutton' ? footerButton : varient == 'ctrlaction' ? screenAction : footerButton;
}

function checkIfAllRoundsEnd() {
    const winners = JSON.parse(localStorage.getItem('winners'));
    if (winners[winners.length - 1] != "") {
        return false;
    }
    return true;
}


function checkIfRoundEnd() {

    const questionData = JSON.parse(localStorage.getItem('question'));

    if (localStorage.getItem('rounds')) {
        if (!questionData.isLast) {
            return true;
        }
    }


    return false;
}

