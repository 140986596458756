import React, { useEffect, useState, useContext, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./playerstart.scss";
import Footer from "../components/Footer/index";
import TeamDetails from "../components/TeamDetails";
import { updateTeam } from "../../../../redux/Teams/team.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import Navbar from "../components/Header/index";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { addUser } from "../../../../redux/User/user.actions";
import { addGame } from "../../../../redux/Game/game.actions";
import {
  getQuestion,
  updateQuestion,
} from "../../../../redux/Questions/question.actions";
function RoundStart(props) {
  const { id, player ,translations} = JSON.parse(localStorage.getItem("game"));
  const dispatch = useDispatch();
  const { skin } = JSON.parse(localStorage.getItem("game"));
  const { gameQuestion } = useSelector((state) => state?.question);
  const getQuestionById = React.useCallback(async () => {
    const res = await dispatch(getQuestion({ id: id, user_id: player?.id }));
    if (res?.success) {
    }
  }, [dispatch]);

  const handleExit = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "game/" + id);
  };
  useEffect(() => {
    localStorage.setItem("playerStage", 16);
    getQuestionById(id);
  }, []);

  const handleContinue = () => {
    props.changePlayerStage(5);
  };

  const { color } = JSON.parse(
    localStorage.getItem("profile") ?? '{"color": "transparent"}'
  );

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${r},${g},${b}`;
  };

  const hex2rgbaAlt = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `${Math.round(r * 0.72)},${Math.round(g * 0.75)},${Math.round(
      b * 0.85
    )}`;
  };

  return (
    <div className="player-questions">
      <Navbar varient="contentheadershortplayerstart" />

      <div className="main-content">
        <div className="pq-whole-section pb-0 player-result-view">
          <div className="round-start flex-center flex-column gap-3">
            <img
              src={`../../assets/skin/${
                skin?.game_skin_name ?? "astro"
              }/logo.png`}
              alt="Game logo"
            />

            <div className="btn-group-player mt-3 flex-center flex-column gap-3 w-100 px-sm-3">
              <button
                class="btn btn-primary dynamic-btn px-5 d-flex justify-content-center align-items-center w-100"
                onClick={handleContinue}
                style={{
                  backgroundImage: `linear-gradient(225deg, rgb(${hex2rgba(
                    color
                  )}) 0%, rgb(${hex2rgbaAlt(color)}) 100%)`,
                  "--element-color": hex2rgba(color),
                }}
              >
                <i class="fa-solid fa-play me-2"></i>{translations?.start_round} {gameQuestion?.question?.round_order_no}
              </button>
              <button
                class="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center w-100 text-white"
                onClick={handleExit}
                style={{ borderColor: `rgb(${hex2rgba(color)})` }}
              >
                <i class="fa-solid fa-right-from-bracket me-2"></i>{translations?.exit_game}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    game: state.game.game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    loader: (data) => dispatch(loader(data)),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addUser: (data) => dispatch(addUser(data)),
    addGame: (data) => dispatch(addGame(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoundStart);
