import { LOADER } from '../../constants/actionTypes';

export const loader = (data) => {
    return {
        type: LOADER,
        data: data
    };
};


