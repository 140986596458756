import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./welcome.scss";
import Navbar from "../components/Header/index";
import Sidebar from "../components/Sidebar/index";
import Footer from "../components/Footer/index";
import GameButton from "../components/Button/index";
import TeamDetails from "../components/TeamDetails";


import logo from "../components/assets/images/team_logo.svg";
import { socket } from "../../../../context/socket";
import { broadCast } from "../../../../helper";
import { connect } from "react-redux";

import {
    toggleOption
}
    from "../../../../redux/Option/option.actions"


function PlayerPause(props) {
    const { id, skin,translations } = JSON.parse(localStorage.getItem('game'));
    const currentSkin = props.game ? props.game?.game?.skin?.game_skin_name : skin?.game_skin_name;

    return (
        <div className="presentation-controller-main position-relative" >
            {/* <div className="navbar-section">
            </div> */}
            {/* <Navbar varient="contentheadersinglemenu" /> */}
            <Navbar varient="contentheadershortplayer" />


            <div>
                {props.optionToggle && <Sidebar varient="normal" />}
            </div>
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <img className="paused-logo" src={`../../assets/skin/${currentSkin}/logo.png`} alt="" />
                <div className="game-paused-text-section mt-4">
                    <h2 className="mb-0">{translations?.game_paused}</h2>
                </div>

            </div>
            <TeamDetails />

            <Footer varient="footercontentplayer" />

        </div>
    );
}

const mapStateToProps = state => {
    return {
        optionToggle: state.optionToggle.optionToggle,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPause)


