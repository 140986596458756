import { INCREMENT, DECREMENT, PRESENTATIONSTAGE } from './counter.types';
const INITIAL_STATE = {
  count: 1,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCREMENT:
      return {
        ...state, count: state.count + 1,
      };

    case DECREMENT:
      return {
        ...state, count: action.data,
      };
    case PRESENTATIONSTAGE:
        console.log(action.data,'PRESENTATIONSTAGE');
      localStorage.setItem('stage',action.data);
      return {
        ...state, count: action.data,
      };


    default: return state;
  }
};

export default reducer;