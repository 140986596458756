import React, { useEffect, useState, useContext, useRef } from "react";
import { connect } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./playerstart.scss";
import { SocketContext } from "../../../../context/socket";
import { performRequest } from "../../../../api/apiHandler";

import Icons from "../components/Icons/index";
import { updateTeam } from "../../../../redux/Teams/team.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import Navbar from "../components/Header/index";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { addUser } from "../../../../redux/User/user.actions";
import { addGame } from "../../../../redux/Game/game.actions";
import { useLocation } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";

function PlayerStart(props) {
  const socket = useContext(SocketContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [error, setError] = useState(null);
  const [teamError, setTeamError] = useState(null);
  const [team, setTeam] = useState("");
  const [name, setName] = useState("");
  const gameData = JSON.parse(localStorage.getItem("game"));
  const translations = gameData?.translations;
  const teamMaxSize = props.game ? props.game.team_max_size : 25;
  const [disable, setDisable] = useState(false);
  const apiToken = localStorage.getItem("accessToken");
  const [teamId, setTeamId] = useState(0);
  const button = useRef();
  const localStorageGame = JSON.parse(localStorage.getItem("game"));
  const skin = localStorageGame
    ? localStorageGame.skin?.game_skin_name
    : props.game.skin.game_skin_name;

  const handleSubmit = () => {
    setError("");
    setTeamError("");

    if (name) {
      const gameId = gameData.id;
      const formData = new FormData();
      const id = queryParams.get('id');
      if(id){
          formData.append("id", id);
      }
      formData.append("name", name);
      formData.append("game_id", gameId);
      formData.append("team_id", team);
      formData.append("created_by", team);
      formData.append("api_token", apiToken);
      if (!team) {
        setTeamError("Please select a team.");
        return;
      }
      setDisable(true);
      performRequest("post", "api/add-player", formData, true)
        .then((response) => {
          setDisable(false);
          const getData = response.data;
          if (getData.success) {
            const { is_live } = JSON.parse(localStorage.getItem("game"));

            const propGame = props.game;
            propGame["player_color"] = props.teams.find((e) => e.id == team)
              ? props.teams.find((e) => e.id == team).color.replace("#", "")
              : "";
            props.addGame(propGame);
            localStorage.setItem("game", JSON.stringify(propGame));
            localStorage.setItem("playerStage", 11);
            const team_members = getData.team_members;
            const data = {
              id: getData.data.id,
              name: name,
              team: team,
              game_id: getData.data?.game_id,
              color: getData.data.color,
            };
            // props.updateTeam(team, team_members);
            props.addUser({
              id: getData.data.id,
              name: name,
              team: team,
            });
            localStorage.setItem("accessToken", getData.token);
            localStorage.setItem("profile", JSON.stringify(data));
            socket.emit("add_team", {
              team_id: team,
              game_id: getData.data?.game_id,
              // players: team_members,
              players: getData.data,
            });
            if (localStorageGame.is_live) {
              props.changePlayerStage(11);
            } else {
              props.changePlayerStage(3);
            }

            // if user is self paced then move to next page
            // otherwise stay in current flow
          } else {
            setError(getData.message);
          }
        })
        .catch((error) => {
          let errors = error.response.data;
          if ((errors.data = 208)) {
            setError(errors.message);
          }
        });
    } else {
      setError("Name field is required.");
    }
  };
  const handleSelect = (select) => {
    setTeamId(select.id);
    setTeam(select.id);
  };

  const handleButtonSelect = (id) => {
    setTeam(id);
  };

  useEffect(() => {
    if (props.user) {
      setTeamId(props?.teams[0]?.id);
      setName(props?.user?.name);
    }
  }, [props]);
  return (
    <div className="user-registration presentation-player-start player-invitation d-flex flex-column justify-content-md-center align-items-center">
      <Navbar varient="contentheadershortplayerstart" />
      <div className="col-lg-6 col-xl-6 d-lg-none d-block mb-4">
        <div className="presentation-content d-flex flex-column justify-content-center align-items-center position-relative h-100">
          <h4>Welcome to</h4>
          <img
            alt="Skin Logo"
            className="skin-logo"
            src={`../../assets/skin/${skin}/logo.png`}
          />
        </div>
      </div>
      <div className="signup col-12">
        <div className="row m-0">
          <div className="signup-form text-start col-lg-6 col-xl-6 col-md-12 p-4">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
              <div className="custom-form-field-with-validation position-relative">
                <label className="ps-4 mb-2">
                  {translations?.enter_your_name}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your name"
                />
                {error && (
                  <>
                    <div
                      className={`validation-line  position-absolute ${
                        error ? `error` : `success`
                      }`}
                    ></div>
                    <div className="validation-icon error position-absolute">
                      <Icons varient="warning" />
                    </div>
                  </>
                )}
              </div>
            </div>
            {error && (
              <div className="col-12">
                <div
                  className="alert alert-warning mt-2 mb-0 text-start"
                  role="alert"
                >
                  {error}
                </div>
              </div>
            )}
            <label className="ps-4 mb-2 mt-4">
              {translations?.select_your_team}
            </label>
            <div className="mob-drop d-block d-sm-none mb-3">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn btn-secondary mb-3 text-start py-0 d-flex align-items-center w-100 mt-0"
                >
                  <div
                    className={`${teamId && "team-icon-sec "}player-start me-2`}
                    style={
                      teamId
                        ? {
                            backgroundColor: `${
                              props.teams.find((e) => e.id == teamId)
                                ? props.teams.find((e) => e.id == teamId).color
                                : ""
                            }`,
                          }
                        : { height: "34px", color: "#91877E" }
                    }
                  >
                    {teamId ? (
                      <Icons
                        varient={
                          props.teams.find((e) => e.id == teamId)
                            ? props.teams.find((e) => e.id == teamId).icon
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="custom-dropdown-text-content"
                    style={
                      teamId ? { backgroundColor: "" } : { color: "#91877E" }
                    }
                  >
                    {teamId
                      ? props.teams.find((e) => e.id == teamId)
                        ? props.teams.find((e) => e.id == teamId).name
                        : ""
                      : "Select team"}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.teams.map((team) => {
                    let maximum = teamMaxSize ? teamMaxSize : 25;
                    let countPlayer = team.players.length;
                    return (
                      <Dropdown.Item
                        key={team.id}
                        disabled={!(countPlayer < maximum)}
                      >
                        <div
                          className="d-flex align-items-center"
                          onClick={() => handleSelect(team)}
                        >
                          <div
                            className="player-start team-icon-sec me-2 "
                            style={{ backgroundColor: `${team.color}` }}
                          >
                            <Icons varient={team.icon} />
                          </div>
                          <p className="mb-0">{team.name}</p>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="row d-sm-flex d-none">
              {props.teams.map((eachTeam) => {
                let maximum = teamMaxSize ? teamMaxSize : 25;
                let countPlayer = eachTeam.players.length;
                return (
                  <div
                    key={eachTeam.id}
                    className="col-lg-6 col-md-12 col-sm-12 col-12"
                  >
                    <button
                      ref={button}
                      onClick={() => handleButtonSelect(eachTeam.id)}
                      className={
                        countPlayer < maximum
                          ? `btn w-100 btn-secondary mb-3 text-start py-0 d-flex align-items-center ${
                              team === eachTeam.id && "team-sel-active"
                            }`
                          : "btn btn-secondary mb-3 text-start py-0 d-flex align-items-center disabled"
                      }
                    >
                      <div
                        className="team-icon-sec me-2"
                        style={{
                          backgroundColor: `${
                            countPlayer < maximum ? eachTeam.color : "white"
                          }`,
                        }}
                      >
                        <Icons
                          varient={eachTeam.icon}
                          color={countPlayer < maximum ? null : eachTeam.color}
                        />
                      </div>
                      <div className="custom-dropdown-text-content">
                        {eachTeam.name}
                      </div>
                    </button>
                  </div>
                );
              })}
              <small style={{ color: "red" }}>{teamError}</small>
            </div>

            <button
              className={`btn btn-primary d-flex justify-content-center align-items-center ${
                disable ? `disabled` : ``
              }`}
              onClick={handleSubmit}
            >
              <Icons varient="check" />
              {translations?.continue}
            </button>
          </div>
          <div className="signup-image presentation-start-game-img col-lg-6 col-xl-6 d-lg-block d-none">
            <div className="presentation-content d-flex flex-column justify-content-center align-items-center position-relative h-100">
              <h4>{translations?.welcome_to}</h4>
              <img alt="Skin Logo" src={`../../assets/skin/${skin}/logo.png`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    game: state.game.game,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    loader: (data) => dispatch(loader(data)),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addUser: (data) => dispatch(addUser(data)),
    addGame: (data) => dispatch(addGame(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStart);
