export const colors = ['first', 'second', 'third', 'fourth'];
export const options = ['A', 'B', 'C', 'D'];
export const graph = ['first-question', 'second-question', 'third-question', 'fourth-question'];
export const gameStatus = ['Game status', 'Game paused', 'Game intro', 'Question', 'End of Round', 'Podium'];
export const displayText = ['View description',
    'Game starts at',
    'Teams with their members',
    'The Game Staus so far',
    'Waiting on your sign off',
    'Introduction video',
    'Presentation text',
    'Players are answering',
    'Answering stopped',
    'Question leaderboard animation',
    'Round leaderboard animation',
    'Final leaderboard animation']
