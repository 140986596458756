import React from 'react';
import './url_expire.scss';
import Loader from '../assets/logo.gif';
export default function UrlExpire(props) {
    
    return (
        <>
            <div className="full-section">
                <div>
                    <div className="d-flex align-items-center justify-content-center error-design">
                        <h1>4</h1>
                        <img src={Loader} alt="" />
                        <h1>4</h1>
                    </div>
                    <p>OOPS! Link Expired!</p>
                </div>
            </div>
        </>
    )
}


