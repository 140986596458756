import React, { useEffect, useState, useRef } from "react";

import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import { connect, useSelector } from "react-redux";
import { socket } from "../../../../context/socket";
import { addQuestion } from "../../../../redux/Questions/question.actions";
import { colors, options } from "../../../../constant";
import "bootstrap/dist/css/bootstrap.min.css";
import "./presentationquestion.scss";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import Loader from "../components/Loader";
import { toggleAudio } from "../../../../redux/Audio/audio.actions";

import Timer from "./Timer";
import { toggleOption } from "../../../../redux/Option/option.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import { updateQuestionStore } from "../../../../redux/QuestionStore/questionStore.actions";
import { changeAnimationStatus } from "../../../../redux/Animations/animation.actions";

import logo from "../components/assets/images/logo.gif";
import { performRequest } from "../../../../api/apiHandler";
let players = [];
function PresentationQuestion(props) {
  const question = useSelector((state) => state.question?.question?.question);
  const teams = useSelector((state) => state.team.teams);
  const { id, rounds, track, translations } = JSON.parse(
    localStorage.getItem("game")
  );
  const [isTimer, setIsTimer] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const current = JSON.parse(localStorage.getItem("current"));
  const [loader, setLoader] = useState(true);
  const [duration, setDuration] = useState(10);
  const [totalPlayer, setTotalPlayer] = useState(0);
  const [timer, setTimer] = useState(duration);
  const [enablebutton, setEnableButton] = useState(true);
  const [answeredPlayers, setAnsweredPlayers] = useState([]);
  const [isTimerVisible, setTimerVisible] = useState(true);
  const [isFirstQuestion, setFirstQuestion] = useState(false);
  const [getQuestion, setQuestion] = useState(null);
  const [questionStatus, setQuestionStatus] = useState("");
  const { round_pos, question_pos } = localStorage.getItem("rounds")
    ? JSON.parse(localStorage.getItem("rounds"))
    : { round_pos: 0, question_pos: 0 };
  const [audio] = useState(new Audio(track?.file));
  const [isQuestion, setIsQuestion] = useState(true);

  const refer = useRef(false);
  function checkValueExist(round, question_id) {
    let round_list = JSON.parse(localStorage.getItem("round_list"));
    if (round_list) {
      if (round_list[round] == undefined) {
        let current_round_list = round_list;
        current_round_list[round] = [question_id];
        return current_round_list;
      } else {
        let current_round_list = round_list;
        if (!current_round_list[round].find((e) => e == question_id)) {
          current_round_list[round].push(question_id);
        }
        return current_round_list;
      }
    } else {
      let current_round_list = {
        [round]: [question_id],
      };
      return current_round_list;
    }
  }

  function endTimer() {
    const formData = new FormData();
    formData.append("question_id", question?.id);
    formData.append("game_id", id);
    setLoading(true);
    performRequest("post", "/api/end-question", formData)
      .then((response) => {
        setLoading(false);
        props.loader(true);
        setAnsweredPlayers([]);
        players = [];
        localStorage.removeItem("timer");
        socket.emit("broadcast_stage", { player: 6, presentation: 6, id: id });
        localStorage.removeItem("answered");
        props.toggleAudio(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const nextSlide = () => {
    ///// only for testing purp
    if (questionStatus == "Show Podium") {
      socket.emit("broadcast_stage", { player: 10, presentation: 10, id: id });
    }
    if (questionStatus == "Show Round Leaderboard") {
      if (rounds[round_pos].questions.length > question_pos + 1) {
        socket.emit("broadcast_stage", { player: 5, presentation: 5, id: id });
        localStorage.setItem(
          "rounds",
          JSON.stringify({
            round_pos: round_pos,
            question_pos: question_pos + 1,
          })
        );
      } else {
        if (rounds.length > round_pos + 1) {
          localStorage.setItem(
            "rounds",
            JSON.stringify({ round_pos: round_pos + 1, question_pos: 0 })
          );
        }
        socket.emit("broadcast_stage", { player: 9, presentation: 9, id: id });
      }
      socket.emit("broadcast_stage", { player: 9, presentation: 9, id: id });
    }
    // check questin has next question
    if (questionStatus == "Next Slide") {
      localStorage.removeItem("answered");
      setAnsweredPlayers([]);
      players = [];
      localStorage.removeItem("timer");
      socket.emit("broadcast_stage", { player: 6, presentation: 6, id: id });
    }

    // check question has previous question
  };

  function endIsSetTimer() {
    setIsTimer(!isTimer);
    props.changeAnimationStatus(isTimer ? 0 : 1);
    // props.toggleAudio(!isTimer);
  }
  const resetAnswerdPlayer = (e) => {
    setAnsweredPlayers([]);
    players = [];
  };


  useEffect(() => {
    setIsTimer(props.animationStatus);
  }, [props.animationStatus]);

  const imageLoaded = () => {
    setLoader(false);
  };

  useEffect(() => {
    const totalPlayer = teams.reduce(
      (total, team) => total + team.players.length,
      0
    );
    props.toggleAudio(true);
    let { round_pos, question_pos } = localStorage.getItem("rounds")
      ? JSON.parse(localStorage.getItem("rounds"))
      : { round_pos: 0, question_pos: 0 };
    if (!(round_pos || question_pos)) {
      setFirstQuestion(true);
    }
    props.changeAnimationStatus(1);
    if (localStorage.getItem("pauseTimes")) {
      if (localStorage.getItem("pauseTimes") == 0) {
        props.changeAnimationStatus(0);
      }
      localStorage.removeItem("pauseTimes");
    }
    setTotalPlayer(totalPlayer);
    const getTimer = localStorage.getItem("timer")
      ? JSON.parse(localStorage.getItem("timer"))
      : { timer: duration };
    // if (getTimer)
    setTimer(getTimer.timer);
    const answered = JSON.parse(localStorage.getItem("answered"));

    if (answered) setAnsweredPlayers(answered);

    props.loader(true);
    socket.on("get_answered", (arg) => {
      if (!answeredPlayers.includes(arg)) {
        players.push(arg);
        const uniqueObjArray = [
          ...new Map(
            players.map((item) => [item["player_id"]["id"], item])
          ).values(),
        ];
        setAnsweredPlayers(uniqueObjArray);
        localStorage.setItem("answered", JSON.stringify(uniqueObjArray));
      }
    });
    socket.on("get_timer", (arg) => {
      setIsTimer(arg);
    });
    getTeamScore();

    const keyDownHandler = (event) => {
      if (event.key == "ArrowRight") {
        endTimer();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      refer.current = false;
      props.toggleAudio(false);
      socket.off("set_question");
    }; // cleanup toggles value, if unmounted
  }, []);

  useEffect(() => {
    //play audio
    audio.src = track?.file;
    if (props.audioToggle == true) {
      audio.loop = true;
      audio.play();
    } else if (props.audioToggle == false) {
      audio.pause();
    }
    //end play audio
  }, [props.audioToggle]);

  useEffect(() => {
    if (socket.connected) {
      socket.on("set_question", (arg, callback) => {
        props.loader(false);
        setEnableButton(false);
        props.addQuestion(arg);
        localStorage.setItem("question", JSON.stringify(arg.question));
        let numberOfQuestions = arg.get_questions_by_round_id_count;
        const round_id = arg.question.round_id;
        const question_id = arg.question.id;
        if (refer.current) {
          setTimerVisible(!arg.question.is_active);
          const timer = JSON.parse(localStorage.getItem("timer"));
          if (timer) {
            if (timer.question_id == question_id) setTimer(timer.timer);
            else setTimer(arg.question.time ? arg.question.time : 20);
          } else {
            setTimer(arg.question.time ? arg.question.time : 20);
          }
          setDuration(arg.question.time ? arg.question.time : 20);
        }
        let data = {
          game_id: current.game_id,
          round_no: current.round_no,
          question_no: current.question_no + 1,
          rounds_count: current.rounds_count,
          questions_count: numberOfQuestions,
          question_id: question_id,
          round_id: round_id,
        };
        localStorage.setItem("current", JSON.stringify(data));
        localStorage.setItem(
          "round_list",
          JSON.stringify(checkValueExist(round_id, question_id))
        );
        // setAnswered(arg.game_current_question_count);
      });
    }
  }, [socket]);

  function getTeamScore() {
    let { round_pos, question_pos } = localStorage.getItem("rounds")
      ? JSON.parse(localStorage.getItem("rounds"))
      : { round_pos: 0, question_pos: 0 };
    const formData = new FormData();
    formData.append("game_id", id);
    formData.append("round_no", round_pos + 1);
    formData.append("question_no", question_pos + 1);
    formData.append("rounds_count", rounds.length);
    formData.append("questions_count", rounds[round_pos].questions.length);
    formData.append("question_id", rounds[round_pos].questions[question_pos]);
    formData.append("round_id", rounds[round_pos].round);
    refer.current = true;
    performRequest("post", "api/get-question", formData).then((response) => {
      const getTime = response.data.question.time
        ? response.data.question.time
        : 20;
      const getTimer = localStorage.getItem("timer")
        ? JSON.parse(localStorage.getItem("timer"))
        : { timer: getTime };
      setDuration(getTime);
      setEnableButton(false);
      setIsQuestion(!response.data.question.is_question);
      localStorage.setItem(
        "rounds",
        JSON.stringify({ round_pos: round_pos, question_pos: question_pos })
      );
      props.updateQuestionStore({
        round_pos: round_pos,
        question_pos: question_pos,
      });
      setQuestionStatus(response.data.nextQuestionStatus);
      //set questionStore here
      socket.emit("broadcast_stage", { player: 5, id: id });
      socket.emit("get_question", response.data.question);

      // if ((round_pos || question_pos)) {
      // socket.emit('get_question', response.data.question);
      // } else {
      if (getTimer?.timer == getTime) {
        setTimer(getTime);
        setFirstQuestion(true);
      } else {
        setFirstQuestion(false);
        setTimer(parseInt(getTimer?.timer));
      }
      props.addQuestion(response.data.question);
      setQuestion(response.data.question);
      props.loader(false);
      // }
    });
  }

  return (
    <>
      {props.isLoader.isLoader && <Loader />}
      {question ? (
        <div className="presentation-question position-relative">
          <div className="navbar-section">
            <Navbar varient="normal" />
          </div>
          <Navbar varient="contentheadershort" />
          <div className="main-content">
            <div className="pq-whole-section">
              <div className="pq-head text-center mt-3">
                <span
                  dangerouslySetInnerHTML={{
                    __html: question ? question.description : "?",
                  }}
                ></span>
              </div>
              <div className="quest-img-loader-sec col-12 my-3">
                <div className="d-flex justify-content-center align-items-center gap-5">
                  <div className="time-left-loader">
                    {isTimerVisible && (
                      <>
                        {translations?.time_left}
                        <Timer
                          player={players}
                          time={duration}
                          isTimer={isTimer}
                          duration={duration}
                          timer={timer}
                          setTimer={setTimer}
                          question_id={question.id}
                          resetAnswerd={resetAnswerdPlayer}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={`quest-img position-relative ${
                      !question.image_path && "no-img"
                    }`}
                  >
                    <div className="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-item-center">
                      <img
                        src={logo}
                        alt="loader"
                        className="w-25"
                        onLoad={imageLoaded}
                        style={{ display: loader ? "" : "none" }}
                      />
                    </div>
                    {!props.isLoader.isLoader && question.image_path && (
                      <img
                        src={question.image_path}
                        alt=""
                        className="img-fluid"
                        onLoad={imageLoaded}
                        style={{ display: loader ? "none" : "" }}
                      />
                    )}
                  </div>
                  <div className="answered-section">
                    {isTimerVisible && (
                      <>
                        {translations?.answered}
                        <div className="position-relative">
                          <div
                            className="mt-2 progress-circle"
                            data-progress={
                              totalPlayer
                                ? parseInt(
                                    (answeredPlayers.length / totalPlayer) * 100
                                  )
                                : 0
                            }
                          >
                            <div className="progress-circle__mask progress-circle__mask--full">
                              <div className="progress-circle__fill"></div>
                            </div>
                            <div className="progress-circle__mask">
                              <div className="progress-circle__fill"></div>
                            </div>
                          </div>
                          <div className="answered-data d-flex flex-column align-items-center justify-content-center">
                            <div className="answered">
                              {answeredPlayers.length >= totalPlayer
                                ? totalPlayer
                                : answeredPlayers.length}
                            </div>
                            <div className="line-seperate"></div>
                            <div className="total-answer">{totalPlayer}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="answer-option-section col-12 mb-5">
                <div className="row">
                  {isQuestion ? (
                    question["get_answers_by_question_id"] ? (
                      question["get_answers_by_question_id"].map(function (
                        e,
                        index
                      ) {
                        return (
                          <div key={e.id} className="col-6 my-2">
                            <div className={"answer-option " + colors[index]}>
                              <div className="col-12">
                                <div className="row mx-0">
                                  <div className="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 p-0">
                                    <div className="option-count d-flex align-items-center justify-content-center w-100 h-100">
                                      {options[index]}
                                    </div>
                                  </div>
                                  <div className="col-xl-11 col-lg-10 col-md-10 col-sm-10 col-9 p-0">
                                    <div
                                      className="option-detail text-start"
                                      dangerouslySetInnerHTML={{
                                        __html: e.name,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h1 style={{ color: "white" }}>
                        {translations?.no_questions}
                      </h1>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer varient="footercontentpresentation" />
          <div className="footer-section">
            <Footer
              varient="normal"
              left={
                <GameButton
                  varient="fboption"
                  onmouseenter={props.toggleOption}
                >
                  <Icons varient="option" />
                  {translations?.options}
                </GameButton>
              }
              centerOne={
                isTimerVisible ? (
                  <GameButton
                    className={enablebutton ? "disabled me-2" : "me-2"}
                    varient="sbButton"
                    onclick={isLoading ? undefined : endTimer}
                  >
                    <Icons varient="timer" />
                    {isLoading ? "Loading..." : translations?.end_timer}
                  </GameButton>
                ) : (
                  <GameButton
                    className={enablebutton ? "disabled me-2" : "me-2"}
                    varient="sbButton"
                    onclick={nextSlide}
                  >
                    <Icons varient="skipintro" />
                    {questionStatus}
                  </GameButton>
                )
              }
              centerTwo={
                isTimerVisible ? (
                  <GameButton
                    className="me-2"
                    varient="sbButton"
                    onclick={endIsSetTimer}
                  >
                    {props.animationStatus == 1 ? (
                      <>
                        <Icons varient="pause" />
                        {translations?.freeze_timer}
                      </>
                    ) : (
                      <>
                        <Icons varient="startnow" />
                        {translations?.unfreeze_timer}
                      </>
                    )}
                  </GameButton>
                ) : (
                  ""
                )
              }
            />
          </div>
        </div>
      ) : (
        <div style={{ color: "white" }}></div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isLoader: state.isLoader,
    animationStatus: state.animationStatus.animationStatus,
    currentQuestion: state.questionStore.currentQuestion,
    audioToggle: state.audioToggle.audioToggle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addQuestion: (data) => dispatch(addQuestion(data)),
    toggleOption: () => dispatch(toggleOption()),
    loader: (data) => dispatch(loader(data)),
    toggleAudio: (data) => dispatch(toggleAudio(data)),
    updateQuestionStore: (data) => dispatch(updateQuestionStore(data)),
    changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentationQuestion);
