import React, { useEffect, useState, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../components/Footer/index";
import { performRequest } from "../../../../api/apiHandler";


export default function CorrectAnswer(props) {
    useEffect(() => {

        performRequest('get', 'api/player_score/')
        .then(response => {            
        })
        .catch(error => {
        })
    }, []);
    return (
        <div className="presentation-controller-main position-relative" style={{ color: 'white' }}>
            <div className="navbar-section">
                PLAYERS
            </div>
            <div className="main-content position-absolute d-flex flex-column justify-content-center align-items-center">
                <h1>Correct Answer</h1>
                <button>Answer is: </button>

            </div>
            <div className="footer-section">
                <Footer />
            </div>
        </div>
    );
}


