import { OPTIONTOGGLE } from '../../constants/actionTypes';
const INITIAL_STATE = {
  optionToggle: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPTIONTOGGLE:
      return {
        ...state, optionToggle: !state.optionToggle,
      };
    default: return state;
  }
};

export default reducer;