import React, { useEffect, useState } from 'react';
import './qrcode.scss';
import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import { connect } from "react-redux"
import { toggleOption } from "../../../../redux/Option/option.actions"
import { changeSelection } from '../../../../redux/Selection/selection.actions';
import {toggleQrCode}from '../../../../redux/QrCode/qrcode.actions';

import { socket } from "../../../../context/socket";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import { gameControls } from "../../../../constant";




function Circuit(props) {
    const { game_qr_code_file, id ,translations} = JSON.parse(localStorage.getItem('game'));
    function handleStart() {
        socket.emit('broadcast_stage', { player: 3, presentation: 3, id: id })
    }
    useEffect(()=>{
        props.toggleQrCode(true);
        return () => {
            props.toggleQrCode(false);
        }
    },[])

    function handleHide() {
       
        if (props.qrcodeToggle) {
            props.toggleQrCode(false);

            socket.emit('broadcast_stage', { presentation: localStorage.getItem('qr_memorized'), id: id });
        } else {
            props.toggleQrCode(!props.qrcodeToggle);
        }
    }

    return (
        <div className='presentation-question position-relative'>
            <div className="navbar-section">
                <Navbar varient="normal" />
            </div>
            <Navbar varient="contentheadershort" />
            <div className="main-content presentation-qr-code d-flex justify-content-center align-items-center">
                {props.qrcodeToggle && <div className="qr-code-section mt-5">
                    <div className="qr-code-head">
                        Scan the QR code to join!
                    </div>
                    <div className="qr-code p-3">
                        <img src={game_qr_code_file} className='img-fluid w-100' />
                    </div>
                </div>
                }
            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer varient="normal"
                    left={<GameButton varient="fboption" onmouseenter={props.toggleOption}><Icons varient='option' />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2" varient="sbButton" onclick={handleHide}>{props.qrcodeToggle ? <><Icons  color="#aa6600" varient='faThLarge' />{translations?.hide_qr}</> : <><Icons varient='qrcode' />{translations?.show_qr}</>}</GameButton>}
                    centerTwo={<GameButton className="me-2" varient="sbButton" onclick={handleHide}><Icons varient='startnow' />{gameControls[localStorage.getItem('qr_memorized')]}</GameButton>}
                    // centerTwo={<GameButton className="me-2" varient="controlbutton" onclick={handleStart}><Icons varient='startnow' />Skip animation</GameButton>}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game,
        playerScreen: state.player.playerScreen,
        memorize: state.memorize,
        qrcodeToggle: state.toggleQrCode.qrcodeToggle,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
        changeSelection: (data) => dispatch(changeSelection(data)),
        toggleQrCode:(data)=>dispatch(toggleQrCode(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);


