import React, { useEffect, useState, Component } from "react";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import GameButton from "../games/GameTypeA/Circuit/components/Button/index";
import Navbar from "../games/GameTypeA/Circuit/components/Header/index";
import Footer from "../games/GameTypeA/Circuit/components/Footer/index";


export default function AllComponent(props) {
    return (
        <div className="allbg">
            <div>
                <Navbar varient= "normal"/>
            </div>
            <div>
                <GameButton varient="sbstartnow"/>
                <GameButton varient="sbskipintro"/>
                <GameButton varient="sbpause"/>
                <GameButton varient="sbjumpquestion"/>
                <GameButton varient="sbfinishround"/>
                <GameButton varient="sbfinishgame"/>
                <GameButton varient="sbstopgame"/>
                <GameButton varient="sbfullscreen"/>
                <GameButton varient="sbqrcode"/>
                <GameButton varient="sbextrashown"/>
                <GameButton varient="sbmutesound"/>
            </div>
            <div>
                <Footer varient= "normal"/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}


