import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux"

import { performRequest } from "../../../../api/apiHandler";
import { socket } from "../../../../context/socket";
import { toggleOption } from "../../../../redux/Option/option.actions"
import { changeAnimationStatus } from "../../../../redux/Animations/animation.actions"
import Footer from "../components/Footer/index";
import Leaderboard from "../components/Presentationleaderboard/index";
import Navbar from "../components/Header/index";
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./presentationstartview.scss";
import styled, { keyframes } from 'styled-components';

function AllComponent(props) {
    let { rounds, id ,translations} = JSON.parse(localStorage.getItem('game'));
    const gameData = JSON.parse(localStorage.getItem('game'));
    const [teamScore, setTeamScore] = useState([]);
    const [teamElement, setTeamElement] = useState([]);
    const [displayDetails, setDisplayDetails] = useState(null);
    const [animate, setAnimate] = useState(false);
    const [isReset, setReset] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isReplyAnimation, setReplyAnimation] = useState(false);
    const [position, setPosition] = useState([]);
    const [tempStartPosition, setTempStartPosition] = useState(0);
    const [leaderboardHeight, setLeaderboardHeightRef] = useState(0);
    const [mainHeight, setMainHeight] = useState(0);
    const [isLast, setLast] = useState(false);

    const { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };
    const question = props.question ? props.question.question : JSON.parse(localStorage.getItem('question'));
    const leaderboardHeightRef = useRef(0);
    const mainHeightRef = useRef(0);

    useEffect(() => {
        
        props.changeAnimationStatus(0);
        const formData = new FormData();
        formData.append('game_id', gameData.id);
        formData.append('question_id', question?.id);
        formData.append('round_id', question?.round_id);
        const questionData = JSON.parse(localStorage.getItem('question'));
        setLast(questionData?.isLast)
        performRequest('post', 'api/question-score-details', formData)
            .then(response => {
                const getScoreDetails = response.data.data;
                const teamPositions = response.data.teamPositions;
                const displayDetails = response.data.displayDetails;
                setDisplayDetails(displayDetails);
                setTeamScore(getScoreDetails);
                setTeamElement(response.data.extra);
                localStorage.setItem('game_score', JSON.stringify(getScoreDetails));
                //place team eleamen based on score
                setPosition(teamPositions.reverse());
                const localPositions = localStorage.getItem('teamPositions') ? JSON.parse(localStorage.getItem('teamPositions')) : [];
                if (localPositions.length) {
                    const localPosition = localPositions[localPositions.length - 1];
                    const pos1 = localPosition.position;
                    setTempStartPosition(pos1)
                    if (!localPositions.find(e => e.id == question.id)) {
                        localPositions.push({
                            id: question.id,
                            position: teamPositions
                        });
                        localStorage.setItem('teamPositions', JSON.stringify(localPositions));
                    }
                } else {
                    setTempStartPosition([])
                    localStorage.setItem('teamPositions', JSON.stringify([{
                        id: question.id,
                        position: teamPositions
                    }]));
                }
                ///////
                if ((round_pos == displayDetails.round_order_no - 1) && (question_pos == displayDetails.question_order_no - 1)) {
                    if (rounds[round_pos].questions.length > question_pos + 1) {
                        localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos, question_pos: question_pos + 1 }));
                    } else {
                        if (rounds.length > round_pos + 1) {
                            localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos + 1, question_pos: 0 }));
                        } else {
                            // localStorage.removeItem('rounds');
                            // localStorage.setItem('rounds', JSON.stringify({ round_pos: round_pos, question_pos: 0 }));

                        }
                    }
                }
                //////
                setLoading(true);
                setLeaderboardHeightRef(leaderboardHeightRef.current.clientHeight);
                setMainHeight(mainHeightRef.current.clientHeight);

            })
        socket.on('get_question_animation', (arg) => {
            setAnimate(arg);
        })

    }, []);

    
    useEffect(()=>{
        const keyDownHandler = event => {
            if (event.key == 'ArrowRight') {
                handleSkipToNext();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isLast])

    const handleSkipToNext = () => {
        if (!isLast) {
            socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
        } else {
            socket.emit('broadcast_stage', { player: 9, presentation: 9, id: id })
        }
    }

    function handleAnimation() {
        setAnimate(!animate)
        props.changeAnimationStatus(!animate ? 1 : 0);

    }
    let timeOut = 0;
    const handleReplay = () => {

        setReplyAnimation(false);
        props.changeAnimationStatus(0);
        setReset(false)
        if (timeOut) {
            clearTimeout(timeOut);
            timeOut = 0;
        }
        timeOut = setTimeout(() => {
            setReset(true)
        }, 500)
    }

    useEffect(() => {
        if (props.animationStatus == 1)
            if (isReplyAnimation) {
                handleReplay();
            }
    }, [props.animationStatus])



    return (
        <div className={`${props.control.control ? 'hide-control' : ''} presentation-start-view position-relative`}>
            <div className="navbar-section">
                <Navbar varient="normal" />
            </div>
            <Navbar varient="contentheader" />
            {
                loading &&
                <div className="main-content custom-margin-for-presentation-lb d-block" ref={mainHeightRef} >
                    <div className="moving-bg cloud"></div>
                    <div className="moving-bg"></div>
                    <div className="moving-bg-2"></div>
                    <div className="moving-bg-3"></div>
                    <div className="moving-bg-4"></div>
                    <div className="w-100" ref={leaderboardHeightRef}>
                        <Leaderboard varient="normal" teamScore={teamScore} animate={!props.animationStatus} isReset={isReset} displayDetails={displayDetails} />
                    </div>
                    <div className="game-section-rocket">
                        <div className={` game-player-position ${teamScore.length > 5 ? 'path-section' : ''}`} style={{ height: (mainHeight < 700 && teamScore.length > 5) ? mainHeight - leaderboardHeight - 160 : mainHeight - leaderboardHeight - 135 }}>
                            {
                                !!teamElement.length && isReset && teamElement.map((team, index) => (<React.Fragment key={index}>
                                    <RocketElement team={team} startPosition={index}
                                        tempStartPosition={tempStartPosition}
                                        position={position}
                                        skin={gameData.skin.game_skin_name}
                                        animationStatus={props.animationStatus}
                                        id={question.id}
                                        changeAnimationStatus={() => props.changeAnimationStatus(2)}
                                        endPosition={index} teamScore={teamElement} index={index} isAnimate={animate}
                                        replyAnimation={(e) => setReplyAnimation(e)}
                                    />
                                </React.Fragment>))
                            }
                        </div>
                    </div>
                </div>
            }
            {/* <Footer varient="footercontentpresentation" /> */}
            <div className="footer-section">
                <Footer
                    varient="normal"
                    left={<GameButton varient="fboption" onmouseenter={props.toggleOption}><Icons color="#aa6600" varient='option' />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2 next-button" varient="sbButton" onclick={handleSkipToNext}><Icons color="#aa6600" varient='startnow' />{!isLast ? translations?.next_question : translations?.show_round_leaderboard}</GameButton>}
                    centerTwo={props.animationStatus == 2 ? <GameButton className="me-2" varient="sbButton" onclick={handleReplay} ><Icons color="#aa6600" varient='faUndo' /><span className="ms-2">{translations?.replay_animation}</span></GameButton> : <GameButton className="me-2" varient="sbButton" onclick={handleAnimation} ><Icons color="#aa6600" varient={!props.animationStatus ? 'pause' : 'startnow'} />{!props.animationStatus ? translations?.pause_animation : translations?.start_animation}</GameButton>} />
            </div>
        </div >
    );
}

const RocketElement = ({ team, teamScore, index, isAnimate, replyAnimation, skin, id, tempStartPosition, changeAnimationStatus, animationStatus }) => {
    const rocketRef = useRef(null)
    // const [skin, setSkin] = useState(0 ? 'car' : 'astro');

    const [animationState, setAnimationState] = useState({
        newPosition: '',
        startPosition: 0,
        endPosition: 0,
        getColor: '',
        someColor: '',
    });

    const checkAnimationEnd = () => {
        if (rocketRef?.current) {
            rocketRef.current.addEventListener("animationend", function () {
                replyAnimation(true);
                changeAnimationStatus(2);
            }, false);
        }
    }

    useEffect(() => {

        var toFind = team.id;
        const getLocalPositions = JSON.parse(localStorage.getItem('teamPositions'));
        const eachDistance = 50 / ((getLocalPositions[getLocalPositions.length - 1]?.position.length == 1) ? 2 : (getLocalPositions[getLocalPositions.length - 1]?.position.length));

        const localPositionStart = getLocalPositions.length > 1 ? getLocalPositions[getLocalPositions.length - 2]?.position : [];
        const localPositionEnd = getLocalPositions[getLocalPositions.length - 1]?.position;

        var posOne = -1;
        var posTwo = -1;
        localPositionStart.forEach((e, i) => {
            if (e.indexOf(toFind) != -1) {
                posOne = i;
            }
        })
        localPositionEnd.forEach((e, i) => {
            if (e.indexOf(toFind) != -1) {
                posTwo = i;
            }
        })
        const _state = {
            newPosition: 50 / teamScore.length,
            startPosition: localPositionStart.length ? 20 + (eachDistance * (posOne + 1)) : 20,
            endPosition: 20 + (eachDistance * (posTwo + 1)),
            getColor: team.color[0].toUpperCase() + team.color.slice(1),
            someColor: team.color,
        }
        setAnimationState(_state);
    }, [team, teamScore, index])

    useEffect(() => {
        if (rocketRef.current) checkAnimationEnd()
    }, [animationState])




    return (
        <div className={`rocket d-flex align-items-center `} style={teamScore ? { marginTop: 0 } : {}}>
            {animationState.someColor && (
                <>
                    <Rocket
                        className="rocket-smoke circle"
                        someColor={animationState.someColor}
                        startPosition={animationState.startPosition}
                        endPosition={animationState.endPosition}
                        isAnimate={isAnimate}
                        animationStatus={animationStatus}
                        ref={rocketRef}
                        style={{ background: `linear-gradient(90deg, ${animationState.someColor + 80} 0.65%, ${animationState.someColor} 3.9%, ${animationState.someColor + 40} 50.64%)` }}
                    />
                    <div className="rocket-svg"  >
                        <img style={{
                          animation: 'customfadeIn 3s '
                    }} src={`../assets/skin/${skin}/player/element${animationState.someColor.replace('#', '-').toUpperCase()}.gif`} />
                    </div>

                </>
            )}
        </div>
    )
}

const rocketMovement = (startPosition, endPosition) => keyframes`from { width: ${startPosition}%;} to {width: ${endPosition}%;}`;

const Rocket = styled.div`h1 { font-weight: lighter; }
                            position: relative;
                            top: 0;
                            width: ${props => props.endPosition}%;
                            animation: ${props => rocketMovement(props.startPosition, props.endPosition)} ease-in-out 10s;
                            animation-duration: 10s;
                            animation-play-state: ${props => props.animationStatus ? 'paused' : 'running'};
                            background: linear-gradient(90deg, ${props => props.someColor + 80} 0.65%, ${props => props.someColor} 3.9%, ${props => props.someColor + 40} 50.64%);
                            `;
const mapStateToProps = state => {
    return {
        question: state.question.question,
        animationStatus: state.animationStatus.animationStatus,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
        changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllComponent);