import React from "react";
import "./404.scss";
import Loader from "../../assets/logo.gif";
const API_URL = process.env.REACT_APP_API_URL;

export default function NotFound(props) {
  return (
    <>
      <div class="full-section">
        <div>
          <div className="d-flex align-items-center justify-content-center error-design">
            <h1>4</h1>
            <img src={Loader} alt="" />
            <h1>4</h1>
          </div>
          <p>OOPS! Page Not Found</p>

          <a href={API_URL+'dashboard'}>back to home</a>
        </div>
      </div>
    </>
  );
}
