import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./playerquestions.scss";
import Questimg from "../components/assets/images/question_img.jpg";
import Navbar from "../components/Header/index";
import Footer from "../components/Footer/index";
import Icons from "../components/Icons/index";
import { socket } from "../../../../context/socket";
import { performRequest } from "../../../../api/apiHandler";
import { addQuestion } from "../../../../redux/Questions/question.actions";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import {
  getQuestion,
  updateQuestion,
} from "../../../../redux/Questions/question.actions";
import { connect, useDispatch } from "react-redux";
import { colors, options } from "../../../../constant";
import { addSelectedAnswer } from "../../../../redux/SelectedAnswers/selectedAnswers.actions";
import TeamDetails from "../components/TeamDetails";
import Loader from "../components/Loader";
import { useLayoutEffect } from "react";

function PlayerQuestion(props) {
  const [isSubmit, setIsSubmit] = useState(true);
  const [selected, setSelected] = useState([]);
  const [timer, setTimer] = useState(0);
  const [notQuestion, setNotQuestion] = useState(false);
  const [isMultipleChoice, setIsMultipleChoice] = useState(false);
  const [question, setQuestion] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isTimerEnable, setTimerEnable] = useState(false);
  const [gameQuestion, setCurrentQuestion] = useState(false);
  const dispatch = useDispatch();

  const getPlayeersSelectedAnswer = [];
  const profile = JSON.parse(localStorage.getItem("profile"));
  const { is_live, id, player, has_team, translations } = JSON.parse(
    localStorage.getItem("game")
  );
  let windowHeight = window.innerHeight;
  let questionHeight = document.getElementById("pq-head")?.clientHeight ?? 0;
  var scrollHeight = windowHeight - (questionHeight ? questionHeight : 0);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);

  const socketConnection = () => {
    socket.on("set_question", (arg) => {
      props.updateQuestion(arg);
      setLoader(false);
      props.addQuestion(arg);
      setCurrentQuestion(arg);
      setNotQuestion(!arg?.question?.is_active);
      localStorage.setItem("playerQuestion", JSON.stringify(arg));
    });
  };

  const getQuestionById = React.useCallback(
    async (customerId) => {
      const res = await dispatch(getQuestion({ id: id, user_id: player?.id }));
      if (res?.success) {
        if (res?.data?.question) {
          setCurrentQuestion(res?.data);
          setNotQuestion(!res?.data?.question?.is_active);
          setTimerEnable(!res?.data?.question?.is_active);
        } else {
          props.changePlayerStage(15);
        }
      }
    },
    [dispatch]
  );
  const handleAnswer = (isAnswered = true) => {
    const playerId = JSON.parse(localStorage.getItem("profile"));
    const { id } = JSON.parse(localStorage.getItem("game"));
    var isRoundEnd = false;
    const data = {
      id: id,
      player_id: props.user ? props.user : playerId,
    };
    const formData = new FormData();
    formData.append("game_id", id);
    formData.append("question_id", gameQuestion?.question?.id);
    if (is_live) {
      formData.append("player_id", props.user ? props.user.id : playerId.id);
    } else {
      formData.append("timeLeft", timeLeft);
      formData.append("player_id", player?.id);
    }
    formData.append("answer_ids", notQuestion ? JSON.stringify(selected) : []);
    formData.append("response_speed", notQuestion ? timer : 0);
    formData.append("is_round_end", isRoundEnd);
    if (selected.length || !notQuestion || !isAnswered) {
      setIsSubmit(false);
      performRequest("post", "api/save-question-answer", formData)
        .then((response) => {
          const selectedAnswer = response.data.data;
          const currentQuestion = gameQuestion?.question;
          props.addQuestion(gameQuestion?.question);
          localStorage.setItem("playerQuestion", JSON.stringify(gameQuestion));
          if (is_live) {
            if (response.data.success == 1) {
              socket.emit("set_answered", data);
            }
          } else {
            if (!currentQuestion?.is_active) {
              props.changePlayerStage(7);
            } else {
              if (currentQuestion?.isRoundEnd) {
                props.changePlayerStage(14);
              } else {
                setIsSubmit(true);
                getQuestionById(id);
                props.changePlayerStage(5);
              }
            }
            // isGameEnd
            // isRoundEnd
          }
          props.addSelectedAnswer(selectedAnswer);
          localStorage.setItem(
            "playerSelected",
            JSON.stringify(selectedAnswer)
          );
        })
        .catch((error) => {
          setIsSubmit(true);
        });
    }
  };
  
  const handleExit = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "game/" + id);
  };

  const handleSelect = (answer) => {
    if (isSubmit) {
      let selecteAnswer = selected;
      if (isMultipleChoice) {
        if (selecteAnswer.indexOf(answer) == -1) {
          selecteAnswer.push(answer);
          if (document.getElementById(answer).classList.contains("selected")) {
            var index = selecteAnswer.indexOf(answer);
            if (index !== -1) {
              selecteAnswer.splice(index, 1);
            }
            document.getElementById(answer).classList.remove("selected");
          } else {
            document.getElementById(answer).classList.add("selected");
          }
        } else {
          if (document.getElementById(answer).classList.contains("selected")) {
            var index = selecteAnswer.indexOf(answer);
            if (index !== -1) {
              selecteAnswer.splice(index, 1);
            }
            document.getElementById(answer).classList.remove("selected");
          } else {
            document.getElementById(answer).classList.add("selected");
          }
        }
        setSelected(selecteAnswer);
      } else {
        let clas = Array.from(document.getElementsByClassName("answer-option"));
        clas.forEach((e) => {
          e.classList.remove("selected");
        });
        document.getElementById(answer).classList.add("selected");
        setSelected([answer]);
      }
    }
  };

  useLayoutEffect(() => {
    // if (is_live) {
    socketConnection();
    // } else {
    if (!is_live) {
      getQuestionById(id);
    }
    setLoader(false);
    // }
  }, []);

  useEffect(() => {
    localStorage.setItem("playerStage", 5);
    setTimeout(() => setLoader(false), 2000);
    if (gameQuestion) {
      setIsMultipleChoice(gameQuestion?.question?.isMultipleChoice);
    } else {
      const playerQuestion = JSON.parse(localStorage.getItem("playerQuestion"));
      if (!is_live) {
        props.addQuestion(gameQuestion?.question);
      }
      if (playerQuestion) {
        if (playerQuestion?.isPresented) {
          setQuestion(gameQuestion?.question);
          setIsMultipleChoice(playerQuestion.isMultipleChoice);
          setNotQuestion(!playerQuestion.question.is_active);
        }
      }
    }
    props.addSelectedAnswer([]);
  }, [gameQuestion]);

  useEffect(() => {
    socket.emit("test_emit", "100");
    socket.on("test_emit", (arg) => {});
    const interval = setInterval(() => {
      setTimer((prev) => parseInt(prev) + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // const getPlayeersSelectedAnswers = JSON.parse(
    //   localStorage.getItem("playerSelected")
    // );
    const getPlayeersSelectedAnswers = [];
    if (
      localStorage.getItem("playerQuestion") &&
      getPlayeersSelectedAnswers.length
    ) {
      const { question } = JSON.parse(localStorage.getItem("playerQuestion"));
      const isFound = getPlayeersSelectedAnswers.find(
        (e) => e.question_id == question.id
      );

      if (isFound) {
        setIsSubmit(false);
      } else {
        setIsSubmit(true);
      }
    }
  }, [question, props.selectedAnswer]);

  useEffect(() => {
    // props.addQuestion(gameQuestion);
    setQuestion(gameQuestion?.question);
    setNotQuestion(!gameQuestion?.question?.is_active);
    localStorage.setItem("playerQuestion", JSON.stringify(gameQuestion)); //
  }, []);

  useEffect(() => {
    if (!is_live) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress - 1;
          return newProgress >= 0 ? newProgress : 0;
        });

        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = prevTimeLeft - 1;
          if (newTimeLeft === 0) {
            handleAnswer(false);
          }
          return newTimeLeft >= 0 ? newTimeLeft : 0;
        });
      }, (gameQuestion?.question?.time * 1000) / 20);

      return () => clearInterval(interval);
    }
  }, [gameQuestion]);

  useEffect(() => {
    setTimeLeft(gameQuestion?.question?.time);
  }, [gameQuestion]);

  return (
    <div className="player-questions">
      <Navbar varient="contentheadershortplayer" />
      {!gameQuestion?.question && <Loader />}
      <div className="main-content">
        <div className="pq-whole-section pb-0">
          <div
            className="pq-head text-center mb-5 position-relative"
            id="pq-head"
            style={{ "--team-color": `#${profile?.color}` }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: gameQuestion?.question?.description,
              }}
            ></span>
            <div className="time-progress">
              {!is_live
                ? isTimerEnable && (
                    <>
                      <p className="mb-1 mt-2">
                        {translations?.time_left} {timeLeft}
                      </p>
                      <div class="progress" style={{ height: "10px" }}>
                        <div
                          class="progress-bar rounded-pill border"
                          role="progressbar"
                          style={{
                            width: `${
                              (timeLeft * 100) / gameQuestion?.question?.time
                            }%`,
                          }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )
                : ""}
            </div>
          </div>
          <div
            className="qstn-scroll-sec pt-0"
            style={{
              maxHeight: scrollHeight - 120,
              marginTop: questionHeight - 20,
            }}
          >
            <div className="quest-img-loader-sec col-12 mb-3 pt-0">
              <div className="d-flex justify-content-center align-items-center gap-5">
                <div
                  className={`quest-img ${
                    !gameQuestion?.question?.image_path && "no-img"
                  }`}
                >
                  {!loader && gameQuestion?.question?.image_path && (
                    <img
                      src={
                        gameQuestion?.question
                          ? gameQuestion?.question?.image_path
                            ? gameQuestion?.question?.image_path
                            : Questimg
                          : Questimg
                      }
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="answer-option-section col-12">
              {notQuestion && (
                <div className="row">
                  {gameQuestion?.question
                    ? gameQuestion?.question["get_answers_by_question_id"].map(
                        function (answer, index) {
                          return (
                            <div
                              key={answer.id}
                              className="col-lg-6 col-md-6 col-sm-12 col-12 my-2 unset"
                              onClick={() => handleSelect(answer.id)}
                            >
                              <div
                                id={answer.id}
                                className={`answer-option ${colors[index]} ${
                                  getPlayeersSelectedAnswer?.find(
                                    (e) => e.id == answer.id
                                  )
                                    ? " selected"
                                    : ""
                                }`}
                                style={
                                  isSubmit
                                    ? { cursor: "pointer" }
                                    : { cursor: "unset" }
                                }
                              >
                                <div className="col-12">
                                  <div className="row mx-0">
                                    <div className="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-3 p-0">
                                      <div className="option-count d-flex align-items-center justify-content-center w-100 h-100">
                                        {options[index]}
                                      </div>
                                    </div>
                                    <div className="col-xl-11 col-lg-10 col-md-10 col-sm-10 col-9 p-0">
                                      <div
                                        className="option-detail text-start"
                                        dangerouslySetInnerHTML={{
                                          __html: answer.name,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : ""}
                </div>
              )}
              {notQuestion && (
                <div className="col-12 w-100 d-flex justify-content-center button-sec">
                  <button
                    className="btn ans-send-btn my-3 d-flex align-items-center justify-content-center"
                    onClick={handleAnswer}
                    style={{
                      "--button-color": has_team
                        ? `#${profile?.color}`
                        : "linear-gradient(225deg, #aa6600 0%, #7f18c2 100%)",
                    }}
                    disabled={selected.length === 0 || !isSubmit}
                  >
                    <Icons varient="checkIcon" />
                    {translations?.sent}
                  </button>
                </div>
              )}

              {!is_live && !notQuestion && (
                <>
                  <div className="btn-group-player d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4 flex-column flex-sm-row">
                    <button
                      class="btn btn-primary dynamic-btn px-5 d-flex justify-content-center align-items-center"
                      onClick={handleAnswer}
                      style={
                        has_team
                          ? { "--button-color": `#${profile?.color}` }
                          : {
                              "--button-color": `linear-gradient(225deg, #aa6600 0%, #7f18c2 100%)`,
                            }
                      }
                    >
                      <i class="fa-solid fa-play me-2"></i>
                      {translations?.continue}
                    </button>
                    <button
                      class="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center text-white"
                      onClick={handleExit}
                      style={
                        has_team
                          ? { "--button-color": `#${profile?.color}` }
                          : {
                              "--button-color": `linear-gradient(225deg, #aa6600 0%, #7f18c2 100%)`,
                            }
                      }
                    >
                      <i class="fa-solid fa-right-from-bracket me-2"></i>
                      {translations?.exit_game}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    question: state.question.question,
    selectedAnswer: state.selectedAnswer.selectedAnswer,
    user: state.user.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addQuestion: (data) => dispatch(addQuestion(data)),
    updateQuestion: (data) => dispatch(updateQuestion(data)),
    addSelectedAnswer: (data) => dispatch(addSelectedAnswer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerQuestion);
