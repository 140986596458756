import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import logo from './../assets/images/logo.svg';
import headerlogo from './../assets/images/logo.png';
import headerlogowhite from './../assets/images/logo.png';
import LongHeader from '../LayoutComponents/LongHeader';
import SingleLongHeader from '../LayoutComponents/SingleLongHeader';
import HeaderNormal from '../LayoutComponents/Header';

import { changeControl } from "../../../../../redux/Control/control.actions";
import { useDispatch } from 'react-redux'
function Header(props) {
    const dispatch = useDispatch();

    const toggleFullscreen = () => dispatch(changeControl(0));
    const collapseFullscreen = () => {
        const controlTabStatus = localStorage.getItem('controlTabStatus') != undefined ? JSON.parse(localStorage.getItem('controlTabStatus')) : true;
        if (!controlTabStatus) {
            dispatch(changeControl(1));
        }
    }
    let game = props.game;
    const brand_logo = game?.brand_logo.includes('http') ? game.brand_logo : false;
    const brand_image = game?.brand_image.includes('http') ? game.brand_image : false;

    const teams = props.teams;
    const teamWidth = 100 / teams.length;
    let Navbar = '';
    let varient = props.varient;
    switch (varient) {
        case 'normal':
            Navbar = <div className="yakeety-header w-100">
                {props.control.control != 1 ?
                    <div className="navbar px-4 py-3" onMouseLeave={collapseFullscreen} style={{ transition: '.3s ease-out all' }}>
                        <div className="text-section d-flex align-items-center"><p className="mb-0"><span>Join now:</span> {process.env.REACT_APP_BASE_URL}</p><h5 className="mb-0">{game ? game.custom_game_url.replace(process.env.REACT_APP_BASE_URL, '') : ''}</h5></div>
                        <div className="logo-img-section"><img src={logo} alt="" /></div>
                    </div> :
                    <div style={{ height: '50px', position: 'absolute', width: '100%', cursor: 'pointer', transition: '.3s ease-in-out all' }} onMouseEnter={toggleFullscreen} />
                }
                <div className="color-scheme-section d-flex align-items-center w-100">
                    {
                        teams.map(team => {
                            return <div key={team.id} className="cs-color green" style={{ backgroundColor: `${team.color}`, width: teamWidth + '%' }}></div>
                        })
                    }
                </div>
            </div>
            break;
        case 'contentheader':
            Navbar = <div className="header-bg">
                <div className="header-overlay-img position-relative">
                    <LongHeader />
                    <div className="ho-logo-section-header-logo position-absolute">
                        <img src={brand_logo ? brand_logo : headerlogo} alt="" className="img-fluid" />
                    </div>
                    <div className="ho-logo-section-team-logo position-absolute">
                        <img src={brand_image ? brand_image : headerlogo} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            break;
        case 'contentheadersinglemenu':
            Navbar = <div className="header-bg">
                <div className="header-overlay-img position-relative">
                    <SingleLongHeader />
                    <div className="ho-logo-section-header-logo position-absolute">
                        <img src={brand_logo ? brand_logo : headerlogo} alt="" className="img-fluid" />
                    </div>
                    <div className="ho-logo-section-team-logo position-absolute">
                        <img src={brand_image ? brand_image : headerlogo} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            break;
        case 'contentheadershort':
            Navbar = <div className="header-bg">
                <div className="header-overlay-img position-relative">
                    <HeaderNormal />
                    <div className="ho-logo-section-header-logo position-absolute">
                        <img src={brand_logo ? brand_logo : headerlogowhite} alt="" className="img-fluid" />
                    </div>
                    {/* <div className="ho-logo-section-team-logo position-absolute">
                        <img src={brand_image ? brand_image : randomlogo} alt="" className="img-fluid" />
                    </div> */}
                </div>
            </div>
            break;
        case 'contentheadershortplayer':
            Navbar = <div className="header-bg top-0 start-0 w-100">
                <div className="header-overlay-img position-relative w-100">
                    <HeaderNormal />
                    <div className="ho-logo-section-header-logo position-absolute">
                        <img src={brand_logo ? brand_logo : headerlogowhite} alt="" className="img-fluid" />
                    </div>
                    <div className="ho-logo-section-team-logo position-absolute">
                        <img src={brand_image ? brand_image : headerlogowhite} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            break;
        case 'contentheadershortplayerstart':
            Navbar = <div className="header-bg top-0 start-0 w-100">
                <div className="header-overlay-img position-relative w-100">
                    <HeaderNormal />
                    <div className="ho-logo-section-header-logo position-absolute">
                        <img src={brand_logo ? brand_logo : headerlogowhite} alt="" className="img-fluid" />
                    </div>
                    <div className="ho-logo-section-team-logo position-absolute">
                        <img src={brand_image ? brand_image : headerlogowhite} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
            break;
        default:
            Navbar = <div className="yakeety-header">
                <div className="navbar px-4 py-3">
                    <div className="text-section d-flex align-items-top"><p className="mb-0"><span>Join now:</span> https//app.yakeety.com/</p><h5 className="mb-0">astroquiz</h5></div>
                    <div className="logo-img-section"><img src={logo} alt="" /></div>
                </div>
                <div className="color-scheme-section d-flex align-items-center w-100">
                    <div className="cs-color green"></div>
                    <div className="cs-color red"></div>
                    <div className="cs-color violet"></div>
                    <div className="cs-color cyan"></div>
                    <div className="cs-color blue"></div>
                </div>
            </div>
            break;
    }
    return (
        <div>
            {Navbar}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        game: state.game.game,
        teams: state.team.teams,
        control: state.control
    }
}
export default connect(mapStateToProps)(Header)


