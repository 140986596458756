import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./playerstart.scss";
import { performRequest } from "../../../../api/apiHandler";
import { connect, useSelector } from "react-redux";

import Footer from "../components/Footer/index";
import TeamDetails from "../components/TeamDetails";

import { updateTeam } from "../../../../redux/Teams/team.actions";
import { loader } from "../../../../redux/Loader/loader.actions";
import Navbar from "../components/Header/index";
import { changePlayerStage } from "../../../../redux/Player/player.actions";
import { addUser } from "../../../../redux/User/user.actions";
import { addGame } from "../../../../redux/Game/game.actions";

function RoundResult(props) {
  const [roundStatus, setRoundStatus] = useState([0, 0]);
  const { gameQuestion } = useSelector((state) => state?.question);
  const windowHeight = window.innerHeight;
  const questionHeight = document.getElementById("pq-head")?.clientHeight;
  const scrollHeight = windowHeight - (questionHeight ? questionHeight : 0);
  const { player, has_team, id,translations } = JSON.parse(localStorage.getItem("game"));

  const handleNext = () => {
    if (gameQuestion?.question?.isGameEnd) {
      props.changePlayerStage(15);
    } else {
      props.changePlayerStage(16);
    }
  };

  const handleExit = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "game/" + id);
  };

  const { color } = JSON.parse(localStorage.getItem("profile") ?? '{"color": "transparent"}');
  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `${r},${g},${b}`;
  };

  const hex2rgbaAlt = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `${Math.round(r * .72)},${Math.round(g * .75)},${Math.round(b * .85)}`;
  };



  useEffect(() => {
    performRequest(
      "get",
      "api/get-round-result/" +
        gameQuestion?.question?.round_id +
        "/" +
        player?.id
    ).then((res) => {
      if (res?.data?.success) {
        setRoundStatus(res?.data?.data);
      }
    });
  }, [props]);

  return (
    <div className="user-registration presentation-player-start player-invitation d-flex flex-column justify-content-md-center align-items-center">
      <Navbar varient="contentheadershortplayerstart" />
      <div className="main-content">
        <div className="pq-whole-section pb-0 player-result-view round-result-box">
          <div
            className="qstn-scroll-sec text-white"
            style={{
              maxHeight: scrollHeight - 120,
              marginTop: questionHeight + 30,
            }}
          >
            <h3 class="card-title mb-3">
              Round {gameQuestion?.question?.round_order_no}
            </h3>
            <div
              class="card text-left answer-list--card"
              style={
                has_team
                  ? {
                      background: `linear-gradient(225deg, rgba(${hex2rgba(color)},.8) 0%, rgba(${hex2rgba(color)},.4) 100%)`,
                    }
                  : {}
              }
            >
              {has_team ? (
                <div class="card-body">
                  <p class="card-text font-Comfortaa mb-1">{translations?.correct_answers}:</p>
                  <ul className="answer-list--items flex-column d-flex flex-wrap ps-0 mb-0">
                    <li className="border-0">
                      {roundStatus[0]} of {roundStatus[1]}
                    </li>
                  </ul>
                </div>
              ) : (
                <div class="card-body">
                  <p class="card-text font-Comfortaa mb-1">{translations?.correct_answers}:</p>
                  <ul className="answer-list--items flex-column d-flex flex-wrap ps-0 mb-0">
                    <li className="border-0">
                      {translations?.answer_correctley} {roundStatus[0]} {translations?.of}{" "}
                      {roundStatus[1]} {translations?.score_so_far}{" "}
                      {roundStatus[2]} {translations?.points}.
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="btn-group-player d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4 flex-column flex-sm-row">
              <button
                class="btn btn-primary px-5 d-flex justify-content-center align-items-center"
                onClick={handleNext} style={{ backgroundImage: `linear-gradient(225deg, rgb(${hex2rgba(color)}) 0%, rgb(${hex2rgbaAlt(color)}) 100%)`,"--element-color": hex2rgba(color)}}>
                <i class="fa-solid fa-play me-2"></i>{translations?.continue}
              </button>
              <button
                class="btn btn-outline-primary px-5 d-flex justify-content-center align-items-center text-white"
                onClick={handleExit} style={{ borderColor: `rgb(${hex2rgba(color)})`}}>
                <i class="fa-solid fa-right-from-bracket me-2"></i>{translations?.exit_game}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TeamDetails />
      <Footer varient="footercontentplayer" />

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    teams: state.team.teams,
    game: state.game.game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTeam: (id, data) => dispatch(updateTeam(id, data)),
    loader: (data) => dispatch(loader(data)),
    changePlayerStage: (data) => dispatch(changePlayerStage(data)),
    addUser: (data) => dispatch(addUser(data)),
    addGame: (data) => dispatch(addGame(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoundResult);
