import React, { useEffect, useState } from 'react';
import './index.css'
import { connect } from "react-redux"
import {
    increaseCounter,
    decreaseCounter,
} from "../../../redux/Counter/counter.actions"
import Player from './player/index';
import Presenter from './views/index';

function Circuit(props) {
    const skin = props.skin;
    const [colors, setColors] = useState('')
    const [skins, setSkins] = useState('Default');
    let game = props.game;
    const gameUrl = game.custom_game_url;
    useEffect(() => {
        let themeSkin = skins != 'Default' ? `/Skins/${skins}/theme.css` : './index.css';
        const themeStyle = document.createElement("link");
        themeStyle.href = themeSkin;
        themeStyle.rel = "stylesheet";
        themeStyle.async = true;
        document.head.appendChild(themeStyle);

        let themeColor = `/Colors/${colors}/index.css`;
        const colorStyle = document.createElement("link");
        colorStyle.href = themeColor;
        colorStyle.rel = "stylesheet";
        colorStyle.async = true;
        document.head.appendChild(colorStyle);
        return () => {
            document.head.removeChild(colorStyle);
            document.head.removeChild(themeStyle);
        }
    }, [skins, colors]);
    return (
        <>
            {props.isGame == 'presentation' ? <Presenter /> :props.isGame == 'player'? <Player />:''}
        </>
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game,
        playerScreen: state.player.playerScreen
    }
}
const mapDispatchToProps = dispatch => {
    return {
        increaseCounter: () => dispatch(increaseCounter()),
        decreaseCounter: () => dispatch(decreaseCounter()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Circuit);


