import { ADDSELECTEDANSWER } from '../../constants/actionTypes';
const INITIAL_STATE = {
  selectedAnswer: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case ADDSELECTEDANSWER:
      return {
        ...state, selectedAnswer: action.data,
      };
    default: return state;
  }
};

export default reducer;