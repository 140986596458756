import React from 'react'
import './layout.scss'
import { connect } from "react-redux"

function Header(props) {
  let gameData = props.game;
  const game = JSON.parse(localStorage.getItem('game'));
  const presenter = props?.game?.color_combination?.color_class;
  const topLeftClass = game?.player?props?.game?.player_color ? 'player_top_left_' +  props?.game?.player_color?.replace('#', '')  : 'player_top_left':'player_top_left_'+presenter?.replace('#', '');
  const topRightClass = game?.player?props?.game?.player_color ? 'player_top_right_' +  props?.game?.player_color?.replace('#', '')  : 'player_top_right':'player_top_right_'+presenter?.replace('#', '');
  const skin = gameData ? gameData?.skin?.game_skin_name : game?.skin.game_skin_name;


  return (
    <div className='normal-header position-relative'>
      <div className="defualt-left-cloud">
        <img className='img-fluid' src={`../../assets/skin/${skin}/cloud/player/${topLeftClass}.png`} />
      </div>
      <div className="defualt-right-cloud">
        <img className='img-fluid' src={`../../assets/skin/${skin}/cloud/player/${topRightClass}.png`} />
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    game: state.game.game,
    teams: state.team.teams,
  }
}
export default connect(mapStateToProps, null)(Header);
