import React, { useEffect, useState, useCallback, useRef } from 'react';
import Footer from "../components/Footer/index";
import Navbar from "../components/Header/index";
import { connect } from "react-redux"
import { socket } from "../../../../context/socket";
import { toggleOption } from "../../../../redux/Option/option.actions"
import GameButton from "../components/Button/index";
import Icons from "../components/Icons/index";
import '../index.css';
import { changeAnimationStatus } from "../../../../redux/Animations/animation.actions"


function IntroText(props) {
    const [animationState, setAnimationState] = useState(true);
    const { intro_text, id, name ,translations} = JSON.parse(localStorage.getItem('game'));
    
    const [replayButton, setReplayButton] = useState(true);
    const [reset, setReset] = useState(true);
    const { round_pos, question_pos } = localStorage.getItem('rounds') ? JSON.parse(localStorage.getItem('rounds')) : { round_pos: 0, question_pos: 0 };

    function handleSkipToQuestion() {
        if ((round_pos || question_pos)) {
            socket.emit('broadcast_stage', { player: 5, presentation: 5, id: id })
        }else{
            socket.emit('broadcast_stage', { presentation: 5, id: id })
        }
    }

    function handleAnimationState() {
        socket.emit('intro_text', { status: !animationState, id: id });
        setAnimationState(!animationState)
    }
    let timeOut = 0;
    const handleReplay = () => {
        setReplayButton(true);
        socket.emit('intro_text', { status: 2, id: id });


        setReset(false)

        if (timeOut) {
        clearTimeout(timeOut);
            timeOut = 0;
        }
        timeOut = setTimeout(() => {
            setReset(true)
        }, 500)

    }

    useEffect(() => {
        props.changeAnimationStatus(0);

        const el = document.getElementById('animated');
        if (el)
            el.addEventListener("animationend", function () {
                setReplayButton(false);
                socket.emit('intro_text', { status: 3, id: id });
            }, false);

        socket.on('get_text', (arg) => {
            setAnimationState(arg)
        })
    }, [reset]);



    useEffect(()=>{
        const keyDownHandler = event => {
            if (event.key == 'ArrowRight') {
                handleSkipToQuestion();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    },[])

    return (
        <div className='presentation-question position-relative'>
            <div className="navbar-section">
                <Navbar varient="normal" />
            </div>
            <Navbar varient="contentheadershort" />
            {/* <div className="main-content presentation-qr-code d-flex justify-content-center align-items-center" id='animated' style={isAnimationEnd ?{ animation: `${(intro_text.length / 100) > 100 ? (intro_text.length / 100) : 100}s ease-out 0s 1 animateScroll`, animationPlayState: animationState ? 'running' : 'paused' }:{} }> */}
            <div className='container text-bg'>

                {reset && <div className="main-content presentation-qr-code d-flex justify-content-center align-items-center" id='animated' style={{ animation: `90s ease-out 0s 1 animateScroll`, 
                animationFillMode: `forwards`, 
                animationPlayState: animationState ? 'running' : 'paused' } }>
                    <div className='intro-text text-center mt-5 col-10'>
                        <div className="it-heading mt-5">
                            <div className="title-sec"><h1>{name}</h1></div>
                        </div>
                        <div className="it-content text-start">
                            <div dangerouslySetInnerHTML={{ __html: intro_text }} />
                        </div>
                    </div>
                </div>}

            </div>
            <Footer varient="footercontentpresentation" />
            <div className="footer-section">
                <Footer
                    varient="normal"
                    left={<GameButton varient="fboption" onclick={props.toggleOption} onmouseenter={props.toggleOption}><Icons varient='option' />{translations?.options}</GameButton>}
                    centerOne={<GameButton className="me-2" varient="sbButton" onclick={handleSkipToQuestion}><Icons varient='skipintro' />{replayButton?translations?.skip_to_question:translations?.start_game_now}</GameButton>}
                    centerTwo={replayButton ? <GameButton className="me-2" varient="sbButton" onclick={handleAnimationState} >{animationState ? <><Icons varient='pause' />{translations?.freeze_text_scroll}</> : <><Icons varient='startnow' />{translations?.unfreeze_text_scroll}</>}</GameButton> : <GameButton className="me-2" varient="sbButton" onclick={handleReplay} >  <><Icons color="#a60" varient='faUndo' />{translations?.replay_text_scroll}</></GameButton>} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        count: state.counter.count,
        game: state.game,
        playerScreen: state.player.playerScreen,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        toggleOption: () => dispatch(toggleOption()),
        changeAnimationStatus: (data) => dispatch(changeAnimationStatus(data))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroText);


