import { combineReducers } from 'redux';

import counterReducer from './Counter/counter.reducer';
import gameReducer from './Game/game.reducer';
import playerReducer from './Player/player.reducer';
import questionReducer from './Questions/question.reducer';
import teamReducer from './Teams/team.reducer';
import questionScoreReducer from './QuestionScore/questionScore.reducer';
import selectedAnswerReducer from './SelectedAnswers/selectedAnswers.reducer';
import audioToggle from './Audio/audio.reducer';
import optionToggle from './Option/option.reducer';
import memorize from './Memorize/memorize.reducer';
import isLoader from './Loader/loader.reducer';
import control from './Control/control.reducer';
import selection from './Selection/selection.reducer';
import user from './User/user.reducer';
import questionStoreReducer from './QuestionStore/questionStore.reducer';
import roundAnimationStoreReducer from './RoundAnimation/roundAnimation.reducer';
import podiumAnimationStoreReducer from './PodiumAnimation/podiumAnimation.reducer';
import animationStatusReducer from './Animations/animation.reducer';
import toggleFullScreen  from './Fullscreen/fullscreen.reducer';
import toggleQrCode from './QrCode/qrcode.reducer';
import authReducer from "./Auth/reducers";


const rootReducer = combineReducers({
    authReducer,
    counter: counterReducer,
    game: gameReducer,
    player: playerReducer,
    question: questionReducer,
    team: teamReducer,
    questionScore: questionScoreReducer,
    selectedAnswer: selectedAnswerReducer,
    audioToggle: audioToggle,
    optionToggle: optionToggle,
    memorize: memorize,
    isLoader: isLoader,
    control: control,
    selection: selection,
    user: user,
    questionStore: questionStoreReducer,
    roundAnimationPlay: roundAnimationStoreReducer,
    podiumAnimationPlay: podiumAnimationStoreReducer,
    animationStatus: animationStatusReducer,
    toggleFullScreen: toggleFullScreen,
    toggleQrCode:toggleQrCode,
});

export default rootReducer;