import { QRCODETOGGLE } from '../../constants/actionTypes';
const INITIAL_STATE = {
  qrcodeToggle: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case QRCODETOGGLE:
    return {
        ...state, qrcodeToggle: action.data,
      };
    default: return state;
  }
};

export default reducer;