import { AUDIOTOGGLE } from '../../constants/actionTypes';

export const toggleAudio = (data) => {
    return {
        type: AUDIOTOGGLE,
        data
    };
};


